.btn {
  &.primary-button {
    background: var(--primary-color) !important;
    color:var(--gray1) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: var(--hover-button-color)  !important;
      box-shadow: none !important;
      color: var(--hover-button-text-color)  !important;
      .arrow-icon-send-message {
        background: var(--hover-button-text-color);
      }
    }
  }
}

.btn {
  &.secondary-button {
    background: var(--secondary-color)  !important;
    color: var(--primary-color)  !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: var(--hover-button-color)  !important;
      box-shadow: none !important;
      color: var(--hover-button-text-color)  !important;
      .arrow-icon-id1 {
        background: var(--hover-button-text-color);
        color: var(--hover-button-text-color);
        opacity: 1;
      }
      .fill-svg-icons > path {
        fill: var(--hover-button-text-color) !important;
      }
      .svg-fill-primary-filter>path {
        fill: var(--hover-button-text-color)  !important;
      }
    }
  }
  &.all-unread-button {
    border: 1px solid var(--primary-color);
    background-color: $bg-white;
    color: var(--primary-color)  !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: var(--hover-button-color)  !important;
      box-shadow: none !important;
      color: var(--hover-button-text-color)  !important;
      .arrow-icon-id1 {
        background: var(--hover-button-text-color);
        color: var(--hover-button-text-color);
        opacity: 1;
      }
      .fill-svg-icons > path {
        fill: var(--hover-button-text-color) !important;
      }
    }
  }
  &.all-unread-button-selected {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: $bg-white;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: var(--hover-button-color)  !important;
      box-shadow: none !important;
      color: var(--hover-button-text-color)  !important;
      .arrow-icon-id1 {
        background: var(--hover-button-text-color);
        color: var(--hover-button-text-color);
        opacity: 1;
      }
      .fill-svg-icons > path {
        fill: var(--hover-button-text-color) !important;
      }
    }
  }
}
.matching-candidate-action-btn {
  .expandMoreIcon {
    color: var(--primary-color)  !important;
  }
  &:hover {
    .expandMoreIcon {
      color: var(--hover-button-text-color)  !important;
    }
  }
}
.btn {
  &.job-wizard-button {
    background-color: $gray3  !important;
    color: #000000  !important;
    font-size: 14px;
    font-weight: 400;
    padding: 10px !important;
    height: 80px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: $font-family;

    &:hover {
      background-color: $gray3  !important;
      box-shadow: none !important;
      color: #000000  !important;
    }
  }
}
.arrow-icon-id {
  Button {
    padding: 11px 0 !important;
    min-width: 42px !important;
    // border-left: 1.5px solid var(--primary-color);
    // margin-left: -8px;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
}
.arrow-icon-id1 {
    opacity: 0.4;
    color: red;
    padding: 0.5px;
    background: var(--primary-color);
    height: 43px;
}
.arrow-icon-send-message {
  opacity: 1;
  padding: 0.5px;
  background: white;
  height: 43px;
}
.arrow-icon-secondary {
  opacity: 0.4;
  color: red;
  padding: 0.5px;
  background: var(--secondary-color);
  height: 40px;
}


.btn {
  &.button-white {
    border: 1px solid $white-color;
    color: $white-color;
    background-color: $bg-info;
    font-size: var(--font-size-16);
    padding: 5px 20px;
    text-transform: none;
    box-shadow: none;
    font-weight: var(--font-weight-600);
    border-radius: 8px;
    font-family: var(--font-family);

    &:hover {
      background-color: $bg-info;
      box-shadow: none;
      color: $white-color  !important;
    }
  }
}

.btn {
  &.disabled-button {
    color:var(--gray7)  !important;
    background-color: var(--gray4) !important;
    font-weight: var(--font-weight-600) !important;
    padding: 5px 20px;
    text-transform: none;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);
    width: 100%;

    &:hover {
      background-color: $bg-disable;
      box-shadow: none;
      color: $white-color  !important;
    }
  }

}

.btn {
  &.profile-button {
    border: 1px solid $primary-shadow-color;
    color: $primary-shadow-color;
    background-color: transparent;
    font-size: var(--font-size-16);
    padding: 5px 20px;
    text-transform: none;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.text-transform {
  button {
    text-transform: none !important;
  }
}

/*.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: var(--primary-color) !important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: var(--primary-color) !important;
}*/

.education-active-btn {
  color: var(--primary-color)  !important;
  // padding:15px !important;
  background: rgba(114, 46, 204, 0.2) !important;
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 3px 7px !important;
}

.education-disable-btn {
  color: $grey-color  !important;
  border: 1px solid #696871 !important;
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 1px 4px !important;
}


.MuiButton-root {
  &.Mui-disabled {
    color:var(--gray7)  !important;
    background-color: var(--gray4) !important;
    font-weight: var(--font-weight-600) !important;
    border-color: var(--gray7)  !important;
  }
}

// button[type="button"]:disabled {
//   color:#FFFFFF !important;
//   background-color: #445bc7 !important;
//   border-color: #FFFFFF;
//   border-radius: 4px !important;
//   padding: 2px;
// }

.level-of-education-active {
  background: var(--secondary-color)  !important;
  color: var(--primary-color)  !important;
  border: 1px solid transparent !important;
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 5px 15px !important;
  margin-top: 5px !important;
  font-size: var(--font-size-16) !important;
}

.level-of-education {
  color:var(--gray6)  !important;
  border: 1px solid var(--gray6) !important;
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 5px 15px !important;
  margin-top: 5px !important;
  font-size: var(--font-size-16) !important;
}

.back-button {
  color: var(--gray9);
  background-color: transparent;
  font-size: var(--font-size-16);
  padding: 5px 20px;
  text-transform: none;
  box-shadow: none;
  border-radius: 8px;
}

.btn {
  &.assessment-btn {
    border: 1px dashed var(--primary-color);
    color: var(--primary-color);
    background-color: $bg-white;
    font-weight:  var(--font-weight-600);
    font-size: var(--font-size-16);
    padding: 5px 20px;
    text-transform: none;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);

    &:hover {
      background-color: $bg-white  !important;
      box-shadow: none;
    }
  }
}
button[type="submit"]:disabled {
  color:var(--gray7)  !important;
  background-color:var(--gray4) !important;
  font-weight:  var(--font-weight-600) !important;
  // border-color: #FFFFFF;
}
// .btn{
//   &.send-btn{
//     // border: 1px solid var(--primary-color);
//     color: $white-color;
//     background-color: var(--primary-color);
//     font-size: 16px;
//     padding:5px 20px;
//     text-transform: none;
//     box-shadow: none;
//     border-radius: 8px;
//     font-family: $font-family;
//     &:hover{
//       // background-color: var(--primary-color) !important;
//       box-shadow: none;
//       background-color: $primary-shadow-color;
//     }
//   }
// }

.btn {
  &.send-disabled {
    color:var(--gray7)  !important;
    background-color: var(--gray4) !important;
    font-weight: var(--font-weight-600) !important;
    padding: 5px 20px;
    text-transform: none;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);
    pointer-events: none;

    &:hover {
      background-color: $bg-disable;
      box-shadow: none;
    }
  }
}
.btn {
  &.send-disabled-interview {
    color:var(--gray6)  !important;
    background-color: $gray3 !important;
    font-weight: var(--font-weight-500) !important;
    padding: 2px 30px;
    text-transform: none;
    height: 30px;
min-height: 30px;
min-width: 110px;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);
    pointer-events: none;

    &:hover {
      background-color: $bg-disable;
      box-shadow: none;
    }
  }
  &.send-disabled-selected {
    color:#FCFCFD  !important;
    background-color: var(--gray6) !important;
    font-weight: var(--font-weight-500) !important;
    padding: 2px 30px;
    text-transform: none;
    height: 30px;
min-height: 30px;
min-width: 110px;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);
    pointer-events: none;

    &:hover {
      background-color: $bg-disable;
      box-shadow: none;
    }
  }
}

.search-byId {
  Button {
    padding: 6px !important;
    min-width: 42px !important;
    border-radius: 8px !important;
  }

  .btn {
    &.edu-filter-active {
      background-color: #445BC7;
      font-size: var(--font-size-14);
      border: 1px solid #696871;
      border-radius: 8px;
      padding: 5px 20px;
      color: #FFFFFF;
      font-weight:  var(--font-weight-400);
      margin-right: 10px;
      text-transform: initial !important;
      margin-top: 15px;
    }
  }

  .btn {
    &.edu-filter-name {
      background: #FFFFFF;
      border: 1px solid #696871;
      border-radius: 8px;
      font-size: var(--font-size-14);
      padding: 5px 20px;
      color: #696871;
      font-weight:  var(--font-weight-400);
      margin-right: 10px;
      text-transform: initial !important;
      margin-top: 15px;
    }
  }
}

.icon-button {
  :focus {
    outline: none !important;
    border: none !important;
  }
}

.bulk-upload-btn {
  .btn {
    &.profile-button {
      padding: 8px !important;
    }
  }
}

.btn {
  &.Mui-focusVisible {
    box-shadow: none !important;
    // background-color: transparent !important;
  }
}
.btn:focus-visible{
    border:2px solid #000;
    border-radius: 8px;
}
.MuiButton-root:focus-visible{
  border:2px solid #000 !important;
  border-radius: 8px;
}

.daterangepicker {
  .drp-buttons {
    .applyBtn {
      border: 1px solid var(--primary-color)  !important;
      color: $white-color  !important;
      background-color: var(--primary-color)  !important;
      font-size: var(--font-size-16) !important;
      padding: 5px 20px !important;
      text-transform: none !important;
      box-shadow: none !important;
      border-radius: 8px !important;
      font-family: var(--font-family)  !important;

      &:hover {
        border: 1px solid var(--hover-button-color)  !important;
        background-color: var(--hover-button-color)  !important;
        color: var(--hover-button-text-color)  !important;
        box-shadow: none !important;
      }
    }
  }
}

.daterangepicker {
  .drp-buttons {
    .cancelBtn {
      border: 1px solid var(--primary-color)  !important;
      color: var(--primary-color)  !important;
      background-color: transparent !important;
      font-size: var(--font-size-16) !important;
      padding: 5px 20px !important;
      text-transform: none !important;
      box-shadow: none !important;
      border-radius: 8px !important;
      font-family: var(--font-family)  !important;

      &:hover {
        border: 1px solid var(--hover-button-color)  !important;
        background-color: var(--hover-button-color)  !important;
        color: var(--hover-button-text-color)  !important;
        box-shadow: none !important;
      }
    }
  }
}

.request-count {
  border-radius: 50% !important;
  padding: 2px;
  font-size: var(--font-size-10);
  color: #FFF;
  background: $danger-color;
  border: 1px solid #FFFFFF;
  margin-left: 5px !important;
  width: 20px !important;
  height: 20px !important;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1px;
}

.active-list-view {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 7px;
  padding: 5px;
  width: 30px;
}

.inactive-list-view {
  background-color: #FFFFFF;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 5px;
  width: 30px;
}

.btn {
  &.remove-button {
    border: 1px solid $danger-color;
    color: $danger-color;
    background-color: transparent;
    font-size: var(--font-size-16);
    padding: 5px 20px;
    text-transform: none;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.shortlist-btn {
  .btn {
    &.primary-button {
      padding: 5px 20px !important;
    }
  }
}
.add-candidate-btn {
  .btn {
    &.primary-button {
      padding: 11px 20px !important;
    }
  }
}
.generateSample-jobWizard {
  .btn {
    &.primary-button {
      padding: 8px 50px !important;
    }
  }
}

.postJob-btn {
  border: none;
  padding: 13px 28px !important;
}
.jobWizard-btn {
  border: none;
  padding: 11px 27px !important;
}
.upload-button{
  padding: 10px 28px !important;
}

.MuiSvgIcon-root:focus {
  outline: none !important;
}
.lead-shortlist-btn{
  .lead-shortlist{
    .MuiButton-root{
      padding-right: 95px !important;
    }
  }
}
.bionic-btn{
  button{
    min-width: 40px !important;
    margin-right: 5px !important;
    height: 30px !important;
    border-radius: 4px !important;
    &.Mui-disabled {
      color:var(--gray7)  !important;
      background-color: var(--gray4) !important;
      font-weight: var(--font-weight-600) !important;
       border-color: var(--gray7)  !important;
    }
    &:hover{
      background-color: none !important;
    }
  }
  .bionic-black{
    background-color: #000000 !important;
    &:hover{
      background-color: #000000 !important;
    }
  }
  .bionic-pink{
    background-color: #6941c6 !important;
    &:hover{
      background-color: #6941c6 !important;
    }
  }
  .bionic-blue{
    background-color: #1976d2 !important;
    &:hover{
      background-color: #1976d2 !important;
    }
  }
  .bionic-snow{
    background-color: #1a7fae !important;
    &:hover{
      background-color: #1a7fae !important;
    }
  }
  .bionic-green{
    background-color: #027a48 !important;
    &:hover{
      background-color: #027a48 !important;
    }
  }
  .bionic-maroon{
    background-color: #b42318 !important;
    &:hover{
      background-color: #b42318 !important;
    }
  }
}
.active-black-button {
    border: 2px solid #000000 !important;
    box-shadow: 0 0 0 3px #f3f5f6 inset;
    color: rgba(0, 0, 0, 0.87);
    background-color: #000000 !important;
}

.active-pink-button {
  border: 2px solid #6941C6 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #6941C6 !important;
}

.active-blue-button {
  border: 2px solid #150349 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #150349 !important;
}

.active-snow-button {
  border: 2px solid #1A7FAE !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #1A7FAE !important;
}

.active-green-button {
  border: 2px solid #027A48 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #027A48 !important;
}

.active-maroon-button{
  border: 2px solid #B42318 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #B42318 !important;
}

.active-gray-button{
  border: 2px solid #e4e7ec !important;
  color: #000000 !important;
  background-color: #e4e7ec !important;
}

.bionic-bold{
  button{
    min-width: 40px !important;
    height: 30px !important;
    margin-right: 5px !important;
    border-color: #FFFFFF!important;
    .MuiToggleButton-root{
      min-width: 25px !important;
      margin-right: 5px !important;
      height: 25px !important;
      text-align: center;
    }
  }
}

.bionic-save{
  button{
    border-radius: 4px !important;
    &:hover{
      background-color: none !important;
    }
    &.Mui-disabled{
      height: 42px !important;
    }
  }
  .bionic-blue{
    background-color: var(--primary-color) !important;;
    &:hover{
      background-color: var(--primary-color) !important;
    }
  }
}
.border-none-bionic {
  border: none !important;
  &:hover{
    background-color: transparent !important;
  }
}
.MuiPickersDay-current:not(.MuiPickersDay-dayDisabled){
  color: initial !important;
}
.MuiPickersDay-daySelected{
  .MuiTypography-colorInherit{
    color: white !important;
  }
}
.btn {
  &.interview-add {
    background: $bg-white  !important;
    color: var(--gray7)  !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-400);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border: 1px solid var(--gray7);
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: $bg-white  !important;
      box-shadow: none !important;
      color: var(--gray7)  !important;
    }
  }
}
.btn {
  &.virtual-btn {
    padding: 6px 16px !important;
  }
}
.btn {
  &.interviewer-btn {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: $bg-white;
    font-weight:  var(--font-weight-500);
    font-size: var(--font-size-14);
    padding: 6px 8px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);

    &:hover {
      background-color: $bg-white  !important;
      box-shadow: none;
    }
  }
}

.btn{
  &.create-ai-btn{
    background: var(--primary-color) !important;
    color:var(--gray1) !important;
    font-size: var(--font-size-14);
    font-weight:var(--font-weight-500);
    padding: 3px 14px;
    text-transform: none;
    box-shadow: none;
    border-radius: 10px;
    font-family: var(--font-family);
    &:hover{
      background: var(--primary-color) !important;
      box-shadow: none !important;
      color: var(--gray1) !important;
    }
  }
}
.btn{
  &.primary-button{
    &.calendar-btn{
      border-radius: 20px !important;
      padding: 3px 30px !important;
    }
  }
}
.schedule-accordion {
  .MuiAccordionSummary-content {
    margin: 15px 0 !important;
  }
}
.disable-btn-align{
  padding: 8px 18px !important;
}
.btn {
  &.danger-button {
    background: #FEF3F2;
    color: #B42318 !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: #FEF3F2  !important;
      box-shadow: none !important;
      color: #B42318  !important;
    }
  }
}
.location-btn{
  .btn{
    font-size: 12px !important;
    padding: 5px 10px !important;
    min-width: 20px !important;
    border-radius: 4px;
  }
}