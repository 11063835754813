* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {
  font-family: 'Montserrat', sans-serif;
}*/

button {
  cursor: pointer;
}
.cursor-hover{
  cursor: pointer;
}
button:disabled {
  cursor: default;
}

.cursor-default{
  cursor: default;
}

.MuiTypography-root {
  margin: 0;
  font-family: var(--font-family) !important;
}
