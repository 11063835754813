/*Background-Colors*/
.bg-white {
  background-color: $bg-white;
}

.bg-black {
  background-color: $bg-black;
}

.bg-input {
  background-color: $bg-input-gray;
}

.bg-grey {
  background-color: $bg-grey !important;
}

.bg-light-blue {
  background-color: $bg-light-blue;
}

.bg-purple {
  background-color: $bg-purple;
}

.bg-light-gray {
  background-color: $bg-light-gray;
}

.danger-color {
  color: $danger-color;
}

.bg-green {
  background-color: $bg-green;
}

.side-menu-color {
  color: $side-menu-color;
}

.side-menu-bg {
  background-color: var(--secondary-color);
}

/*Text-color*/
.primary-text-color {
  color: $primary-text-color  !important;
}
.secondary-text-color {
  color: var(--secondary-color)  !important;
}
.primary-btn-text {
  color: var(--primary-color) !important;
}
.sub-text-color {
  color: var(--gray7)  !important;
}

.gray11 .MuiFormControlLabel-label {
  color: var(--gray11) !important;
}

.light-text {
  color: $light-black  !important;
}

.c-white {
  color: $white-color;
}

.light-grey {
  color: $light-grey;
}

.light-black {
  color: $black-light;
}

.medium-dark-grey {
  //color: $davys-grey;
}

.dark-shade-grey {
  //color:$dark-shade-grey;
}

.primary-shadow-color {
  color: var(--primary-color)  !important;
}

.text-green {
  //color:$text-green;
}

/*Link color*/
.link-color {
  color: var(--primary-color)  !important;
  cursor: pointer;
}

.link-color-dark {
  color: $link-color-dark  !important;
  cursor: pointer;
}

.text-decoration-color {
  text-decoration-color: var(--primary-color)  !important;
}

.danger-color {
  color: $danger-color  !important;
}

.purple-color {
  color: $purple-color  !important;
}

.color-primary {
  color: $primary-text-color;
}

.color-info {
  color: $info-color  !important;
}

.text-black {
  color: $black-color  !important;
}

.applied-color {
  color: $applied-color;
}

.sub-info li a:active {
  color: var(--primary-color)  !important;
  font-weight: var(--font-weight-600);
}

.brand-color-link {
  color: var(--primary-color);
}

.border-1 {
  border: 1px solid var(--gray4);
}

.shadow-0 {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0px 1px 2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%);
}

.warn-color {
  color: $warning-color  !important;
}

.success-color {
  color: $success-color  !important;
}

.border-bottom-1 {
  border-bottom: 1px solid rgba(105, 104, 113, 0.2);
}


.border-top-1 {
  border-top: 1px solid rgba(105, 104, 113, 0.2);
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
  color: var(--primary-color)  !important;
}

.cursor-default {
  cursor: default !important;
}

.rating .MuiRating-icon {
  color: $warning-color  !important;
}

.candidateDecline {
  background: rgba(201, 41, 56, 0.2);
  border-radius: 10px;
}

.candidateAccepted {
  background: rgba(147, 218, 140, 0.788);
  border-radius: 10px;
  color: white;
}

.text-decoration-grey {
  text-decoration-color: #1F1F1F !important;
}

.c-grey-link {
  color: #696871 !important;
  cursor: pointer;
  text-decoration: underline;
}

.decorationcolor {
  &:hover {
    text-decoration: underline !important;
    color: var(--primary-color)  !important;
  }
}

.brand-color {
  color: var(--primary-color);
}

.job-list-header {
  background: var(--dashboard-color) !important;
  // background: linear-gradient(92.14deg, var(--primary-color) 0.47%, var(--secondary-color) 102.41%);
  // background: linear-gradient(90.22deg, #150349 0.19%, rgba(0, 113, 227, 0.25) 108.33%);
  // padding: 16px 20px 16px !important;
}

.job-header {
  background: #FFFFFF;
  border: 1px solid var(--gray4);
  border-radius: 7px;
}

.default-img-border {
  background: var(--secondary-color);
  //border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: 1px;
  color: var(--primary-color);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-600);
}
.default-img-border-access-control {
  background: var(--secondary-color);
  //border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: 1px;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-600);
}

.default-manager-img {
  background: #D1976C;
  //border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: 1px;
  color: #FFFFFF;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500) !important;
  opacity: 0.5;
}

.brand-blue-color {
  color: #09b9fa;
}

// .send-btn {
//   color: #002962 !important;
// }
.shadow-2 {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.border-right {
  border-right: 1px solid rgba(105, 104, 113, 0.2);
}

.edit-details {
  a {
    color: #696871 !important;
  }
}

.border-left-line {
  border-left: 1px solid #E4E4E7 !important;
}
.border-left-primary {
  border-left: 3px solid var(--primary-color) !important;
}
.border-left-danger {
  border-left: 3px solid #B54708 !important;
}
.border-left-success {
  border-left: 3px solid #150349 !important;
}
.border-left-pending {
  border-left: 3px solid #b54708 !important;
}
.border-left-accepted {
  border-left: 3px solid #1A7FAE !important;
}
.border-left-closed {
  border-left: 3px solid #363f72 !important;
}
.decisionToHireSuccess {
  background-color: #E5f0FC;
  color: $success-color;
  border-radius: 6px;
}
.decisionToHireNotSure {
  background-color: #fffaeb;
  color: #b54708;
  border-radius: 6px;
}
.decisionToHireNo {
  background-color: #fef3f2;
  color: #b42318;
  border-radius: 6px;
}
.occupiedStatus {
  background: #FFEBE6;
  border-radius: 20px;
  color: #B54708;
  padding: 8px;
}

.bulk-inProgress {
  color: #6941C6;
}

.bulk-yetToProcess {
  color: $purple-color;
}

.myCandidate-Ongoing {
  padding: 4px 8px;
  border-radius: 24px;
  background-color: #FFF4E8;
  color: #B16007 !important;
  font-size: 11px;
  font-weight: var(--font-weight-600);
}

.my-candidate-Ongoing {
  color: #1A7FAE;
  padding: 8px;
  border-radius: 20px;
  background-color: #DEEBFF;
}

.interviewAcceptedStatus {
  color: #1A7FAE;
  padding: 4px 15px;
  border-radius: 20px;
  background-color: #EBF9FF;
}

.matching-weightage {
  background-color: #FFFFFF;
  // display: flex;
  // justify-content: space-between;
}

.scoringModelColor {
  background-color: #F8F9FC;
}

.matchingscore-lightColor {
  color: #9C9C9C;
}

.step-color {
  color: var(--primary-color)  !important;
}

// New Design color code
// Text colors
.gray1 {
  color: var(--gray1);
}
.gray4{
  color: var(--gray4);
}
.gray6{
  color: var(--gray6) !important;
}
.gray7 {
  color: var(--gray7);
}

.gray8 {
  color: var(--gray8);
}

.gray9 {
  color: var(--gray9)  !important;
}
.gray10 {
  color: var(--gray10) !important;
}
.gray11 {
  color: var(--gray11);
}
.disable-color{
  color:$bg-disable !important;
  pointer-events: none;
}
.disable-border{
  border: 1px solid $bg-disable;
}
.success-color {
  color: $success-color;
}

.secondary-bg-color {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  &:hover {
    background-color: var(--hover-button-color) !important;
    color: var(--hover-button-text-color) !important;
    .svg-icons > path {
      stroke: var(--hover-button-text-color) !important;
    }
    .fill-svg-icons > path {
      fill: var(--hover-button-text-color) !important;
    }
  }
}

.cohort-secondary-bg-color {
  background-color: var(--secondary-color);
  >path {
    stroke: var(--primary-color) !important;
  }
  &:hover {
    background-color: var(--hover-button-color) !important;
    >path {
      stroke: var(--hover-button-text-color) !important;
    }
  }
}

.notification-secondary-bg-color {
  background-color: var(--secondary-color);
}
.checklist-secondary-bg-color {
    background: var(--secondary-color)  !important;
    color: var(--primary-color)  !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
}

.blue-color {
  color: $blue-color  !important;
}

.bg-gray2 {
  background-color: $bg-gray2;
}

.bg-gray3 {
  background: $gray3;
}
.user-bg-green {
  background: #027A48 !important;
}
.user-bg-primarycolor {
  background: var(--primary-color) !important;
}

.search-background {
//  background-color: var(--primary-color);
  svg {
    stroke: #fff;
  }
}

.border-bottom-gray4 {
  border-bottom: 1px solid var(--gray4) !important;
}
.border-bottom-gray6 {
  border-bottom: 1px solid $gray6 !important;
}
.border-top-gray4 {
  border-top: 1px solid var(--gray4) !important;
}
.border-top-gray5 {
  border-top: 1px solid var(--gray5) !important;
}
.border-gray6{
  border: 1px solid $gray6 !important;
}
.border-right-gray4{
  border-right:1px solid $gray4
}
.border-right-gray7 {
  border-right: 1.3px solid var(--gray7)  !important;
}
.border-gray7{
  border:1px solid $gray7;
}
.border-bottom {
  border-bottom: 1px solid #EBEBEB
}
.border-bottom-gray5{
  border-bottom: 1px solid #D0D5DD;
}
.border-dot {
  border: 1px dashed #98A2B3;
}
.border-dashed {
  border: 1px dashed var(--primary-color);
  border-radius: 4px;
}
.scheduled-date-border {
  border: 1px dotted var(--primary-color);
  border-radius: 4px;
}
.disable-border{
  border: 1px solid $bg-disable;
}
.bg-light-gray2 {
  background-color: $bg-light-gray2;
}

.border-bottom:last-child {
  border-bottom: none;
}

.border-radius-7 {
  border-radius: 7px;
}

.available-status {
  // background-color: #ecfdf3;
  // color: #027A48 !important;
  padding: 4px 15px;
  border-radius: 20px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
}

.occupied-status {
  // background: #FFEBE6;
  border-radius: 20px;
  // color: #B42318;
  padding: 5px 15px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  display: flex;
}
.inactive-status {
  background: #FFEBE6;
    color: #B42318 !important;
    padding: 4px 15px;
    border-radius: 20px;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-500);

}

.maybe-available-status {
  // background: #FFFAEB;
  border-radius: 20px;
  // color: #B54708;
  padding: 6px 10px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
}
.horizontal-line{
    border: 1px solid #e4e7ec;
    height: 2px;
    width: 35px;
}
.offer-line{
  border: 1px solid #e4e7ec;
  height:0px;
  width: 35px;
}
.logo-bg-neuro {
  background: #EAF4FF;
  padding:30px !important;
}
.login-bg{
  //background: linear-gradient(135.83deg, var(--primary-color) 44.94%, rgba(0, 113, 227, 0.25) 157.15%) !important;
  background: var(--dashboard-color);
  padding:30px !important;
  // position: fixed;
  // bottom: 0px;
  z-index:9;
}
.danger-text-color{
  color: #B54708;
}
.text-black-color {
  color: #18181B;
}
.border-top-grey4 {
  border-top: 1px solid #E4E7EC;
}
.offer-decline-status {
  background-color: rgb(255, 235, 230);
}
.offer_decline{
  margin-left: 10px;
  padding: 5px 10px;
  background-color: rgb(255, 235, 230);
  color: rgb(180, 35, 24);
  border-radius: 20px;
}
.offer_accept {
  background-color: rgb(236, 253, 243);
  color: rgb(2, 122, 72);
}
.offer-label{
  .MuiStepper-root{
    .MuiStepButton-root{
      &.offer-stepactive{
        .MuiSvgIcon-root{
          color: var(--primary-color) !important;
          width: 40px !important;
          height: 28px !important;
          .MuiStepIcon-text{
              fill:#FFFFFF !important;
          }
        }
      }
    }
  }
}
.closure-border{
  border-left: 1px solid #B5B5B5;
}
.expired-closure{
  background-color: #EEF0F2;
 }
.heading-text-color{
  color: var(--heading-text-color) !important;
}
.main-text-color{
  color: var(--main-text-color) !important;
}
.MuiTableRow-hover{
  &:hover{
    background-color: var(--list-hover-color) !important;
  }
}
.list-hover-color{
  &:hover{
    background-color: var(--list-hover-color) !important;
  }
}
// New theme updated colors
.closure-bg-decline{
  background-color: #FFEBEA;
}
.closure-bg-hold{
  background-color: #FFFAEB;
}
.closure-bg-accept{
  background-color: #E5f0FC;
}
.default-bg-color{
  background-color: #238B23;
}
.sapphire-bg-color{
  background-color: #1A7FAE;
}
.crimson-bg-color{
  background-color: #B42318;
}
.aqua-bg-color{
  background-color: #008081;
}
.magenta-bg-color{
  background-color: #AF26B1;
}
.lavendar-bg-color{
  background-color: #6941C6;
}
.cobalt-bg-color{
  background-color: #150349;
}
.senset-bg-color{
  background-color: #B15826;
}
.neuro-default-bg-color{
  background-color: #055094;
}
.orchid-bg-color{
  background-color: #704FAE;
}
.vivid-bg-color{
  background-color: #A95578;
}
.turbo-bg-color{
  background-color: #1A7FAE;
}
.olive-bg-color{
  background-color: #629462;
}

.cookie-btn {
  .btn{
    &.cookie-Primary-button {
      color: #FCFCFD !important;
      background-color: #150349 !important;
    }
  }
  .btn{
    &.cookie-secondary-button {
      color: #150349 !important;
      background-color: #E5f0FC !important;
      &:hover {
        color: #FCFCFD !important;
        background-color: #150349 !important;
      }
    }
  }
}