.accordion-section{
 margin-bottom: 10em;
  .MuiPaper-root{
    margin: 30px 0;
    background: #FFFFFF;
    border: 1px solid #EAE9F2;
    border-radius: 10px;
    .MuiButtonBase-root{
      background: #FFFFFF;
      border-bottom: none;
      border-radius: 10px;
    }
    &:before{
      background-color: #FFFFFF;
    }
  }
}

/*Checkbox-color*/
.radio-button-class{
  border-top: 1px solid #EAE9F2;
  margin-right: 35px !important;
  .MuiRadio-root{
    &.MuiButtonBase-root{
      display: none;
    }
  }
}

.accordian-radio-container{
  padding: 15px 0;
  padding-left: 20px;
}

.chatbot-container{
  padding: 15px 123px;
  border: 1px solid #EAE9F2;
}
/*.MuiPaper-root-MuiAccordion-root:before{
  background-color: none;
}*/

.accordion-Select-option{
  background-color: #EFF0F6;
  .MuiInputBase-root{
    .MuiSelect-select{
      // padding: 0 10px;
      font-size: var(--font-size-12);
      padding-right:27px !important;
    }
  }
}

.MuiAccordionSummary-root{
&:last-child {
  margin-top: 10em;
}
}
.maintemplate-title{
  .MuiButtonBase-root{
    .MuiAccordionSummary-content {
      align-items: center !important;
    }
  }
}

.MuiButtonBase-root{
  &.MuiPaginationItem-root{
    &.MuiPaginationItem-previousNext{
        svg{
          // color: #FFFFFF !important;
          z-index: 99;
              }
              .MuiTouchRipple-root{
            background-color: var(--primary-color);
              }
    }
  
  }
 
}

.MuiButtonBase-root{
  &.MuiPaginationItem-root{
    &.MuiPaginationItem-firstLast{
        svg{
          // color: #FFFFFF !important;
          z-index: 99;
              }
              .MuiTouchRipple-root{
            background-color: var(--primary-color);
              }
    }
  
  }
 
}

.MuiButtonBase-root{
  &.MuiPaginationItem-root{
    &.Mui-disabled{
        svg{
          color: #000 !important;
          z-index: 99;
              }
              .MuiTouchRipple-root{
            background-color: var(--primary-color);
              }
    }
  
  }
 
}


//Scroll-hide-nav-bar
.sticky {
  display: grid;
align-content: center;
justify-content: center;
//position: fixed;
bottom: 0px;
width: 20vw;
/* min-height: 10vw; */
}

.hideScrollDown {
  left: 0;
  bottom: 40vh;
}

.displayArea .disableAnimations .fitToPage{
  height:100vh !important;
}

.explorationContainer .exploreCanvas .canvasFlexBox .displayAreaContainer .displayArea{
  height:100vh !important;
}
.request-expand{
  box-shadow: none !important;
  .MuiPaper-root.MuiPopover-paper {
    box-shadow: none !important;
  }
  .MuiAccordionSummary-root{
    .Mui-expanded{
      margin: 0px !important;
    }
  }
  .MuiAccordionSummary-expandIconWrapper{
    position: absolute;
    right: 25px;
    top: 41px;
    &.Mui-expanded{
      top: 34px !important;
    }
  }
}
.file-cards{
  border: 1px solid var(--gray4);
  border-radius: 8px;
}
.count-border{
  position: absolute;
  border-left: 2px solid #EAF4FF;
  height: -webkit-fill-available;
  left: -47px;
  width: 100%;
}
.left-count-border{
  border-left: 2px solid var(--primary-color);
  width: 10px;
  height: 1.7px;
  display: inline-flex;
  position: absolute;
  left: -51px;
  border-width: 10px;
  // top: 25px;
  margin-top: 10px !important;
}