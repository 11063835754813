/*material-tabs*/
.material-tab {
  .MuiTabs-root {
    .MuiTabs-indicator {
      height: 8px;
      background: var(--primary-color);
      /* border-radius: 10px;*/
    }

    .MuiTab-root {
      text-transform: capitalize;
      font-size: var(--font-size-16);
      width: 50px;
      color: #262626;

      &.Mui-selected {
        color: var(--primary-color);
      }

      .MuiTouchRipple-root {
        border-bottom: 8px solid var(--primary-color);
        margin: 0 8px;
        opacity: 20%;
      }
    }
  }
}


.MuiListItemButton-root {
  &.Mui-selected {
    background: rgba(114, 46, 204, 0.2) !important;
    color: var(--primary-color);
  }

  a {
    text-decoration: none;
  }
}

.tabs-selected-text {
  .Mui-selected p {
    color: var(--primary-color);
  }
}

.MuiPaper-root {
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25) !important;
  // border: 1px solid $gray4  !important;
  border-radius: 8px !important;

  .MuiMenu-list {
    .MuiMenuItem-root {
      font-family: var(--font-family)  !important;
      font-size: var(--font-size-14);
      color: var(--gray9);
      // font-weight: 500;

      // cursor: context-menu !important;
      span {
        svg {
          font-size: var(--font-size-16);
        }
      }
    }
  }
}

.message-section {
  .MuiTabs-root {
    .MuiButtonBase-root {
      &.Mui-disabled {
        border-bottom: 0px solid var(--primary-color)  !important;
      }
    }
  }
}
.MuiPopover-paper {
  .MuiMenuItem-root {
    &:hover {
      background-color: transparent;
    }
  }
}

.MuiTabs-root {
  .MuiButtonBase-root {
    &.Mui-disabled {
      background-color: #FFFFFF !important;
      border-bottom: 8px solid var(--primary-color);
      margin: 0 8px;
      opacity: 20%;
    }
  }
}

.chip-dropdown {
  // border: 1px solid $gray7 !important;
  border-radius: 6px !important;
  padding: 8px 10px 8px 13px !important;
  text-transform: none !important;
  margin: 10px 8px 10px 0px !important;
  font-size: var(--font-size-16);
  color: var(--gray7)  !important;
  background-color: $gray3  !important;

  img {
    padding: 10px;
  }
}

.chip-dropdown-active {
  // border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  border-radius: 6px !important;
  color: #FFFFFF !important;
  padding: 8px 10px 8px 13px !important;
  text-transform: initial !important;
  margin: 10px 8px 10px 0px !important;
  font-size: var(--font-size-16);

  img {
    padding: 10px;
  }

  &:hover {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
  }
}

.ethnicity-chip {
  background: var(--secondary-color) !important;
  border-radius: 6px;
  padding: 4px 12px !important;
  color: var(--primary-color) !important;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  display: inline-flex !important;
  align-items: center !important;
  margin: 4px 6px 4px 0px;
  svg {
    fill: var(--primary-color) !important;
  }
}
.ethnicity-checkbox{
  color: #98A2B3 !important;
  background: none;
  padding: 0px !important;
  &.Mui-checked {
    color: var(--primary-color) !important;
    &.hover {
      background: none;
      padding: 0px;
    }
  }
}

.offer-letter {
  border-radius: 6px !important;
  padding: 5px 40px 5px 40px !important;
  text-transform: none !important;
  margin: 10px 8px 10px 0px !important;
  font-size: var(--font-size-16);
  color: var(--primary-color) !important;
  background-color: var(--secondary-color)!important;

}

.offer-letter-active {
  border-radius: 6px !important;
  padding: 5px 40px 5px 40px !important;
  text-transform: none !important;
  margin: 10px 8px 10px 0px !important;
  font-size: var(--font-size-16);
  color: #FFFFFF !important;
  background-color: var(--primary-color)!important;

  &:hover {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
  }
}

.matching-tab {
  .MuiBox-root {
    padding: 0px !important;
  }

  .MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        height: 0px;
      }
    }

    .MuiTabs-flexContainer {
      justify-content: initial !important;
    }

    .MuiTab-root {
      text-transform: capitalize;
      font-family: var(--font-family)  !important;
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-500);
      padding: 0px 15px !important;
      border: 1px solid var(--gray7);
      border-radius: 21px;
      color: var(--gray7);
      min-height: 33px !important;
      margin: 0px 10px;

      &.Mui-selected {
        color: #FFFFFF;
        background: var(--primary-color);
        border-radius: 21px;
        border: 1px solid var(--primary-color);
      }
    }
  }
}

.note-jerk {
  .chip-dropdown-active {
    margin-left: 0px !important;
  }
}

.advanced-search {
  .MuiButtonBase-root {
    text-transform: capitalize !important;
    color: #000000 !important;
    font-size: var(--font-size-16) !important;
    padding: 0px !important;
    margin-right: 20px !important;
    display: inline-grid !important;
    justify-content: left !important;

    &.Mui-selected {
      color: var(--primary-color) !important;
      padding: 0px !important;
    }
  }

  .MuiTabs-indicator {
    background-color: var(--primary-color) !important;
  }
}

.MuiSlider-thumb {
  color: var(--primary-color) !important;
  width: 12px !important;
  height: 12px !important;
}

.MuiSlider-track {
  color: var(--primary-color) !important;
  opacity: 0.2;
  border-radius: 6px !important;
  height: 2px !important;
}

.MuiSlider-rail {
  background: #EAEAEA !important;
  border-radius: 10px !important;
  color: #EAEAEA !important;
}

// Basic information stepper//
.basic-stepper-align {
  .link-color {
    color: #1973B9 !important;
  }

  .MuiStepLabel-root {
    align-items: flex-start !important;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 13px !important;
  }

  .MuiStepConnector-line {
    // right: 129px !important;
    // margin: 11px 15px 10px 0px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 80px !important;
  }

  .MuiStepLabel-label {
    font-weight: var(--font-weight-700) !important;
    font-size: var(--font-size-18) !important;
    // margin-top: 20px !important;
    font-family: var(--font-family);
  }

  .stepper-detail {
    // color: #696871;
    font-size: var(--font-size-14) !important;
    line-height: 20px !important;
    display: flex !important;
    margin-top: 5px !important;
  }
}

.MuiStepConnector-vertical {
  text-align: initial;
  margin-left: 22px !important;
  left: 0px !important;
}

.stepper-tab-align {
  .MuiBox-root {
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.stepper-align {
  // margin-top: 20px !important;
}

.skill-tab-align {
  .MuiBox-root {
    padding-top: 0px !important;
  }
}

.skill-tab-aligned {
  .MuiBox-root {
    padding-top: 8px !important;
  }
}

/* edit profile tabs */
.matching-drawer-tab {
  button {
    min-width: 90px !important;
  }
}

.edit-profile-tabs {
  button {
    padding: 0 16px 0 16px !important;
    text-transform: capitalize !important;
    font-size: var(--font-size-14) !important;
    font-weight: var(--font-weight-500);
    margin-right: 30px !important;
    color: var(--gray9) !important;
  }
  .MuiTabs-indicator {
    background: var(--primary-color) !important;
  }
  .MuiTabs-flexContainer {
    border-bottom: 2px solid #ebebeb;
    // justify-content: space-between !important;
    .Mui-selected {
      color: var(--primary-color) !important;
      font-weight: var(--font-weight-600) !important;
      font-family: var(--font-family) !important;
    }
  }
}

.profile-tabs {
  button {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    font-weight: var(--font-weight-400) !important;
    margin-right: 30px !important;
    color: var(--gray7)  !important;
  }

  .MuiTabs-indicator {
    background: var(--primary-color) !important;
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #E8E8E8;

    .Mui-selected {
      color: var(--primary-color) !important;
    }
  }
}

.candidates-leads-list {
  .MuiTab-root {
    background: #EFF0F6;
    border-radius: 2px;
    color: #696871 !important;
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-14);
    padding: 5px 15px !important;
    text-transform: capitalize !important;
    min-height: 38px;
    width: 140px;

    &.Mui-selected {
      background: var(--primary-color) !important;
      border: 1px solidvar(--primary-color) !important;
      color: #FFFFFF !important;
    }
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}

.select-leads {
  .MuiSelect-select {
    outline: none !important;
  }

  .MuiPaper-root {
    .MuiList-root {
      .MuiMenuItem-root.Mui-selected {
        &.Mui-selected {
          background-color: green !important;
        }
      }
    }
  }
}

.matching-action-align {
  .matching-menu {
    button {
      justify-content: right !important;
      min-width: 50px;
      font-family: var(--font-family)  !important;
    }
  }
}

.list-by-status {
  .MuiTabs-indicator {
    background-color: transparent !important;
  }

  .PrivateTabIndicator-colorSecondary-5 {
    background-color: transparent !important;
  }

  .Mui-selected {
    background: var(--primary-color) !important;
    border: 1px solidvar(--primary-color) !important;
    color: #FFFFFF !important;
  }

  .MuiTab-root {
    border-radius: 2px;
    color: #696871;
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-400);
    background: #EFF0F6;
    margin-right: 5px;
    text-transform: capitalize;
    min-width: 90px !important;
    min-height: 30px !important;
    padding: 0px !important;
  }
}

.list-by-status-tabs {
  .MuiBox-root {
    // padding-top: 0px !important;
    padding: 0px !important
  }
}

.cohort {
  // width: 95px !important;
  // margin-top: 14px !important;
  // text-align: center !important;
  // margin-left: 7px !important;
}

.time-lineStepper {
  .MuiStepConnector-vertical {
    margin-left: 12px !important;
  }

  .MuiStepLabel-labelContainer {
    position: relative;
  }

  .timeline-img {
    left: 0px;
    top: -5px;

    img {
      width: 35px;
      height: 35px;
    }
  }

  .MuiStepContent-root {
    min-height: 100px;
    max-height: 250px;
    margin-left: 7px !important;
    padding-left: 25px !important;
  }
}

.create-project-stepper {
  .stepper-detail {
    display: flex !important;
    font-size: var(--font-size-12) !important;
    font-weight: var(--font-weight-400);
    margin-top: 5px !important;
    color: var(--gray7);
    font-family: var(--font-family);
  }

  .MuiStepConnector-vertical {
    text-align: initial;
    margin-left: 35px !important;
    left: 0px !important;
  }


  .MuiStepLabel-labelContainer {
    .MuiStepLabel-label {
      font-size: 14px !important;
      font-weight: var(--font-weight-600) !important;
      color: var(--gray9);
      font-family: var(--font-family);
    }
  }
}
.MuiDrawer-modal{
  &.MuiModal-root{
    &.notification-drawer{
      z-index: 99999 !important;
    }
  }
}
.notification-drawer .MuiDrawer-paper {
  width: 30% !important;
  overflow-y: hidden;
  //top: 70px;
}

.message-job-list {
  .MuiTabs-root {
    .MuiButtonBase-root.Mui-disabled{
      border-bottom: none !important;
    }
    .MuiTab-root {
      min-height: 33px !important;
      &.Mui-selected {
        color: var(--primary-color) !important;
        background: var(--secondary-color) !important;
        border: none !important;
      }
    }

    .MuiTabs-flexContainer {
      justify-content: initial !important;
    }
  }
}

.MuiPaper-root {
  .MuiList-root {
    .logout-text {
      font-size: var(--font-size-14) !important;
    }
  }
}

.timeline-tabs {
  .Mui-selected.position-issue {
    bottom: -3px !important;
  }

  .MuiBox-root {
    padding: 0px !important;
  }

  .MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        background-color: var(--primary-color) !important;
        height: 0px;
        border-radius: 3px;
      }
    }

    .MuiTabs-flexContainer {
      // justify-content: space-between !important;
      // border-bottom: 1px solid $gray4 !important;
      border-color: rgba(0, 41, 98, 0.2) !important;
      border-radius: 3px !important;
      border-bottom: 1px solid #E4E7EC;
      padding: 30px !important;
    }

    .MuiTab-root {
      text-transform: capitalize;
      color: #363F72;
      font-family: var(--font-family);
      padding-left: 0px !important;
      padding-right: 0px !important;
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-400);
      margin-right: 10px !important;
      min-width: 130px !important;
      background: #F2F4F7;
      border-radius: 18.5px;
      min-height: 35px !important;

      &.Mui-selected {
        color: var(--primary-color);
        font-size: var(--font-size-14) !important;
        font-weight: var(--font-weight-600) !important;
        background: var(--secondary-color);
        border-radius: 18.5px;
        padding: 0px !important;
      }
    }
  }
}

.header-fixed-section {
  position: fixed;
  width: 80%;
  top: 24px;
}

.stepper-left-panel {
  // width: 25%;
  // position: fixed;
  // top: 6em;
  // bottom: 0;
  // overflow: hidden;
  position: fixed;
  top: 65px;
  width: 25%;
  bottom: 0;
  overflow-y: auto;
  height: 100vh; /* Set the initial height of the sidebar */
  max-height: 510px;
}

.stepper-right-panel {
  // width: 75%;
  // margin-left: 30% !important;
  // overflow-y: auto;
  // height: 100vh;
  margin-left: 32% !important;
  margin-top: 50px;
  height: 100vh;
  overflow: auto;
  white-space: nowrap;

  .panel-section {
    min-height: 100vh;
    padding-bottom: 6em;
  }
}

.skills-panel-section {
  min-height: 51.4vh;
  max-height: 51.4vh;
  overflow-y: auto;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: var(--font-size-18);
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px !important;
  width: 440px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  // height: 100%;
  object-fit: cover;
}
.select-option-for-tp {
  display: block;
  width: 100%;
  text-align: left !important;
  justify-content: left !important;
}
.accordian-options {
  &.MuiAccordion-root:before{
    opacity: 0 !important;
  }
  &.MuiPaper-root {
    box-shadow: none !important;
  }
  &.Mui-expanded {
    margin-top: 0px !important;
  }
  .MuiAccordionSummary-root {
    min-height: 0px !important;
    &.Mui-expanded {
      min-height: 0px !important;
      margin-top: 5px;
      }
  }
  .MuiAccordionSummary-content {
    margin:0px !important;
    &.Mui-expanded {
      margin: 0px !important;
      }
  }
  .MuiCollapse-root{
    .MuiAccordionDetails-root{
      padding: 0px !important;
      .MuiMenuItem-root{
        padding: 6px 16px !important;
        align-items: flex-start !important;
      }
    }
  }
  .MuiMenuItem-root{
    .MuiListItemText-primary{
      white-space: break-spaces;
      width: 500px;
    }
  }
}
.talent-campaign{
  .MuiSelect-select{
    width: 98%;
  }
}
.MuiListItemText-primary {
  font-size: 14px !important;
}
.max-width {
  max-width: 680px !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
}

.swiper-pagination-horizontal {
  // top: 20.9em !important;
}
.access-tabs{
  button{
    padding-left: 20px !important;
    text-transform: initial;
    font-weight: var(--font-weight-600);
    font-size: 14px !important;
    font-family: var(--font-family) !important;
  }
}
.access-box{
  .MuiBox-root{
    padding:0px !important;
  }
}
  // top: 26.6em !important;


.swiper {
  width: 100%;
  height: 100%;
}
.swiper-wrapper{
  margin-bottom: 2em;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.job-location{
  .access-control-tabs{
    button{
      padding: 5px !important;
    }
    .MuiTabs-indicator {
      display: none !important;
    }
    .MuiTabs-flexContainer{
      border-bottom: none !important;
      display: flex;
      justify-content: space-between;
    }
    .MuiButtonBase-root.Mui-disabled{
      border-bottom: none !important;
      margin: 0px !important;
    }
    .MuiTabScrollButton-horizontal {
      svg {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid $gray6;
        align-items: center;
        display: flex;
        color: $gray6;
      }
    }
  }
}
.access-control-tabs {
  button {
  //   padding-left: 0px !important;
  //   padding-right: 0px !important;
  //   padding-bottom: 0px !important;
    text-transform: capitalize !important;
    // font-size: 16px !important;
    font-weight: var(--font-weight-400) !important;
    // margin-right: 30px !important;
    color: "#1D2939"  !important;
    font-family: var(--font-family);
  }

  .MuiTabs-indicator {
    background: var(--primary-color) !important;
    margin-left: 5px !important;
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #E8E8E8;

    .Mui-selected {
      color: var(--primary-color) !important;
      font-weight: var(--font-weight-600) !important;
    }
  }
}
.interviewer{
  .MuiFormControlLabel-label{
    color: $gray9 !important;
  }
}
.tab-section-detail {
  color: var(--gray9);
  .mui-selected {
    color: var(--primary-color);
    border-bottom: 1.5px solid var(--primary-color);
    font-weight: var(--font-weight-500);
  }
}
.interview-closure-tab {
  .MuiTab-root {
    border-radius: 6px;
    background-color: var(--secondary-color);
    text-transform: capitalize;
    color: var(--primary-color) !important;
    font-size: 14px;
    font-weight: 600 !important;
    height: 30px;
    min-height: 30px;
    min-width: 110px;
    margin-right: 10px;
    font-family: $font-family !important;
    &.Mui-selected {
      color: $white-color !important;
      background-color: var(--primary-color) !important;
    }
  }
  .MuiTabs-indicator{
    height: 0px !important;
  }
}
.matching-tabs{
  .MuiTabs-root{
    .MuiButtonBase-root.Mui-disabled{
      border-bottom: none !important;
    }
    .MuiTabScrollButton-root svg{
      background-color: var(--secondary-color);
      color: var(--primary-color);
      border-radius: 4px;
      padding: 2px;
      width: 23px;
      height: 23px;
      margin-top: 7px;
    }
  }
}
.interview-status{
  .priorityStatus{
    left: 20px !important;
  }
  .mspExclusive{
    left: 8px !important;
  }
  .myCandidate-Ongoing{
    left: 16px !important;
  }
}
.pre-qualification-tabs {
  .MuiTabs-flexContainer{
    border-bottom: 1px solid $gray4 !important;
    .preq-switch{
      .MuiSwitch-root{
        .MuiSwitch-switchBase.Mui-checked{
          padding-left: 8px !important;
        }
      }
    }
    .MuiButtonBase-root{
      opacity: 1 !important;
      padding-left: 0px !important;
      margin-right: 30px !important;
      justify-content: flex-start !important;
      min-width: auto !important;
      &.Mui-disabled{
          border-bottom: none !important;
      }
      &.Mui-selected{
        .MuiTab-wrapper{
          font-weight: 500;
          color: var(--main-text-color) !important;
        }
      }
      .MuiTab-wrapper{
        text-transform: capitalize;
        font-weight: 400;
        color: $gray10 !important;
        font-family: var(--font-family) !important;
        justify-content: flex-start;
        display: inline-grid;
      }
    }
  }
  .MuiTabs-indicator{
    background-color: var(--main-text-color) !important;
  }
}
.preq-slider{
  // width:105px !important;
    text-align: center;
}
.slider-container {
  .MuiSlider-sizeMedium {
    &.MuiSlider-root {
      height: 10px !important;
      padding: 0px !important;
      cursor: pointer !important;
      border: 1px solid #B42318;
    }
    .MuiSlider-track {
      height: 10px !important;
    }
    .MuiSlider-rail {
      background-color: #FEF3F2 !important;
      border: 1px solid #B42318 !important;
      opacity: 1 !important;
    }
    .MuiSlider-track {
      color: #B42318 !important;
      opacity: 1;
      border-radius: 6px !important;
    }
  }
  .preqvalue0 {
    .MuiSlider-track {
      display: none !important;
    }
  }
  .preq-slider-success {
    .MuiSlider-sizeMedium {
      &.MuiSlider-root {
        border: 1px solid #150349 !important;
      }
      .MuiSlider-rail {
        background-color: #ECFDF3 !important;
        border: 1px solid #150349 !important;
        opacity: 1 !important;
      }
      .MuiSlider-track {
        color: #087C45 !important;
        opacity: 1;
        border-radius: 6px !important;
      }
    }
  }
}
.default-loader svg {
  height: 280px;
  width: 100%;
}
.preq-model{
  .preq-label{
    margin-bottom: 0 !important;
  }
}
.preq-label{
  padding: 4px 12px;
  border-radius: 20px;
  background: #EAF4FF;
  display: flex;
  // width: 118px !important;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px 0px 5px 0px !important;
  color:#150349;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  // &:hover{
  //   background: #e4e4e4;
  // }
}
.enterprises-dropdown{
  background-color: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
  z-index: 999999999 !important;
  position: absolute !important;
  top: 85px !important;
  left: 0px !important;
  right: 0px !important;
  margin: 0px 20px !important;
  width: auto !important;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  .add-enterprises:hover{
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}
.privacy-tabs{
  padding-left: 48px;
  .profile-tabs {
    button {
      padding: 0px !important;
      text-transform: capitalize !important;
      font-size: var(--font-size-16) !important;
      font-weight: var(--font-weight-600) !important;
      margin-right: 30px !important;
      color: #000 !important;
      font-family: var(--font-family) !important;
    }
    .MuiTabs-indicator {
      background: var(--primary-color) !important;
    }
    .MuiTabs-flexContainer {
      border-bottom: none !important;
      .Mui-selected {
        color: var(--primary-color) !important;
        font-weight: var(--font-weight-600) !important;
        font-family: var(--font-family) !important;
      }
    }
  }
}
.document-view {
  // height: 250px;
  overflow-y: auto;
}
#menu-console-location{
  .MuiPaper-root {
    .MuiMenu-list {
      .MuiMenuItem-root {
        background-color: #FFFFFF !important;
        &:hover{
          background-color: rgba(0, 0, 0, 0.04) !important;
        }
      }
    }
  }
}