.MuiDrawer-modal{
  &.MuiModal-root{
    z-index: 9999 !important;
  }
}
.MuiModal-root{
  z-index: 99999 !important;
}
.MuiDialog-root{
  z-index: 99999 !important;
}
#modal-title {
  /* position: fixed;*/
  width: 100%;
  /* padding: 10px;*/
  color: #1F1F1F;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-600);
}

#modal-description {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
  padding: 5px;
}
// #modal-description-wizard {
//   // height: 65vh;
//   max-height: 398px;
//   //height: 500px;
//   overflow-y: auto;
//   margin: 10px 0;
//   padding: 5px 5px 5px 0px;
// }

.modal-description-wizard-content {
  // height: 65vh;
  max-height: 398px;
  //height: 500px;
  overflow-y: auto;
  margin: 10px 0;
  padding: 5px 5px 5px 0px;
}

.modal-description-wizard-detail {
  // height: 65vh;
  //max-height: 398px;
  height: 400px;
  min-height: 400px;
  max-height: 500px;
  overflow-y: auto;
  margin: 10px 0px 0px 0px;
  padding: 5px 5px 0px 0px;
}

#modal-description-add-theme {
  max-height: 320px;
  overflow-y: auto;
  margin: 10px 0;
  padding: 5px;
}

.feebackModal-height {
  max-height: 350px !important;
}
.talentpoolModal-height {
  max-height: 380px !important;
}
#modal-footer {
  /* position: fixed;*/
  /* bottom: 12px;*/
}
.invite-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: $bg-white;
  padding: 20px;
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25);
  border-radius: 16px;

  &:focus-visible {
    outline: none;
  }
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: $bg-white;
  padding: 25px;
  border-radius: 8px;

  &:focus-visible {
    outline: none;
  }
}
.modal-content.work-exclusivity-modal-content {
  width: 550px !important;
}

.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  width: 410px;
  border-radius: 8px;
  text-align: center;
  background-color: white;
}
.shortlist-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: $bg-white;
  padding: 25px;
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25);
  border-radius: 16px;
  &:focus-visible {
    outline: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .modal-content {
    width: 380px;
  }
  .invite-modal-content{
    width: 380px;
  }
  .modal-content {
    &.checklist-modal &.responsive-checklist-modal {
      width: 380px !important;
    }
  }

}

#simple-popover-list {
  .MuiPaper-root {
    &.MuiPopover-paper {
      filter: drop-shadow(0px 0px 0px rgba(234, 233, 242, 0.4)) !important;
      box-shadow: none !important;
    }
  }
}

#upload-logo-image-modal {
  .modal-content {
    width: 500px;
  }
}

.displayArea .disableAnimations .fitToPage {
  width: 986.361px !important;
  left: -1.1806px !important;
}

.user-details {
  a {
    .MuiSvgIcon-root {
      color: #696871 !important;
    }
  }
}

.modal-content {
  &.checklist-modal {
    width: 900px !important;
  }
}
.select-talent-pool{
  .MuiFormControlLabel-label{
    font-size: var(--font-size-14) !important;
    color:#000000 !important;
    font-weight:var(--font-weight-400) !important;
  }
}


.modal-content1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: $bg-white;
  padding: 25px;
  border-radius: 8px;

  &:focus-visible {
    outline: none;
  }
}
#modal-description1 {
  max-height: 460px;
  overflow-y: auto;
  margin: 10px 0;
  padding: 5px;
}
.sticky-issue{
  position: sticky;
    z-index: 1;
    top: 73px;
    width: 100%;
}
#menu-talentPoolId{
  .MuiPaper-root{
    max-height: 200px !important;
  }
}
.matching-list-view{
  .no-table-border{
    border:none !important;
    box-shadow:none !important;
  }
}
.matching-list-view{
  .matching-list-align{
    overflow-y: hidden;
  }
}
#menu-{
  .MuiPaper-root{
    .MuiList-root{
      .MuiMenuItem-root{
        &:hover {
          background-color: rgba(0, 0, 0, 0.04) !important;
        }
      }
    }
  }
}

#composition-menu{
  .MuiMenuItem-root{
    &:hover {
      background-color: var(--list-hover-color) !important;
    }
  }
}

.interviewmodal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 503px;
  background-color: $bg-white;
  padding: 5px;
  border-radius: 8px;

  &:focus-visible {
    outline: none;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .interviewmodal-content {
    width: 380px;
  }

  .interviewmodal-content {
    &.checklist-modal &.responsive-checklist-modal {
      width: 380px !important;
    }
  }
  .interviewmodal-content {
    &.checklist-modal {
      width: 900px !important;
    }
  }

}
.line {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 1px;
  border: 1.4px solid #EAE9F2;

}

.svg-icons > path {
  stroke: var(--primary-color);
}
.fill-svg-icons > path {
  fill: var(--primary-color);
}
.fill-svg-icon-smart-filter > path {
  fill: #228B22;
}
.rect-svg-icons > rect {
  fill: var(--primary-color);
}
.svg-icons-disabled > path {
  stroke: var(--gray7);
}
.interview-btn {
  float: right;
}
.photo-text{
  display: flex;
}
.content-all{
  height: 350px;
}
.photo-circle{
  width: 50px;
  height: 40px;
  border-radius: 100%;
  background-color:#C9EEFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.display-flex{
  display: flex;
}
.background-color{
  background-color: #EBF9FF;
}
.overflow-hidden{
  overflow: hidden;
}
.overflow-x-scroll{
  overflow-x: scroll;
}
.default-loader{
  svg{
    width: 100%;
    height: 280px;
  }
}
.modal-content-smartFilter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  background: #C5E0FD;
  padding: 15px;

  &:focus-visible {
    outline: none;
  }
}