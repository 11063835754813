/* Responsive */
// Mobile resolution
@media screen and (min-width: 300px) and (max-width: 600px) {
  .navbarMargin {
    margin: 0px !important;
    padding-left: 40px;
  }

  .dashboard-list-tab {
    padding-bottom: 20px !important;
    margin-top: 0px !important;
    padding-right: 0px !important;
    padding-left: 10px !important;

    span {
      font-size: 11px !important;
    }

    .navbarMargin {
      padding-right: 9px;
    }

  }

  .jobListFilter {
    /*display: block !important;*/
  }

  /*  .search-job{
      padding-right:10px;
      button{
        padding:0px !important;
      }
    }*/
  .search-job {
    padding-right: 10px;
  }

  .skillIcon {
    font-size: var(--font-size-12) !important;
    margin: 0px !important;
    display: block !important;

    svg {
      padding-top: 5px !important;
    }
  }

  .MuiDialog-root {
    .MuiDialog-scrollPaper {
      .MuiPaper-root {
        min-width: 315px !important;
      }
    }
  }

  .previewButton {
    font-size: var(--font-size-10) !important;
  }

  .jobProfile {
    img {
      height: 40px !important;
      width: 40px !important;
    }
  }

  .descriptionSpace {
    margin-top: 15px !important;
    padding: 0 10px 0 0;
  }

  .jobs-portal {
    padding: 0px 16px !important;
  }

  .matchingJobSpace {
    padding-top: 0px !important;
  }

  .matchingJob {
    .MuiTabs-root {
      .MuiTabs-flexContainer {
        width: max-content !important;
      }
    }
  }

  .modal-content,
  .modal-content1 {
    width: 340px !important;
  }

  .responsive-tab {
    padding-left: 28px !important;
  }

  .privacy-logo {
    padding-left: 20px !important;
  }

  .width-responsive-100 {
    width: 100%;
  }

  .resolution-view {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .sort-filter-center {
    justify-content: center !important;
  }

  .candidate-info-align {
    align-items: flex-start !important;
  }

  .matching-details-align {
    margin-top: 5px !important;
  }

  .search-byId {
    width: 50px;
    margin: 0 auto !important;
  }

  .icon-margin {
    margin-top: 3px !important;
  }

  .sort-responsive {
    .MuiSelect-select {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      margin-left: 10px;
    }
  }

  .responsive-main-section {
    margin-left: 150px !important;
  }

  .responsive-layout {
    padding-right: 0 !important;
  }

  .responsive-no-results-image {
    img {
      width: 150px !important;
    }
  }

  .menu-list {
    top: 81px !important;
  }

  .logoImg {
    // height: 35px;
    width: 120px !important;
    padding: 0 10px;
  }

  .logoImg-navbar {
    padding: 4px 0px 0px 5px !important;
  }

  .react-multi-carousel-list {
    .react-multi-carousel-dot-list {
      padding: 0 !important;
    }
  }

  .notification-body {
    li {
      white-space: break-spaces;
    }
  }

  .responsive-chat-section {
    max-height: 11em !important;
    margin-bottom: 20px !important;
  }

  .analytics-report {
    .MuiPaper-root {
      left: -20px !important;

      .MuiList-root {
        position: fixed;
        left: 55px;
        top: 81px;
        background: #ffffff;
        box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
        // padding: 25px !important;
        height: 100vh;
        width: 210px !important;
      }
    }
  }

  .mobile-justify-end {
    justify-content: end !important;
  }

  .responsive-flex {
    display: flex !important;
  }

  .responsive-flex-start {
    justify-content: flex-start !important;
  }

  .matching-breakdown-responsive {
    padding-left: 3em;
  }

  .responsive-icon {
    vertical-align: flex-start !important;

    img {
      // margin-top: 4px !important;
      width: 12px;
    }
  }

  .responsive-icon {
    &.responsive-sidebar-icon {
      padding-bottom: 25px !important;
    }
  }

  .responsive-copy-link {
    .MuiInputBase-input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 20px !important;
    }
  }

  .responsive-share-icon {
    margin-left: -25px !important;
  }

  .time-lineStepper .timeline-img {
    top: -8px;
  }

  .responsive-grid {
    display: grid !important;
  }

  .responsive-block {
    display: block !important;
  }

  .responsive-padding-div{
    padding: 0 8px !important;
  }
  .responsive-img-block {
    width: 250px;
    height: auto;
  }

  .responsive-direct-source {
    .direct-source {
      margin-top: 0 !important;
    }
  }

  .swiper-slide {
    width: 100% !important;
  }

  .responsive-contents {
    display: contents !important;
  }

  .radio-button-class {
    img {
      width: 200px !important;
    }
  }

  .matching-location-align {
    margin: 0px !important;
  }

  .MuiBadge-badge {
    background-color: var(--primary-color) !important;
    color: #ffffff !important;
  }

  .responsive-checklist {
    margin-left: 0 !important;
  }

  .preview-space {
    padding: 20px !important;
  }

  .joblist-header-section .jobListFilter {
    padding-left: 0 !important;
  }

  .userBg-space {
    padding-right: 15px !important;
  }

  .postJob-search {
    .MuiOutlinedInput-root {
      // margin-left:5px !important;
      // margin-right:25px !important;
    }
  }


  .profileImage {
    left: -10px !important;
  }
  .profileImage-crossSell {
    left: -10px !important;
  }

  .userName-align {
    padding-left: 6px !important;
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        max-height: 420px !important;
        overflow-y: auto;
      }
    }
  }

  .globalList-align {
    margin-right: 10px !important;
  }

  .sortByDropdown {
    .MuiOutlinedInput-root svg {
      top: auto !important;
      right: 2px !important;
    }
  }

  .rating-align {
    width: 100% !important;
    display: flex !important;
    margin-top: 10px !important;
  }

  .candidateList-align {
    margin-top: 25px !important;
  }

  .filter-jobs {
    top: 55px !important;
  }

  .caursoul-text {
    top: 160% !important;
    padding: 0px 10px !important;
  }

  .caursoul-button1 {
    bottom: -170px !important;
    left: 256px !important;
  }

  .caursoul-button2 {
    bottom: -180px !important;
    left: 256px !important;
  }

  .simple-section {
    .inner-simple-section {
      padding: 2em !important;
    }

    .card-section {
      padding: 0em !important;
    }
  }

  .carousel-responsive {
    .react-multi-carousel-list {
      .react-multi-carousel-track {
        height: 350px;
      }
    }
  }

  .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px) !important;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
  }

  .cand-rating-align {
    text-align: left !important;
    padding: 0px !important;
  }

  .category-rating {
    justify-content: left !important;
  }

  .job-title-align {
    width: 30% !important;
  }

  .job-summary-align {
    width: 100% !important;
  }

  .select-filter {
    .MuiPaper-root {
      &.MuiPopover-paper::before {
        left: 90px !important;
      }
    }
  }

  .type-filter {
    .MuiPaper-root {
      &.MuiPopover-paper::before {
        left: 173px !important;
      }
    }
  }

  .notes-modal-view {
    .notes-table-view {
      width: 375px !important;
    }
  }

  .profile-video-align {
    .video-image {
      width: 55px !important;
      height: 55px !important;
    }
  }
  .uploadjob-align{
    margin-top: 10px !important;
  }
  .sideBar-icon {
    margin-top: 30px !important;
  }

  // .resolution-view {
  //   padding-right: 20px !important;
  //   padding-left: 30px !important;
  // }

  .responsive-view-align {
    padding-left: 0px !important;
  }

  .joblist-align {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        max-height: 420px !important;
        overflow-y: auto;
        left: 158px !important;
      }
    }
  }

  .enterpriseSearch {
    .MuiFormControl-root {
      width: 240px !important;
    }
  }

  .loading-align {
    top: 100px !important;
    width: 75% !important;
  }

  .global-search-align {
    width: 75% !important;
  }

  .profile-tabs {
    .MuiTabs-flexContainer {
      width: max-content !important;
    }
  }

  .edit-profile-tabs {
    .MuiTabs-flexContainer {
      width: max-content !important;
    }
  }

  .talent-pool-status {
    margin-right: 0px !important;
    padding: 10px !important;
  }

  .talent-poolFilter {
    display: flex !important;
    justify-content: flex-start !important;
  }

  .upload-job-align {
    margin-left: 3px !important;
  }

  .scoring-input {
    width: auto !important;
  }

  .scoring-align {
    #modal-description {
      max-height: 300px !important;
    }
  }

  .scoring-detail-align {
    padding-left: 8px !important;
  }

  .score-aged-align {
    justify-content: space-between !important;
  }

  .talentPool-btn-align {
    margin: 0px auto !important;
  }

  .job-status-align {
    text-align: end !important;
  }

  .reqest-count {
    .request-expand {
      .MuiAccordionSummary-expandIconWrapper {
        right: 0px !important;
        top: 85px !important;

        &.Mui-expanded {
          top: 73px !important;
        }
      }
    }
  }

  .search-user-align {
    min-width: auto !important;
  }

  .scoring-modal {
    #modal-description {
      max-height: 145px !important;
    }
  }

  .scoring-input-align {
    // width: max-content !important;
    width: 40px !important;
  }

  .rating-progress {
    .MuiLinearProgress-determinate {
      border-radius: 25px !important;
    }
  }

  .sortby-align {
    justify-content: flex-start !important;
  }

  #modal-description1 {
    max-height: 350px !important;
  }

  .associate-status-align {
    margin-bottom: 10px !important;
  }

  .modal-content {
    &.checklist-modal {
      width: 340px !important;

      #modal-description {
        max-height: 350px !important;
      }
    }
  }

  .sticky-issue {
    top: 55px !important;
  }

  .add-checklist-align {
    margin-top: 20px !important;
  }

  .shortlist-options {
    transform: translate3d(-162px, -163.5px, 0px) !important;

    .MuiPaper-root {
      .MuiList-root {
        .MuiGrid-root {
          max-width: 260px !important;
        }
      }
    }
  }

  .signup-footer {
    padding-bottom: 30px !important;
  }

  .signup-align {
    margin-top: 110px !important;
  }

  .input-otp input {
    margin-right: 17px !important;
  }

  .sideIcon-align {
    display: flex !important;

    svg {
      margin-top: 6px !important;
    }
  }

  .notification-value {
    line-height: 20px !important;
  }

  .notification-align {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .title-align {
    min-width: 300px !important;
  }

  .ratingReview {
    margin-left: 20px !important;
  }

  .notedby-align {
    width: 125px !important;
  }

  .campaign-box-status-align {
    padding-left: 6px !important;
  }

  .cta-padding {
    padding-right: 50px !important;
    padding-bottom: 30px !important;
  }

  .matching-job-align {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .filter-responsive {
    width: fit-content !important;
  }

  .job-detail-drawer,
  .jobDetail-drawer {
    .MuiDrawer-paper {
      width: 95% !important;
    }
    .drawer-align{
      padding: 20px !important;
    }
  }

  .w-300 {
    width: 180px !important;
  }

  .joblist-header-section {
    .search-job {
      flex-direction: column;
    }
  }

  .shortlist-modal {
    width: 320px !important;
  }

  .responsivegrid {
    width: 100% !important;

    button {
      margin-left: 0 !important;
    }
  }

  .header-fixed-section {
    position: sticky;
    width: 100%;
    top: 0;
  }

  .stepper-left-panel {
    width: 100%;
    position: static;
    overflow: hidden;
    left: 0;
    right: 0;
  }

  .stepper-right-panel {
    width: 100%;
    margin-left: 0 !important;
    overflow-y: auto;
    position: static;
  }

  .panel-section {
    .MuiBox-root {
      padding: 2px;
    }
  }

  .panel-section-skills {
    .MuiBox-root {
      padding: 2px;
    }
  }

  .skills-panel-section {
    min-height: 85vh;
    max-height: 65vh;
    overflow-y: auto;
  }

  .direct-source-responsive {
    padding-bottom: 5em !important;
  }
  .privacy-logo-align{
      padding-left: 25px !important;
  }
  .interview-link-align{
    width: 190px !important;
  }
  .date-time-align{
    max-width: fit-content !important;
  }
  .interview-link{
    max-width: 260px !important;
  }
  .added-pool{
    margin-right: 0px !important;
  }
  .campaign-title{
    width: 180px !important;
  }
  .break-down-align{
    
  }
}

@media screen and (min-width: 200px) and (max-width: 600px) {
  .chat-container {
    display: none !important;
  }
  .jobWizard-arrowdown {
    top: 94% !important;
  }
  .jobWizardSearchBox{
    bottom: auto !important;
  }
  .login-width {
    width: 90% !important;
    margin: 0px auto !important;
  }
  .policy-responsive {
    width: 100% !important;
  }
  .login-responsive {
    align-items: flex-start !important;
  }
  .align-item-mob{
    align-items: center !important;
  }
  .resolution-issues .enterprise-profile img {
    width: 40px !important;
    height: 40px !important;
  }
  .neuro_switch{
    display: flex !important;
    margin-left: auto;
    margin-top: 10px;
  }
  .responsive-settings {
    padding: 0 !important;
  }
  .settings-sidebar-fix {
    position: absolute;
    z-index: 0;
    width: 100% !important;
    .settings-sidebar {
      padding: 0 !important;
      // margin-right: 30px;
    }
  }
  .settings-body {
    margin-left: 0 !important;
    padding-top: 34em;
    border-left: 0;
    width: 100% !important;
    padding-right: 0;
  }
  .job_status{
    display: block;
    padding-top: 10px !important;
  }
  .appliedStatus{
    margin-left: 0px !important;
  }
  .jobs_status{
    display: block;
    margin-bottom: 10px;
  }
  .edu-tile{
    .edu-align{
      margin-top: 0px !important;
    }
  }
  .res-priv-logo{
    width: 170px !important;
  }
  .neuro-priv-logo{
    padding-left: 30px !important;
  }
  .play-icon-mobile{
    left: 80px !important;
  }
  .video-descrip-align{
    left: 0px !important;
  }
  .bg-color-res-none {
    border-radius: 8px !important;
    padding: 20px !important;

    button {
      width: 100% !important;
      padding: 5px !important;
    }
  }
  .file-upload {
    display: block !important;
  }
  .create-video-justify{
    justify-content: center !important;
  }
  .job-detail-align {
    margin-top: 20px !important;
  }
  .offer-drawer{
    padding: 20px !important;
  }
  .response-wrap{
    text-wrap: wrap !important;
  }
  .share-job-align {
    .MuiFormControl-root {
      .MuiInputBase-root {
        .MuiInputBase-input {

        }
      }
    }
  }
  .stepper-left-panel{
      max-height: 605px !important;
  }
  .notification-body{
    max-height: 350px;
  }
  .justify-end-flex{
    display: flex;
    justify-content: flex-end;
  }
  .modal-description-wizard-detail{
    height: 410px;
  }
  .job-option-align{
    margin-top: 10px !important;
  }
  .referee-align{
    padding-left: 0px !important;
  }
  .refree-page-align{
    margin: 0px !important;
  }
  .setting-timing-align{
    justify-content: flex-start !important;
    padding-left: 20px !important;
  }
  .cand-list-align{
    display: flex !important;
    justify-content: center !important;
  }
  .logo-responsive{
    width: 150px !important;
    height: auto !important;
  }
  .weightage-align{
    padding-left: 5px !important;
  }
  .draft-align{
    margin-right: auto !important;
  }
  .modalStyle{
    width: 360px !important;
    padding: 20px !important;
  }
  .wizard-job-align{
    padding-right: 2px !important;
    margin-right: 0px !important;
    margin-left: 20px !important;
  }
  .exp-skill-align{
    margin-left: 8px !important;
  }
  .exp-align-skill{
    margin-right: 0px !important;
  }
  .screening-del-align{
    margin-top: 0px !important;
  }
  .share-jobAlign{
    width: 70%;
  }
  .campaign-align{
    margin-right: 20px !important;
  }
  .campaign-score-align{
    padding-right: 10px !important;
  }
  .Toastify__toast-container{
    padding-right:20px !important;
    padding-left: 20px !important;
  }
  .policy-align{
    margin-top: 75px !important;
  }
  .policy-res-align{
    padding-left: 10px !important;
  }
  .navBar ul{
    padding-left: 12px !important;
    li{
      padding-right: 14px !important;
      a{
        font-size: 12px !important;
      }
    }
  }
  .privacy-tabs{
    padding-left: 20px !important;
  }
  .table-position{
    position: static !important;
  }
  .location-card{
    width: 130px !important;
  }
  .candidateProfileDocument {
    p {
      width: 150px !important;
    }
  }
  .cross-list-align{
    margin-left: 0px !important;
  }
  .cross-title{
    min-width: 100% !important;
  }
  .talent-position{
    position: static !important;
  }
  .console-align{
    padding-bottom: 0px !important;
  }
  .console-name{
    padding-bottom: 20px !important;
  }
  .edit-none{
    display: none !important;
  }
  .edit-rating{
    &.edit-height{
      height:auto !important;
    }
  }
  .edit-align{
    width: auto !important;
  }
  .referee-align{
    &.candidateSpace{
      padding-left: 0px !important;
    }
  }
  .modal-content-smartFilter {
    width: 410px !important;
  }
}

// iPhone 12 Pro responsive view
@media screen and (min-width: 390px) and (max-width: 400px) {
  .jobWizard-arrowdown {
    top: 77% !important;
  }
  .signup-align {
    margin-top: 0px !important;
  }
  .panel-modal-align{
    width: 160px !important;
  }
  .occupied-align{
    width: max-content !important;
  }
  .checklist-modal-view{
    .modal-content{
      top: 42% !important;
    }
  }
  .question-type-align{
    display: block !important;
  }
  .policy-res-align{
    padding-left: 25px !important;
  }
  .navBar ul{
    padding-left: 28px !important;
    li{
      padding-right: 25px !important;
      a{
        font-size: 14px !important;
      }
    }
  }
  .job-location{
    .location-card{
      width: 140px !important;
    }
  }
}

// small device to large device resolution
@media screen and (min-width: 300px) and (max-width: 1024px) {
  .input-otp {
    input {
      margin-right: 13px !important;
    }
  }

  .candidateTopmargin {
    margin: 0 !important;
  }

  .w-300 {
    width: 200px !important;
  }

  .w-500 {
    width: 200px !important;
  }

  .navBar {
    overflow: hidden;
    padding-bottom: 6px;
  }

  .navBar ul li .active {
    padding-bottom: 6px !important;
  }

  .policyInfo {
    margin-left: 0px !important;
    padding: 0px !important;
    max-width: 480px !important;
  }

  .sub-info,
  .web-image {
    display: none;
  }

  .logo,
  .home-page button,
  .img-width {
    width: 50% !important;
  }

  .infoFont {
    font-size: var(--font-size-14) !important;
  }

  .launchTitle {
    font-size: var(--font-size-16) !important;
  }

  .titleFont {
    // font-size: 16px !important;
  }

  .aboutEnterprise {
    padding-left: 0px !important;
  }

  .degreeName {
    // padding-left: 15px !important;
    margin-left: 0px !important;
  }

  .lunaImage {
    text-align: center !important;
  }

  .enterpriseLaunch {
    margin-top: 0px !important;
  }

  .policyEnterprise {
    margin-top: 55px !important;
  }

  .column-reverse {
    flex-flow: column-reverse !important;
  }

  .profile-img {
    width: 100px !important;
    height: 100px !important;
    object-fit: cover;
    margin-top: 20px !important;
  }

  .input-otp input {
    // margin-right: 13px !important;
  }

  .column-reverse {
    flex-flow: column-reverse !important;
  }

  .footerLearn {
    padding-left: 0px !important;
  }

  .registrationBtn {
    padding-bottom: 46px !important;
  }

  /* .registrationTitle{
     padding-top:120px !important;
   }*/
  .loginSocial {
    padding-bottom: 80px;
  }

  .neuro-diversity-background{
    background-image: none;
    .neurodiversity-outlet{
      width: 100% !important;
    }
  }
  // .left-side-section-image {
  //   display: none !important;
  // }

  .joblist-header-section {
    display: block;

    .jobListFilter {
      padding-left: 47px;

      .titleFont {
        padding: 10px 15px;
      }
    }

    .search-job {
      // flex-direction: column;

      .post-button {
        padding-top: 10px;

        button {
          // width: 280px !important;
        }
      }
    }
  }

  .name-email {
    .textEllipse {
      width: 175px !important;
    }
  }

  .profileImage {
    // left: 15px !important;
    width: 120px !important;
    height: 120px !important;
  }

  .profileImage-crossSell {
    // left: 15px !important;
    width: 120px !important;
    height: 120px !important;
  }

  .candidateSpace {
    padding-left: 40px !important;
  }

  .degreeName {
    padding-left: 90px !important;
  }

  .responsive-margin-left-0 {
    margin-left: 0 !important;
  }

  .stepper-right-panel {
    .panel-section {
      padding-bottom: 0 !important;
    }
  }

  .updateProfile {
    top: 123px !important;
    left: -8px !important;
    height: 45px !important;
    width: 101% !important;
  }

  .updateImage {
    top: -37px !important;
    left: 18px !important;
  }

  .enterprise-access-section {
    .profile-section {
      // height: 90px !important;

      .enterprise-section {
        margin-left: 110px;
      }
    }
  }

  .joblist-position {
    position: initial !important;
  }

  .responsive-singin-section {
    &.policyCandidate {
      margin-top: 0 !important;
    }
  }

  .budget {
    .input-group {
      &.select-option-section {
        margin-top: 0px !important;
        height: 47px !important;
      }
    }
  }

  .payrate-align {
    padding-top: 5px !important;
  }

  .matchingJob {
    .MuiTabs-root {
      .MuiTabs-flexContainer {
        min-width: max-content !important;
      }
    }
  }

  .filter-align {
    display: block !important;
  }

  .filter-right-align {
    margin: 0px !important;
  }

  .launch-align {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .launch-videoImage {
    width: 100% !important;
  }
}

@media screen and (min-width: 425px) and (max-width: 516px) {
  .navBar ul li .active {
    padding-bottom: 18px !important;
  }
}

/* Launch page */
@media screen and (min-width: 1032px) and (max-width: 1440px) {
  .width-responsive {
    width: 640px !important;
  }

  .fit-body-expanded {
    width: 82%;
    right: 0px;
    position: absolute;
  }

  .fit-body-collapsed {
    width: 95.7%;
    right: 0px;
    position: absolute;
  }

  .fit-body-collapsed {
    .responsive-create-project {
      width: 98%;
    }
  }
}

@media screen and (min-width: 688px) and (max-width: 1024px) {
  .navBar ul li .active {
    padding-bottom: 18px !important;
  }

  .navBar ul a {
    font-size: var(--font-size-20) !important;
  }

  .logo {
    width: 22% !important;
  }

  // .logoHeader {
  //   padding-left: 22px !important;
  // }

  .enterprise-access-section {
    .profile-section {
      .profile-main-section {
        top: 176px;
      }
    }
  }

  .degreeName {
    padding-left: 120px !important;
  }

  .score-align {
    display: block !important;
  }

  .status-align {
    width: auto !important;
  }

  .caursoul-text {
    width: 82% !important;
    top: 23% !important;
    left: 57% !important;
    padding: 10px !important;
  }

  .caursoul-button1 {
    bottom: 245px !important;
    left: 25px !important;
  }

  .caursoul-button2 {
    bottom: 245px !important;
    left: 25px !important;
  }

  .launch-page-heading-color {
    font-size: var(--font-size-20) !important;
  }
}

@media screen and (min-width: 820px) and (max-width: 2560px) {
  .align-center-big-screen {
    align-items: center !important;
    justify-content: center !important;
    vertical-align: middle !important;
    display: flex !important;
  }
}

@media screen and (min-width: 1860px) and (max-width: 2560px) {
  .MuiContainer-root {
    max-width: 2400px !important;
  }

  .fit-body-expanded {
    width: 80%;
    right: 25px;
    position: absolute;
  }

  .fit-body-collapsed {
    width: 95%;
    right: 25px;
    position: absolute;
  }

  .fit-body-collapsed {
    .responsive-create-project {
      width: 98%;
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .degreeName {
    padding-left: 120px !important;
  }

  .caursoul-text {
    width: 80% !important;
    top: 24% !important;
    left: 59% !important;
    padding: 10px !important;
  }

  .caursoul-button1 {
    bottom: 185px !important;
    left: 18px !important;
  }

  .caursoul-button2 {
    bottom: 185px !important;
    left: 18px !important;
  }

  .launch-page-heading-color {
    font-size: var(--font-size-20) !important;
  }

  .signup-zoom {
    margin-top: 130px !important;
    margin-bottom: 40px !important;
  }

  .message-height {
    height: auto !important;
    margin-bottom: 20px !important;
  }

  .responsive-chat-section {
    max-height: 12em !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .responsive-share {
    padding: 10px;
    width: 100%;
  }
  .responsive-qr {
    margin-left: auto;
    margin-right: auto; 
  }
  .left-side-section-image {
    display: none !important;
  }
  .neuro-diversity-background{
    background-image: none;
    .neurodiversity-outlet{
      width: 100% !important;
    }
  }
  .align-center-big-screen {
    .neuro-diversity-section {
      display: none !important;
    }
    .neuro-right {
      right: 0 !important;
    }
  }


  .responsive-tabs {
    display: block !important;
  }

  .video-image {
    width: 400px !important;
    height: 250px !important;
  }

  .jobSearch {
    display: block !important;
  }

  .logoWord {
    width: 111px !important;
  }

  .logoHeader {
    padding-left: 40px !important;
  }

  .navbarUser-logout {
    padding-right: 30px !important;
  }

  .job-detail-matching {
    margin: 0px !important;
  }

  .matching-candidate {
    .MuiBox-root {
      .MuiBox-root {
        .MuiTabs-root {
          .MuiTabs-scroller {
            .MuiTabs-flexContainer {
              .MuiButtonBase-root {
                padding-right: 15px !important;
              }
            }
          }

          .Mui-disabled {
            display: none !important;
          }

          .MuiTabs-scrollButtonsHideMobile {
            display: none !important;
          }
        }
      }
    }
  }

  .view-response {
    justify-content: flex-start !important;
    padding-left: 15px !important;
  }

  .jobHeader-align {
    margin-top: 10px !important;
  }

  // .rating-progress{
  //   width:70% !important;
  // }
  .menu-list {
    display: none !important;
  }

  .navbar-fixed {
    padding: 0 !important;
  }

  .navbar-fixed-loggedout {
    padding: 0 !important;
  }

  .candidate-list-align {
    margin-right: 0px !important;
  }

  .candidate-lead-align {
    .MuiSelect-select {
      width: 150px !important;
    }
  }

  .enterprise-access-section {
    .profile-section {
      height: auto !important;
      display: block !important;

      .enterprise-section {
        // margin-left: 110px;
        margin-top: 65px !important;
      }
    }

    .adduserbox {
      padding-top: 13px !important;
      justify-content: center !important;
    }
  }

  .myJobs-status {
    margin-top: 50px !important;
  }

  .upload-status {
    padding-left: 0px !important;
  }

  .checklist-add {
    padding-top: 75px !important;
  }

  .lead-view {
    margin-top: 65px !important;
  }

  .create-pool {
    padding-top: 0px !important;
  }

  .signin-title {
    padding-top: 20px !important;
  }

  .default-manager-img {
    width: 30px !important;
    height: 30px !important;
    padding: 10px !important;
  }

  p {
    &.launch-page-title2 {
      font-size: 40px !important;
    }
  }

  .responsive-bottom-section {
    &.direct-source-responsive {
      padding-bottom: 7em !important;
    }
  }

  .occupied-status {
    font-size: var(--font-size-10);
    padding-right: 4px !important;
  }

  .available-status {
    font-size: var(--font-size-10);
  }

  .maybe-available-status {
    font-size: var(--font-size-10);
  }

  .matching-details-align {
    &.sort-filter-center {
      justify-content: center !important;
    }
  }

  .checklist-content {
    min-width: 0 !important;
  }
}

.default-logo-image-width {
  width: 180px;
}

/* Responsive */
.navbar-responsive {
  height: 107vh !important;
  display: initial !important;

  .MuiBox-root {
    padding-left: 0;
  }
}

.navbar-responsive .MuiPaper-root {
  top: 0 !important;
  right: 0px;
  padding: 25px;
  background: #fff !important;
  box-shadow: none !important;
  height: 100vh;
  border-radius: 0 !important;
  left: 32px !important;
}

/*@media screen and (min-width: 1441px) and (max-width: 2560px) {
.responsive-footer{
  margin: 0 -325px !important;
}
}*/

.profilePhotoContainer {
  top: 175px !important;
}

@media screen and (min-width: 300px) and (max-width: 1180px) {
  .profilePhoto {
    top: 12px !important;
  }

  .profileBgImage {
    .bg-image {
      height: 130px !important;
    }
  }

  .inputWidth {
    padding-right: 30px !important;
  }

  .icon-padding {
    padding-left: 0px !important;
  }

  .toggle-icon {
    padding-left: 0px !important;
  }

  .notification-drawer .MuiDrawer-paper {
    width: 80% !important;
  }
}

@media screen and (min-width: 677px) and (max-width: 1024px) {
  .uploadResume {
    .file-extension-image {
      width: 7% !important;
    }
  }

  .profilePhotoContainer {
    left: 25px !important;
  }

  .icon-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .toggle-icon {
    padding-left: 10px !important;
    padding-right: 0px !important;
  }

  .header-fixed-section {
    position: sticky;
    width: 100%;
    top: 0;
  }

  .stepper-left-panel {
    width: 100%;
    position: static;
    overflow: hidden;
    left: 0;
    right: 0;
  }

  .stepper-right-panel {
    width: 100%;
    margin-left: 0 !important;
    overflow-y: auto;
    position: static;
  }
}

@media screen and (min-width: 425px) and (max-width: 516px) {
  .profilePhotoContainer {
    left: 20px !important;
  }
}

@media screen and (min-width: 1860px) and (max-width: 2560px) {
  .profilePhotoContainer {
    left: 450px !important;
  }

  // .logoHeader {
  //   padding-left: 55px !important;
  // }
}

@media screen and (min-width: 1032px) and (max-width: 1440px) {
  .profilePhotoContainer {
    left: 245px !important;
  }

  h5.logo-responsive {
    // margin-top: 110px !important;
  }
}

.profileImage {
  width: 108px !important;
  height: 108px !important;
  object-fit: cover;
}

.profileImage-crossSell {
  width: 95px !important;
  height: 95px !important;
  object-fit: cover;
}

.updateProfile {
  top: 140px;
  left: -0.4px;
  height: 45px;
  width: 91%;
}

.uploadFile {
  display: block !important;
}

.profileSpacing {
  height: 175px !important;
}

#CircularProgressbarWithChildren__children {
  margin-top: 30%;
}

@media screen and (min-width: 1300px) and (max-width: 1440px) {
  .joblist-position {
    height: 945px !important;
  }

  //   .menu-list{
  //     .MuiPaper-root {
  //     width: 258px !important;
  //   }
  // }
}

@media screen and (min-width: 2033px) and (max-width: 2560px) {
  .joblist-position {
    height: 58vh !important;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {

  .place-contr {
    filter: invert(0);
    border: 1px solid white !important;
  }

  .navbar-fixed {
    filter: invert(0);
    border: 1px solid white !important;
  }

  .navbar-fixed-loggedout {
    filter: invert(0);
    border: 1px solid white !important;
  }

  .contrasts {
    filter: invert(0);
  }

  .Nav-cont {
    filter: invert(1);
  }

  .menu-list {
    top: 86px !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;

    .MuiPaper-root {
      top: 86px !important;
    }
  }

  .experience-cont {
    filter: invert(30%) !important;
  }

  .inputcontrasts {
    border: 1px solid white !important;
  }

  .MuiTabs-indicator {
    filter: invert(1);
  }

  .enterpriseSearch {
    border: 1px solid white !important;
  }

  .rdw-option-wrapper img {
    filter: invert(1);
  }

  .rdw-editor-main {
    filter: invert(30%);
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {


  .contrasts {
    filter: invert(1);
  }

  .navbar-fixed {
    filter: invert(1);
    border: 1px solid black !important;
  }

  .navbar-fixed-loggedout {
    filter: invert(1);
    border: 1px solid black !important;
  }

  .enterpriseSearch {
    border: 1px solid gray !important;
  }

  .inputcontrasts {
    border: black 1px solid !important;
  }
}

// 200% - 400% zooming issue fixes //
@media screen and (min-width: 200px) and (max-width: 640px) {
  .modal-content {
    // width: 335px !important;
    padding: 10px !important;

    form {
      // margin-top:1px !important;
    }
  }

  #modal-title {
    font-size: var(--font-size-16) !important;
  }

  #modal-description {
    // max-height: 95px !important;
    // margin:0px !important;
  }

  #modal-footer {
    margin-top: 5px !important;
  }

  ::placeholder {
    // font-size:10px !important;
  }

  .mt-10 {
    // margin-top:3px !important;
  }

  .mt-15 {
    // margin-top:5px !important;
  }

  .small-text-font,
  .f-14,
  .sub-text-font,
  .f-15 {
    // font-size:10px !important;
  }

  .f-20,
  .f-16,
  .f-24 {
    // font-size:14px !important;
  }

  .pb-10 {
    // padding-bottom: 2px !important;
  }

  .pt-10 {
    // padding-top: 2px !important;
  }

  .btn {

    &.primary-button,
    &.profile-button {
      // padding: 0px 25px !important;
      // font-size: 12px !important;
    }
  }

  .btn {

    &.primary-button,
    &.profile-button,
    &.send-disabled {
      // padding: 5px 25px !important;
      // font-size: 12px !important;
    }
  }

  .settings-align {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .preview-job-align {
    // padding-left: 300px !important;
  }

  .create-preview-align {
    padding: 0px !important;
    margin: 0px !important;
  }

  .screening-view {
    display: block !important;
  }

  .access-align {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .cityName input {
    padding-top: 0px !important;
    padding-bottom: 4px !important;
  }

  .access-input {
    .MuiOutlinedInput-input {
      &.MuiInputBase-input {
        padding-top: 0px !important;
        padding-bottom: 4px !important;
      }
    }
  }

  .matching-candidate {
    .MuiTabs-root {
      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            // font-size:8px !important;
            min-width: 60px !important;
          }
        }
      }
    }
  }

  .matching-align {
    padding-right: 2px !important;
  }

  .password-icon {
    button {
      top: 2px !important;
    }
  }
}

// 200% resolution
@media screen and (min-width: 540px) and (max-width: 640px) {
  .project-preview-align {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }

  .preview-side-align {
    margin-left: 240px !important;
  }

  .screen-left-align {
    margin-left: 0px !important;
  }

  .screening-align {
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 640px) {
  .policyCandidate {
    margin-top: 330px !important;
  }

  .resolution-issues {
    .logoImg-header {
      height: 25px !important;
      padding: 0px !important;
    }

    .enterpriseSearch .MuiFormControl-root {
      width: 150px !important;
    }

    .headerIcon {
      margin-left: 10px !important;
    }

    .enterprise-profile img {
      width: 40px !important;
      height: 40px !important;
    }

    .enterprise-name-width {
      width: 50px !important;
      min-width: 65px !important;
      font-size: 9px !important;
    }

    .headerUser {
      justify-content: flex-start !important;
    }

    .enterpriseSearch {
      .MuiFormControl-root {
        .MuiOutlinedInput-root {
          height: 27px !important;

          .MuiOutlinedInput-input {
            height: 0px !important;
          }
        }
      }
    }

    .menu-list {
      top: 62px !important;

      .MuiPaper-root {
        top: 62px !important;
      }

      .menu-sideIcon {
        top: 75px !important;
      }

      ul {
        margin-top: 0px !important;

        li {
          margin-top: 0px !important;
          padding: 5px !important;
          line-height: 14px !important;

          svg {
            width: 8px !important;
            height: 8px !important;
          }
        }
      }

      a {
        label {
          font-size: 8px !important;
          margin-top: 0px !important;
        }
      }
    }

    .collapse-navbar {
      .icon-margin {
        margin-top: 15px !important;
      }

      .myJobIcon {
        margin-top: 40px !important;
      }
    }
  }

  .enterpriseSearch {
    ::placeholder {
      font-size: 7px !important;
    }
  }

  .modal-content {
    // width:400px !important;
  }

  .signin-zoom {
    // margin-top:130px !important;
  }

  .signup-zoom {
    // margin-top:370px !important;
  }

  .zoom-margin {
    // margin-bottom:60px !important;
  }

  .newPassword-zoom {
    // margin-top:200px !important;
  }

  .message-text {
    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .message-height {
    height: auto !important;
    margin-bottom: 20px !important;
  }

  .messagelist-resolution {
    width: auto !important;
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        max-height: 420px !important;
        overflow-y: auto;
        top: 62px !important;
        left: 160px !important;
      }
    }
  }

  .navbarUser {
    justify-content: flex-start !important;
  }

  .navbarUser-logout {
    justify-content: flex-start !important;
  }

  // .enterprise-user-name {
  //   width: 50px !important;
  //   min-width: 65px !important;
  //   font-size: 9px !important;
  // }
  .nav-user-img {
    margin-left: 0px !important;
  }

  .message-nav-icon {
    margin-top: 45px !important;
  }

  .userDetails {
    display: block !important;
  }



  .navigation-zooming {
    margin-left: 30px !important;
  }

  .matching-list {
    margin-top: 10px !important;
  }

  // .matchingJob{
  //   .MuiTabs-root{
  //     .MuiTabs-flexContainer{
  //       overflow: auto !important;
  //       width:auto !important;
  //       border-bottom: none !important;
  //     }
  //   }
  // }
  .matching-shortlist {
    margin-left: auto !important;
  }

  .payrate-zooming {
    justify-content: right !important;
  }

  .applied-align {
    justify-content: center !important;
  }

  .matchingJob .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator {
    display: none !important;
  }

  .otp-zoom-align {
    padding-bottom: 40px !important;
  }
}

@media screen and (min-width: 140px) and (max-width: 512px) {
  .menu-list {
    top: 55px !important;
    overflow-y: auto !important;
    max-height: 100vh !important;
  }

  .myJobIcon {
    margin-top: 15px !important;
  }

  .sideBar-icon {
    // margin-top: 3px !important;
  }

  .modal-content {
    // width:400px !important;
  }

  .signin-zoom {
    // margin-top:220px !important;
  }

  .signup-zoom {
    // margin-top:470px !important;
  }

  .opt-zooming {
    // margin-top:60px !important;
  }

  .password-zoom {
    // margin-top:90px !important;
  }

  .newPassword-zoom {
    // margin-top:270px !important;
  }

  .message-text {
    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .zoom-font {
    font-size: 15px !important;
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        // max-height: 150px !important;
        overflow-y: auto;
        top: 122px !important;
        left: 81px !important;
        width: 240px !important;

        .MuiButtonBase-root {
          padding-bottom: 0px !important;
          padding-top: 0px !important;
          min-height: 43px !important;
        }
      }
    }
  }

  .payrate-responsive {
    width: 50px !important;
  }

  .nav-zooming {
    margin-left: 37px !important;
  }


  // .matchingJob{
  //   .MuiTabs-root{
  //     .MuiTabs-flexContainer{
  //       overflow: auto !important;
  //       overflow-x: auto;
  //       overflow-y: hidden;
  //       border-bottom: none !important;
  //     }
  //   }
  // }
  .matching-shortlist {
    margin-left: auto !important;
  }

  .payrate-zooming {
    justify-content: right !important;
  }

  .sortByDropdown {
    .MuiOutlinedInput-root svg {
      // top: 3px !important;
      right: 2px !important;
    }
  }

  .remark-align {
    width: auto !important;
  }

  .matchingJob .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator {
    display: none !important;
  }

  .zooming-sidebar {
    margin-left: 245px !important;
  }

  .responsive-outlet {
    // margin-left: 2.5em !important;
  }

  .responsive-create-project {
    padding-right: 0 !important;
  }

  .launch-page-heading-color {
    font-size: var(--font-size-20) !important;
    font-weight: 800 !important;
  }

  .otp-code-align {
    padding-bottom: 30px !important;
  }
}

@media screen and (max-height: 200px) {
  .candidateTopmargin {
    .zoom-password {
      margin-top: 180px !important;
    }
  }

  .footer-view-align {
    margin-bottom: 0px !important;
  }

  .signin-zoom {
    margin-top: 300px !important;
  }

  .policyCandidate {
    margin-top: 420px !important;
  }

  .logoImg {
    // width: 120px !important;
  }

  .enterprise-profile {
    img {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .menu-list {
    overflow-y: auto !important;
    max-height: 18em !important;
    top: 56px !important;
    height: 74vh;
  }

  .zooming-issue {
    margin-top: 5px !important;
  }

  .navbar-fixed {
    padding: 0px !important;
  }

  .navbar-fixed-loggedout {
    padding: 0px !important;
  }

  .zoom-font {
    font-size: 15px !important;
  }

  .registrationBtn {
    margin-bottom: 10px !important;
  }

  .postJob-search {
    min-width: 200px !important;
  }

  .postJob-btn {
    width: 200px !important;
  }

  .pagenation-table {
    min-width: 100% !important;
  }

  .adding-skills {
    display: block !important;
  }

  .skill-labl {
    margin-left: 0px !important;
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        max-height: 120px !important;
        overflow-y: auto;
        top: 40px !important;
      }
    }
  }

  .payrate-responsive {
    width: 50px !important;
  }

  .matchingJob {
    .MuiTabs-root {
      .MuiTabs-flexContainer {
        overflow: auto !important;
        width: auto !important;
        border-bottom: none !important;
      }
    }
  }

  .matching-shortlist {
    margin-left: auto !important;
  }

  .payrate-zooming {
    justify-content: right !important;
  }

  .matchingJob .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator {
    display: none !important;
  }

  .address-align {
    width: auto !important;
  }

  .zooming-sidebar {
    margin-left: 300px !important;
  }

  .joblist-header-section .jobListFilter {
    padding-left: 0;
  }

  .signup-zoom-align {
    margin-top: 220px !important;
  }

  .opt-zooming {
    margin-top: 150px !important;
  }

  .otp-code-align {
    padding-bottom: 50px !important;
  }

  .modal-content,
  .modal-content1 {
    width: 300px !important;
  }

  #modal-description {
    max-height: 50px !important;
    margin: 0px !important;
  }
  #modal-description-wizard {
    max-height: 50px !important;
    margin: 0px !important;
  }

  .f-15 {
    font-size: 7px !important;
  }

  .modalStyle {
    width: 270px !important;
    max-height: 130px !important;
    overflow-y: auto !important;
    padding: 10px !important;
  }

  .matching-candidate {
    .MuiTabs-root {
      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            font-size: 5px !important;
            min-width: 0px !important;
            padding-right: 2px !important;
          }
        }
      }
    }
  }

  .campaign-list-align {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 420px) {
  .modal-content {
    // width:290px !important;
  }

  #modal-title {
    // margin-top: 10px !important;
  }

  .signin-zoom {
    // margin-top:290px !important;
  }

  .zoom-margin {
    // margin-bottom:80px !important;
  }

  .signup-zoom {
    // margin-top:300px !important;
  }

  .password-zoom {
    // margin-top: 200px !important;
  }

  .newPassword-zoom {
    // margin-top:320px !important;
  }

  .message-text {
    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .jobDetail-user {
    display: block !important;
  }

  .jobDetail-status {
    justify-content: center !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .applied-status-align {
    padding-bottom: 40px !important;
  }

  .rating-details {
    padding: 0px !important;
  }

  .descrip-status {
    margin-top: 10px !important;
  }

  .descrip-offer {
    margin-left: 0px !important;
  }

  .history-align {
    // width:auto !important;
  }

  .ratings-align {
    padding-left: 15px !important;
  }
}

@media screen and (min-width: 412px) and (max-width: 915px) {
  .policyCandidate {
    margin-top: 80px !important;
  }

  .hide-icon {
    // transform: scale(0.5);
    display: none;
  }
}

@media screen and (min-width: 200px) and (max-width: 380px) {
  .responsiveBlock {
    display: block !important;
  }

  .categories-align {
    justify-content: flex-start !important;
  }

  .view-checklist-align {
    margin-top: 40px !important;
  }

  .user-logo-align {
    display: none !important;
  }
}

// Mobile small view
@media screen and (min-width: 200px) and (max-width: 320px) {
  .caursoul-text {
    top: 180% !important;
    padding: 0px 10px !important;
  }

  .caursoul-button1 {
    bottom: -210px !important;
    left: 168px !important;
  }

  .caursoul-button2 {
    bottom: -210px !important;
    left: 168px !important;
  }

  .input-otp input {
    margin-right: 6px !important;
  }

  .my-job-view {
    padding-right: 0px !important;
  }

  .description-status {
    display: block !important;
  }

  .score-matching {
    justify-content: end !important;
  }
  .galaxy-upload-resume {
    width: auto !important;
    margin-right: 4px !important;
  }
}

// Tablet view
@media screen and (min-width: 688px) and (max-width: 768px) {
  .sidebar-dropdown {
    .MuiPaper-root.MuiPopover-paper {
      min-height: 100vh !important;
      width: 500px !important;
      left: auto !important;
    }
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        // max-height: 420px !important;
        overflow-y: auto;
        left: 475px !important;
        top: 125px !important;
      }
    }
  }

  .message-listAlign {
    height: auto !important;
    padding-right: 0px !important;
  }

  .enterpriseSearch {
    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .side-bar-align {
    margin-left: 0px !important;
  }

  .user-logo-align {
    margin-left: 20px !important;
  }

  .responsive-tablet-view {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .my-jobs-align {
    padding-right: 10px !important;
    padding-left: 15px !important;
  }

  .notes-modal-view {
    .checklist-view-align {
      width: 750px !important;
    }
  }

  .access-list-align {
    justify-content: end !important;
  }

  .side-view {
    margin-top: 30px !important;
  }

  .pool-input {
    display: flex !important;
  }

  .invite-btn {
    margin-left: 10px !important;
  }

  .unbind-btn {
    margin-top: 10px !important;
  }

  .modal-content1 {
    width: 700px !important;
  }

  #modal-description1 {
    max-height: 330px !important;
  }

  .sticky-issue,
  .filter-jobs {
    top: 55px !important;
  }

  .matchingScore {
    padding: 0px !important;
    justify-content: flex-start !important;
  }

  .filter-global-align {
    margin-top: 0px !important;
  }

  .tablet-signin {
    height: auto !important;
  }

  .tablet-forgot {
    height: 100vh !important;
  }
  .tablet-payrate-align {
    justify-content: initial !important;
  }
  .table-yourRate {
    margin-left: 25px !important;
  }
  .talent-pool-name {
    width: 400px !important;
}
  .policy-view{
    min-width: 100% !important;
  }
  .tab-logo-align{
    img{
      width: 150px !important;
    }
  }
}

@media screen and (min-width: 859px) and (max-width: 1024px) {
  .fix-width-sign-up {
    width: 80% !important;
  }

  .fit-body-expanded {
    width: 82%;
    right: 0px;
    position: absolute;
  }

  .fit-body-collapsed {
    width: 94.6%;
    right: 0px;
    position: absolute;
  }

  .fit-body-collapsed {
    .responsive-create-project {
      width: 98%;
    }
  }
  .payrate-your-rate {
    display: block !important;
  }
  .yourRate-align {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
  .talent-pool-name{
    width: 68px !important;
  }
}

@media screen and (min-width: 766px) and (max-width: 885px) {
  .menu-list .MuiPaper-root {
    top: 64px !important;
  }

  .menu-list {
    top: 64px !important;

    .joblist-header-section {
      .search-job {
        .post-button {
          padding-top: 0px !important;
        }
        .post-button-job {
          padding-top: 0px !important;
        }
      }
    }
  }
  .job_status{
    display: block;
    padding-top: 10px !important;
  }
  .appliedStatus{
    margin-left: 0px !important;
  }
  .jobs_status{
    display: block;
    margin-bottom: 10px;
  }
}

// iPad Air view
@media screen and (min-width: 820px) and (max-width: 850px) {
  body {
    // padding-bottom: 8em !important;
  }

  .ipad-view-align {
    padding-bottom: 8em !important;
  }
  .talentPool-ipad{
    margin-top: 110px !important;
  }
  .job-detail-drawer {
    .MuiDrawer-paper {
      padding-bottom: 10em !important;
    }
  }

  .neuro-diversity-background{
    background-image: none;
    .neurodiversity-outlet{
      width: 100% !important;
    }
  }
  .left-side-section-image {
    display: none !important;
  }
  .align-center-big-screen {
    .neuro-diversity-section {
      display: none !important;
    }
    .neuro-right {
      right: 0 !important;
    }
  }
  .menu-list {
    display: none !important;
  }

  .ipad-view {
    width: 100% !important;
  }

  .jobs-ipad {
    margin-top: 20px !important;
  }

  .myjobs-ipad {
    padding-left: 0px !important;
  }

  .create-job {
    padding: 0px !important
  }

  .left-section {
    margin: 55px 0px 0px 0px !important;
  }

  .MuiTabScrollButton-horizontal {
    display: none !important;
  }

  .ipad-nav {
    padding: 20px;

    .enterprise-profile {
      img {
        width: 60px !important;
        height: 60px !important;
        margin: 0px !important;
      }
    }

    .chevron-down {
      width: 24px !important;
      margin-left: 15px;
    }

    .menu-icon {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .ipad-block {
    display: block !important;
  }

  .job-view-align {
    margin-top: 85px !important;
  }

  .skill-ipad {
    padding-right: 10px !important;
  }

  .scoring-ipad {
    margin: 0px !important;
  }

  .ipad-width {
    width: auto !important;
  }

  .years::after,
  .percent::after {
    padding-left: 10px !important;
  }
  .search-pool-align{
    justify-content: end;
  }
  .status-minw{
    min-width: 150px;
  }
  .action-minw{
    min-width: 220px;
  }
  .enterprise-access-section {
    .profile-section {
      .profile-main-section {
        top: 230px;
      }
    }
  }
  .settings-sidebar-fix{
    top: 8em !important;
  }
  .ipad-profile{
    .MuiPaper-root{
      min-width: 200px !important;
      ul li{
        margin-top: 10px !important;
      }
    }
  }
  .analytic-view-ipad{
    .MuiPaper-root{
      left: 170px !important;
      top: 120px !important;
    }
  }
  .ipad-search-width{
    width: 70% !important;
  }
  .header-search-bar{
    top: 95px;
    width: 70%;
  }
  .job_status{
    display: block;
    padding-top: 10px !important;
  }
  .appliedStatus, .interviewReferralStatus, .offBoardedReferralStatus {
    margin-left: 0px !important;
  }
  .jobs_status{
    display: block;
    margin-bottom: 10px;
  }
  .tab-logo-align{
    img{
      width: 150px !important;
    }
  }
  .checklist-modal-view{
    .modal-content{
      min-width: 770px!important;
    }
  }
  .ipad-share-align{
    padding: 20px !important;
  }
  .share-air-align{
    justify-content: center;
    display: flex;
  }
  .table-position{
    position: static !important;
  }
  .job-detail-drawer,
  .jobDetail-drawer {
    .MuiDrawer-paper {
      width: 80% !important;
    }
  }
  .rehire-ipad-align{
    padding-left: 10px !important;
  }
}
// iPad Mini
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .profilePhoto {
    top: 12px !important;
  }
  .enterprise-name-width {
    min-width: 30px !important;
    max-width: 55px;
  }
  .joblist-position {
    height: 570px !important;
  }
  .ipad-ml5{
    margin-left: 5px !important;
  }
  .ipad-miniblock{
    display: block !important;
  }
  .ipad-justifyend{
    justify-content: end !important;
  }
  .ipad-mt10{
    margin-top: 10px !important;
  }
  .modal-description-wizard-detail{
    min-height: 780px !important;
    max-height: 780px !important;
  }
  .ipad-max-budget{
    max-width: 40% !important;
  }
  .ipad-min-budget{
    max-width: 20% !important;
  }
  .ipad-skill{
    max-width: 45% !important;
  }
  .ipad-exp{
    max-width: 30% !important;
  }
  .ipad-delete{
    max-width: 5% !important;
  }
  .job-search-align{
    min-width: 220px !important;
  }
  .education-align{
    padding-left: 8px !important;
  }
  .stepper-left-panel{
    max-width: 35% !important;
    max-height: 745px !important;
  }
  .mini-break-space{
    white-space: break-spaces !important;
  }
  .mini-flex{
    display: flex !important;
  }
  .ipad-share-align{
    padding: 20px !important;
  }
  .referee-align{
    padding-left: 0px !important;
  }
  .table-position{
    position: static !important;
  }
  .job-detail-drawer,
  .jobDetail-drawer {
    .MuiDrawer-paper {
      width: 75% !important;
    }
  }
  .matching-search-align{
    width: 250px !important;
    min-width: 250px !important;
  }
  .rehire-ipad-align{
    padding-left: 10px !important;
  }
  .short-ipad-align{
    padding-right: 10px !important;
  }
}
// iPad Pro
@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .ipad-search{
    width: 44% !important;
  }
  .ipad-flexstart{
    justify-content: flex-start !important;
  }
  .menu-list .sidebar-list .ipad-text-start{
    text-align: start !important;
    align-items: flex-start !important;
  }
  .sidebar-list ul{
    margin-bottom: 30px !important;
  }
  .edu-del-align{
    justify-content: flex-end;
    display: flex;
    min-width: 100% !important;
    padding-right: 15px;
  }
  .checklist-modal-view{
    .modal-content{
      min-width: 970px!important;
    }
  }
  .ipad-share-align{
    padding: 20px !important;
  }
  .pro-refer-align{
    padding-left: 8px !important;
  }
  .pro-btn-align{
    max-width: 100% !important;
  }
  .pro-block{
    display: block !important;
  }
  .dashboard-card{
    height: 140px !important;
  }
  .ipad-pool-align{
    min-width: 33.333% !important;
  }
}
@media screen and (min-width: 1400px) {
  .fit-body-expanded {
    width: 82%;
    right: 0px;
    position: absolute;
  }

  .fit-body-collapsed {
    width: 97%;
    right: 0px;
    position: absolute;
  }

  .fit-body-collapsed {
    .responsive-create-project {
      width: 98%;
    }
  }
}

// samsung fold
@media(max-width: 320px) {
  .galaxy-block {
    display: block !important;
  }
  .galaxy-flex{
    display: flex !important;
  }
  .galaxy-padding {
    padding: 10px !important;
  }
  .share-align{
    padding: 20px !important;
  }
  .galaxy-padding-top-10 {
    padding-top: 10px !important;
  }

  .galaxy-padding-top-30 {
    padding-top: 30px !important;
  }

  .galaxy-padding-bottom-10 {
    padding-bottom: 10px !important;
  }

  .galaxy-margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .galaxy-margin-top-5 {
    margin-top: 5px !important;
  }
  .gal-slot-align{
    margin-top: 20px !important;
  }
  .galaxy-margin-bottom-10 {
    margin-bottom: 10px !important;
  }

  .galaxy-padding-left-0 {
    padding-left: 0 !important;
  }

  .galaxy-padding-0 {
    padding: 0 !important;
  }

  .galaxy-padding-5 {
    padding: 5px !important;
  }

  .galaxy-margin-0 {
    margin: 0 !important;
  }

  .galaxy-margin-right-0 {
    margin-right: 0 !important;
  }

  .galaxy-margin-left-0 {
    margin-left: 0 !important;
  }

  .galaxy-margin-left-10 {
    margin-left: 10px !important;
  }

  .galaxy-padding-right-5 {
    padding-right: 5px !important;
  }

  .galaxy-padding-top-0 {
    padding-top: 0 !important;
  }

  .galaxy-padding-right-0 {
    padding-right: 0 !important;
  }

  .galaxy-justify-end {
    justify-content: end;
  }
  .revamp-align{
    padding-left: 10px !important;
  }
  .stepper-left-panel{
    max-height: 655px !important;
  }
  .shortlst-menu{
    min-height: 30px !important;
  }
  .menu-list {
    display: none;
  }
  .header-inline-flex{
    display: inline-flex !important;
  }
  .zooming-sidebar {
    margin-left: 0px !important;
  }
  .create-project-stepper{
    .MuiStep-root{
      .MuiStepLabel-vertical{
        padding: 2px 8px !important;
      }
    }
  }
  .modal-content {
    width: 270px !important;
  }
  .job-card-align{
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .panel-modal-align{
    width: 100px !important;
  }
  .modal-content1 {
    width: 250px;
  }

  .shortlist-modal {
    width: 250px;
  }

  .invite-modal-content {
    width: 250px;
  }

  .modal-content.work-exclusivity-modal-content {
    width: 250px !important;
  }

  .responsive-view-align {
    padding: 0 !important;
  }

  .notification-drawer .MuiDrawer-paper {
    width: 90% !important;
  }

  .job-detail-drawer {
    .MuiDrawer-paper {
      width: 90% !important;
    }
    .drawer-align{
      padding: 20px !important;
    }
  }

  .card-section-campaign {
    padding: 0 !important;
  }

  .modalStyle {
    width: 250px !important;
  }

  .header-fixed-section {
    position: sticky;
    width: 100%;
    top: 0;
  }

  .stepper-left-panel {
    width: 100%;
    position: static;
    overflow: hidden;
    left: 0;
    right: 0;
  }

  .stepper-right-panel {
    width: 100%;
    margin-left: 0 !important;
    overflow-y: auto;
    position: static;
  }

  .galaxy-fold-top {
    margin-top: 30px !important;
  }

  .enterprise-access-section .profile-section .enterprise-section {
    margin-left: 0px !important;
  }

  .logoImg {
    height: 35px !important;
    padding: 0 10px;
  }

  .joblist-header-section {
    .search-job {
      flex-direction: column !important;
    }
  }

  .job-detail-drawer,
  .jobDetail-drawer {
    .MuiDrawer-paper {
      width: 95% !important;
    }
    .drawer-align{
      padding: 20px !important;
    }
  }

  .responsive-button-100 {
    width: 100%;
  }

  .MuiDialog-root {
    .MuiDialog-scrollPaper {
      .MuiPaper-root {
        min-width: 365px !important;
      }
    }
  }

  .galaxy-resolution-button {
    width: 230px !important;
  }

  .talent-pool-status {
    margin-right: 0px !important;
    padding: 10px !important;
  }

  .category-rating {
    justify-content: left !important;
  }

  .enterprise-access-section .profile-section .enterprise-section {
    margin-left: 110px;
  }

  .profile-img {
    width: 100px !important;
    height: 100px !important;
    object-fit: cover;
    margin-top: 20px !important;
  }

  .updateProfile {
    top: 123px !important;
    left: -8px !important;
    height: 45px !important;
    width: 101% !important;
  }

  .updateImage {
    position: absolute;
    top: -43px;
    left: 20px;
    color: #fff;
    cursor: pointer;
    width: 20px;
  }

  .enterprise-access-section {
    .profile-section {
      .profile-main-section {
        position: absolute;
        top: 155px;
      }
    }
  }

  .enterprise-access-section .adduserbox {
    padding-top: 13px !important;
    justify-content: center !important;
  }

  .zoom-font {
    font-size: var(--font-size-14) !important;
  }

  .enterprise-access-section .profile-section {
    height: auto !important;
    display: block !important;
  }

  .enterprise-access-section .profile-section .enterprise-section {
    margin-top: 100px !important;
  }

  .enterprise-access-section .profile-section .enterprise-section {
    margin-left: 110px;
  }

  .swiper-slide {
    width: 100% !important;
  }

  .responsive-img-block {
    width: 210px;
    height: auto;
  }

  .responsive-direct-source {
    .direct-source {
      margin-top: 0em !important;
    }
  }

  .responsive-content {
    padding-top: 10px !important;
  }

  p {
    &.launch-page-title2 {
      font-size: 32px !important;
    }
  }

  .logoImg-navbar {
    height: 25px;
    margin: 9px 13px 3px 15px;
  }

  .launch-align {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .neuro-diversity-background{
    background-image: none;
    .neurodiversity-outlet{
      width: 100% !important;
    }
  }
  .left-side-section-image {
    display: none !important;
  }
  .align-center-big-screen {
    .neuro-diversity-section {
      display: none !important;
    }
    .neuro-right {
      right: 0 !important;
    }
  }

  .enterpriseSearch .MuiFormControl-root {
    width: 200px !important;
  }

  .default-manager-img {
    width: 30px !important;
    height: 30px !important;
    padding: 10px !important;
  }

  .MuiDialog-root {
    .MuiDialog-scrollPaper {
      .MuiPaper-root {
        min-width: 269px !important;
      }
    }
  }

  .responsive-checklist {
    .MuiTextField-root {
      min-width: 220px !important;
    }
  }

  .matching-details-align {
    margin-top: 5px !important;
  }

  .search-byId {
    width: 50px;
    margin: 0 auto !important;
  }

  .responsive-checklist {
    margin-left: 0 !important;
  }

  .responsive-flex {
    display: flex !important;
  }

  .sort-filter-center {
    justify-content: center !important;
  }

  .width-in-galaxy {
    width: 160px;
  }

  .WorkExperienceBullet {
    width: 150px;
    display: block;
  }

  .panel-section {
    .MuiBox-root {
      padding: 2px;
    }
  }

  .panel-section-skills {
    .MuiBox-root {
      padding: 2px;
    }
  }

  .skills-panel-section {
    min-height: 85vh;
    max-height: 65vh;
    overflow-y: auto;
  }

  .responsivegrid {
    width: 90% !important;
  }

  .talent-pool-responsive-btn {
    margin: 0 auto !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
    margin-top: 5px !important;
  }

  .responsive-tab {
    padding-left: 28px !important;
  }

  .navBar ul li .active {
    padding-bottom: 12px !important;
  }

  .infoFont {
    font-size: var(--font-size-14) !important;
  }

  .policyEnterprise {
    margin-top: 18px !important;
  }

  .responsive-bottom-section {
    &.direct-source-responsive {
      padding-bottom: 20em;
    }
  }

  .shortlist-modal {
    width: 265px !important;
  }

  .galaxy-filter {
    width: 95px;
    margin: 0 auto !important;
    display: flex !important;
  }

  .occupied-status {
    font-size: var(--font-size-10);
    padding-right: 2px !important;
  }

  .available-status {
    font-size: var(--font-size-10);
  }

  .maybe-available-status {
    font-size: var(--font-size-10);
  }

  .text-width-galaxy {
    min-width: 220px !important;
  }

  #upload-logo-image-modal .modal-content {
    width: 260px;
  }

  .default-logo-image-width {
    width: 180px;
  }

  .w-0 {
    width: 0 !important;
  }

  .checklist-content {
    min-width: 0 !important;
  }

  // .galaxy-scoring-modal{
  //   padding: 0 135px !important;
  // }
  // .locationAutocomplete{
  //   .MuiTextField-root{
  //     width: 35% !important;
  //   }
  // }
  // .galaxy-width-input{
  //   width: 35% !important;
  // }
  // .galaxy-select{
  //   &.MuiInputBase-root{
  //     width: 35% !important;
  //   }
  // }
  .galaxy-width{
    width: 100% !important;
  }
  .galaxy-width-preview {
    .matching-location-align {
      margin-left: 0 !important;
    }

    .access-input {
      width: 40px !important;
    }

    .MuiTextField-root {
      min-width: 0 !important;
    }
  }

  .scoring-modal #modal-description {
    max-height: 145px !important;
    overflow: auto;
  }

  .scoring-input-align {
    // width: max-content !important;
    width: 40px !important;
  }

  .matching-location-align {
    margin: 0px !important;
  }

  .left-side-score-section {
    margin-left: 0 !important;
  }

  .stepper-left-panel {
    width: 100%;
    position: static;
    overflow: hidden;
    left: 0;
    right: 0;
    max-height: 685px;
  }

  .profile-video-thumbnail {
    width: 50px;
    height: 50px;
  }

  .sideIcon-align {
    display: flex !important;
  }

  .sideIcon-align svg {
    margin-top: 6px !important;
  }

  .sideBar-icon {
    margin-top: 30px !important;
  }

  .search-id {
    min-width: 260px !important;
  }

  .MuiPickersBasePicker-pickerView {
    min-width: 250px !important;
  }

  .MuiPickersMonthSelection-container {
    width: 270px !important;
  }

  .stepper-right-panel {
    .panel-section {
      padding-bottom: 0 !important;
    }
  }

  .galaxy-job-menu {
    width: 70px;
    margin: 0 auto !important;
    display: flex !important;
    margin-top: 5px !important;
  }

  .modal-content.checklist-modal {
    width: 260px !important;
  }

  .signin-title {
    font-size: var(--font-size-20) !important;
  }

  .header-search-bar {
    position: absolute;
    top: 46px;
    width: 100%;
  }

  .matching-breakdown-responsive {
    padding-left: 2.4em;
  }

  .responsive-icon {
    vertical-align: flex-start !important;

    img {
      // margin-top: 4px !important;
      width: 12px;
    }
  }

  .responsive-icon {
    &.responsive-sidebar-icon {
      padding-bottom: 25px !important;
    }
  }

  .responsive-icon {
    &.responsive-calender-icon {
      padding-bottom: 35px !important;
    }
  }

  .galaxy-date-text {
    margin-top: 12px;
  }

  .galaxy-font-score {
    font-size: 6px !important;
  }

  .galaxy-font-12 {
    font-size: var(--font-size-12) !important;
  }

  .responsive-share-container {
    width: 240px !important;
    margin: 0px !important;
  }

  .responsive-copy-link {
    .MuiInputBase-input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 15px !important;
    }
  }

  .responsive-share-icon {
    margin-left: -25px !important;
  }

  .galaxy-width-200 {
    width: 200px !important;
  }

  .galaxy-width-100 {
    width: 100px !important;
  }

  // .time-lineStepper .timeline-img {
  //   top: -8px;
  // }

  .tp-side-card {
    height: 115px;
  }

  .responsive-settings {
    padding: 0 !important;
  }

  .settings-sidebar-fix {
    position: absolute;
    z-index: 0;
    width: 100% !important;

    .settings-sidebar {
      padding: 0 !important;
      // margin-right: 30px;
    }
  }

  .settings-body {
    margin-left: 0 !important;
    padding-top: 37em;
    border-left: 0;
    width: 100% !important;
    padding-right: 0;
  }

  .settings-align {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .galaxy-close-icon {
    width: 5px !important;
  }

  .responsive-flex-start {
    justify-content: flex-start !important;
  }

  .galaxy-score-last-used {
    width: 50px;
  }

  .galaxy-image-left {
    margin-left: -5px !important;
  }

  .request-expand .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    right: -2px !important;
    top: 62px !important;
  }

  .galaxy-request-expanded {
    &.request-expand .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      top: 34px !important;
      padding: 10px;
    }
  }

  .request-expand .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    right: 0px;
    top: 45px !important;
    padding: 10px;
  }

  .direct-source-responsive {
    padding-bottom: 5em !important;
  }
  .occupied-align{
    width: max-content !important;
  }
  .delete-skill-align{
    margin-left: 5px !important;
  }
  .education-align{
    padding-left: 8px !important;
  }
}
.mw-76 {
  max-width: 76% !important;
}
// 100%
@media screen and (min-width: 600px) and (max-width: 1281px){
  .location-card {
    width: 165px !important;
  }
  .tooltipLocation{
    left: -25px !important;
  }
  .no-talent-console{
    margin-top: 0px !important;
  }
  .tooltipLocation{
    &.other-location{
      left: -53px !important;
    }
  }
  .screening-preview {
    height: 400px;
  }
}
// 90%
@media screen and (min-width: 1281px) and (max-width: 1423px){
  .location-card {
    width: 180px !important;
  }
  .tooltipLocation{
    left: -35px !important;
  }
  .no-talent-console{
    margin-top: 20px !important;
  }
  .tooltipLocation{
    &.other-location{
      left: -59px !important;
    }
  }
  .question-list {
    height: 156px;
    overflow-y: auto;
}
.screening-question-view{
  height: 360px;
  overflow-y: auto;
}
.screening-response-overflow{
  height: 493px !important;
}
.screening-preview {
  overflow-y: auto;
  height: 410px !important;
}
.cross-selling, .cross-sell-jobs{
  height:245px !important;
}
  .stepper-left-panel{
    max-height: 550px !important;
  }
  .skills-panel-section {
    min-height: 56vh !important;
    max-height: 56vh !important;
  }
}
// 80%
@media screen and (min-width: 1423px) and (max-width: 1710px) {
  .talent-pool-name{
    width: 165px !important;
  }
  .location-card {
    width: 200px !important;
  }
  .tooltipLocation{
    left: -45px !important;
  }
  .no-talent-console{
    margin-top: 50px !important;
  }
  .tooltipLocation{
    &.other-location{
      left: -73px !important;
    }
  }
  .question-list {
    height: 285px !important;
    overflow-y: auto;
}
.screening-question-view{
  height: 510px !important;
  overflow-y: auto;
}
.screening-response-overflow{
  height: 650px !important;
}
.screening-preview {
  overflow-y: auto;
  height: 545px !important;
}
.cross-selling, .cross-sell-jobs{
  height:325px !important;
}
  .stepper-left-panel{
    max-height: 650px !important;
  }
  .skills-panel-section {
    min-height: 61vh !important;
    max-height: 61vh !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1710px) {
  .modal-description-wizard-detail {
    min-height: 25vh;
    max-height: 100vh;
    height: 71vh !important;
  }
  .screening-question-view{
    height: 427px !important;
    overflow-y: auto;
  }
  .screening-response-overflow{
    height: 567px !important;
  }
  .screening-preview{
    overflow-y: auto;
    height: 465px !important;
  }
  }
@media screen and (min-width: 1100px) and (max-width: 1220px) {
  .talent-pool-name {
    width: 110px !important;
  }
}