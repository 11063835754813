.p-0{
    padding: 0 !important;
}
.p-2{
    padding: 2px !important;
}
.p-5{
    padding: 5px !important;
}
.p-7{
    padding:7px !important;
}
.p-15{
    padding: 15px !important;
}
.pt-2{
    padding-top: 2px !important;
}
.pt-3{
    padding-top: 3px !important;
}
.pt-4{
    padding-top: 4px !important;
}
.pt-7{
    padding-top: 7px !important;
}
.pt-8{
    padding-top: 8px !important;
}
.pt-12{
    padding-top: 12px !important;
}
.pt-16{
    padding-top: 16px !important;
}
.pt-20{
    padding-top: 20px !important;
}
.pt-25{
    padding-top: 25px !important;
}
.pt-30{
    padding-top: 30px !important;
}
.pt-35{
    padding-top: 35px !important;
}
.pt-40{
    padding-top: 40px !important;
}
.px-10{
    padding:0 10px !important;
}
.px-25{
    padding:0 25px !important;
}
.pt-60{
    padding-top:60px !important;
}
.pb-12{
    padding-bottom: 12px !important;
}
.pb-20{
    padding-bottom: 20px !important;
}
.pb-38{
    padding-bottom: 38px !important;
}
.pb-46{
    padding-bottom: 46px;
}
.pb-48{
    padding-bottom: 48px;
}
.pb-33{
    padding-bottom: 33px !important;
}
.pb-25{
    padding-bottom: 25px !important;
}
.pb-50{
    padding-bottom: 50px !important;
}
.pb-100{
    padding-bottom: 100px !important;
}
.pb-54{
    padding-bottom: 54px !important;
}
.pb-53{
    padding-bottom: 53px;
}
.pt-10{
    padding-top: 10px !important;;
}
.pt-110{
    padding-top:110px !important;
}

.pt-160{
    padding-top:160px !important
}

.p-r-10{
    padding-right: 10px;
}

.p-b-15{
    padding-bottom: 15px;
}
.p-t-b-10{
    padding: 10px 0;
}
.p-t-b-20{
    padding: 20px 0;
}
.p-t-4{
    padding-top: 4px !important;
}
.p-4{
    padding: 4px !important;
}
.p-6{
    padding: 6px !important;
}
.p-8{
    padding: 8px !important;
}
.p-10{
    padding: 10px !important;
}
.p-20{
    padding:20px !important;
}
.pl-1{
    padding-left: 1px !important;
}
.pl-2{
    padding-left: 2px;
}
.pl-6{
    padding-left: 6px !important;
}
.pl-14{
    padding-left: 14px !important;
}

.pl-20{
    padding-left:20px !important;
}
.pl-23{
    padding-left:23px !important;
}
.pl-25{
    padding-left:25px !important;
}

.pl-50{
    padding-left: 50px !important;
}
.pl-90{
    padding-left: 90px !important;
}
.pr-50{
    padding-right: 50px !important;
}
.pr-52{
    padding-right: 52px !important;
}
.pl-5{
    padding-left:5px !important;
}

.matchingSkills{
    .skills{
        padding:2px 6px !important;
    }
}
.pt-0{
    padding-top:0px !important;
}
.pl-10{
    padding-left:10px !important;
}
.pl-13{
    padding-left:13px !important;
}
.pl-0{
    padding-left:0px !important;
}
.pr-0{
    padding-right: 0px !important;
}
.pb-16{
    padding-bottom: 16px !important;
}
.pb-10{
    padding-bottom:10px !important;
}
.px-4{
    padding:0px 4px;
}
.px-5{
    padding:0px 5px;
}
.px-6{
    padding:0px 6px;
}
.pl-15{
    padding-left:15px !important;
}
.py-3 {
    padding: 3px 0;
}
.py-5{
    padding: 5px 0;
}
.py-10{
    padding: 10px 0 !important;
}
.py-15{
    padding: 15px 0 !important;
}
.py-20 {
    padding: 20px 0;
}
.py-25{
    padding: 25px 0;
}
.pt-5{
    padding-top:5px !important;
}
.pt-6{
    padding-top:6px !important;
}
.pb-5{
    padding-bottom:5px !important;
}
.pb-6{
    padding-bottom:6px !important;
}
.pb-45{
    padding-bottom: 45px !important;
}
.pb-7{
    padding-bottom:7px !important;
}
.pb-0{
    padding-bottom: 0px !important;
}
.pl-35{
    padding-left: 35px !important;
}
.p-3{
    padding:3px !important;
}
.pr-20{
    padding-right:20px !important;
}
.pr-30{
    padding-right: 30px;
}
.pr-80{
    padding-right: 80px;
}
.pr-5{
    padding-right: 5px !important;
}
.pr-8{
    padding-right: 8px;
}
.pr-10{
    padding-right:10px !important;
}
.pt-15{
    padding-top: 15px !important;
}
.pb-15{
    padding-bottom: 15px !important;
}
.pr-15{
    padding-right:15px !important;
}
.p-r-1{
    padding-right: 1px;
}
.pb-40{
    padding-bottom:40px !important;
}
.pl-7{
    padding-left:7px !important;
}
.pr-25 {
    padding-right: 25px !important;
}
.px-3{
    padding: 0px 3px;
}
.pr-25 {
    padding-right: 25px !important;
}
.pl-12{
    padding-left:12px !important;
}
.p-r-5{
    padding-right:5px !important;
}
.p-l-5{
    padding-left: 5px !important;
}
.p-l-100{
    padding-left: 100px !important;
}
.pr-25{
    padding-right:25px !important;
}
.pl-30{
    padding-left: 30px !important;
}
.p-30{
    padding:30px !important;
}
.p-60{
    padding: 60px !important;
}
.pl-40{
    padding-left: 40px !important;
}
.pl-45{
    padding-left: 45px !important;
}
.pl-4-1{
    padding-left: 4.1px !important;
}
.pb-30{
    padding-bottom: 30px !important;
}
.pb-60{
    padding-bottom: 60px !important;
}
.p-x-5 {
    padding: 0 5px !important;
}
.pl-51 {
    padding-left: 57px !important;
}
.pl-18 {
    padding-left: 18px !important;
}
.pr-18 {
    padding-right: 18px !important;
}
.pl-31 {
    padding-left: 31px !important;
}
.pr-31 {
    padding-right: 31px !important;
}
.pr-38 {
    padding-right: 38px !important;
}
.pl-17{
    padding-left: 17px !important;
}
.pr-17{
    padding-right: 17px !important;
}
.pl-24 {
    padding-left: 24px !important;
}
.pb-54{
    padding-bottom: 54px !important;
}
.pr-25 {
    padding-right: 25px !important;
}
.p-b-0{
    padding-bottom: 0px !important;
}
.pb-8{
    padding-bottom: 8px !important;
}
.p-14{
    padding: 14px !important;
}
.p-9{
    padding: 9px !important;
}
.p-7{
    padding: 7px;
}
.p-7-10{
    padding: 7px 10px;
}
.p-6-16{
    padding: 6px 16px;
}
.p-10-20{
    padding: 10px 16px;
}
.p-25-20-20-10{
    padding: 25px 20px 20px 10px;
}