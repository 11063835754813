.table-section{
    .MuiTable-root{
        .MuiTableHead-root{
            tr{
                border-bottom: 1px solid #696871;
          /*  &:last-child{
                border-bottom: 0;
            }*/
                th{
                    p{
                        color: #696871;
                        font-size: var(--font-size-14);
                        opacity: 1;
                        font-weight:var(--font-weight-600);
                    }
                }
            }
        }
        .MuiTableBody-root{
            tr{
                td{
                   p{
                       color: var(--gray7);
                       font-size: var(--font-size-14);
                       font-weight: normal;
                   }
                }
            }
        }
    }
}
.MuiButtonBase-root{
    // margin-right: 3px !important;
}



.enterprise-access-table{
    .MuiTable-root{
        font-family: var(--font-family) !important;
        .MuiTableHead-root{
            tr{
                // border-bottom: 1px solid #696871;
                /*  &:last-child{
                      border-bottom: 0;
                  }*/
                th{
                    // color: #767676;
                    background: #F2F4F7;
                    // font-size: 13px;
                    // opacity: 1;
                    // font-weight: 600;
                    padding: 6px 10px;
                    color: var(--gray9) !important;
                    opacity: 1 !important;
                    font-size: var(--font-size-12) !important;
                    font-weight:var(--font-weight-500) !important;
                    // padding:5px 15px !important;
                    font-family: var(--font-family) !important;
                    border-bottom: none !important;
                }
            }
        }
        .MuiTableBody-root{
            background-color: #FFFFFF;
            tr{
                td{
                    color: var(--gray10);
                    font-size: var(--font-size-14);
                    font-weight: normal;
                    padding: 20px 10px 15px 10px;
                    font-weight:var(--font-weight-400) !important;
                    vertical-align: top;
                }
            }
        }
    }
    .MuiTablePagination-select{
        margin-right: 10px !important;
        margin-bottom: 2px;
    }
    .MuiButtonBase-root{
        margin-right: 3px !important;
    }
}
.Notes-table{
    box-shadow:none !important;
    .MuiTable-root{
        .MuiTableHead-root {
            .MuiTableRow-root{
                th{
                    color: var(--gray9) !important;
                    font-size:13px !important;
                    font-weight:var(--font-weight-600) !important;
                    text-align: left;
                    padding: 16px 5px;
                }

            }
        }
        .MuiTableBody-root{
            .MuiTableRow-root{
                th,td{
                    color: var(--gray9);
                    font-size: var(--font-size-12);
                    font-weight:var(--font-weight-400);
                    text-align: left;
                    border-bottom:1px solid rgba(105, 104, 113, 0.2) !important;
                    word-break: break-all;
                    padding: 16px 5px;
                }
            }
        }
    }
}

.vertical-align-top{
    vertical-align: top !important;
}
// .my-candidate-list{
//     .MuiTable-root{
//       font-family: $font-family !important;
//       .MuiTableHead-root{
//         .MuiTableRow-root{
//             .MuiTableCell-stickyHeader{
//                 top:80px !important;
//                 background: #FFFFFF !important;
//             }
//         }
//         th{
//           font-weight: 600;
//           font-size: 13px;
//           color: #696871;
//           opacity: 1;
//           border-bottom: 1px solid rgba(105, 104, 113, 0.2) !important;
//           font-family: $font-family !important;
//         }
//       }
//       .MuiTableBody-root{
//         tr{
//             td{
//                 border-bottom: 1px solid rgba(105, 104, 113, 0.2) !important;
//                 vertical-align: top !important;
//                 font-size:14px !important;
//                 font-weight: 400 !important;
//               }
//         }
//       }
//     }
//   }
.candidate-lead-list{
    .MuiTable-root{
        .MuiTableCell-root{
            // padding:10px !important;
        }
    //   font-family: $font-family !important;
        .MuiTableHead-root{
        .MuiTableRow-root{
            .MuiTableCell-stickyHeader{
                top:80px !important;
                background: #FFFFFF !important;
            }
        }
        th{
            font-weight: var(--font-weight-600);
            font-size: var(--font-size-12);
            color: var(--gray7);
            opacity: 1;
            border-bottom: 1px solid rgba(105, 104, 113, 0.2) !important;
            font-family: var(--font-family) !important;
            line-height: 19px !important;
        }
        }
        .MuiTableBody-root{
        tr{
            td{
                border-bottom: 1px solid rgba(105, 104, 113, 0.2) !important;
                vertical-align: middle !important;
                font-size:var(--font-size-14) !important;
                font-weight: var(--font-weight-400) !important;
                }
        }
        }
    }
}
.candidate-skills{
    .skills{
        padding: 4px 16px !important;
        font-size: var(--font-size-14) !important;
        margin: 2px 0px !important;
    }
}
.table-list-align {
    .matching-table {
        .MuiTableBody-root {
            tr {
                td {
                    // padding-bottom: 20px !important;
                }
                td.pb-40{
                    // padding-bottom: 40px !important;
                }
            }
        }
    }
}
.matching-table{
    margin-top: 5px;
    border-collapse: unset !important;
    .MuiTableHead-root{
        background: #F2F4F7;
        border-radius: 8px;
        tr{
            th{
                color: var(--gray9) !important;
                opacity: 1 !important;
                font-size: var(--font-size-12) !important;
                font-weight:var(--font-weight-500) !important;
                padding: 6px 10px !important;
                // padding:5px 15px !important;
                font-family: var(--font-family) !important;
                border-bottom: none !important;
                vertical-align: top !important;
                .MuiFormGroup-root{
                    .MuiFormControlLabel-root {
                            margin-right: -14px !important;
                    }
                }
            }
        }
    }
    .MuiTableBody-root{
        tr{
            td{
                color: var(--gray10) !important;
                background-color: white;
                font-weight: var(--font-weight-400) !important;
                font-size: var(--font-size-14) !important;
                vertical-align: top;
                border-bottom: 1px solid rgba(105, 104, 113, 0.2) !important;
                // padding: 20px 10px 15px 10px !important;
                padding-top: 20px !important;
                padding-right: 10px !important;
                padding-left: 10px !important;
                padding-bottom: 15px;
                // padding:12px 15px !important;
                font-family: var(--font-family) !important;
            }
            &:hover{
                td {
                    background-color: var(--list-hover-color) !important;
                }
            }
        }
    }
}
.matching-menu{
    button{
        padding: 0px !important;
        justify-content: flex-end !important;
        // padding-right: 10px !important;
    }
}
.candidate-lead-action{
    button{
        padding: 0px !important;
        justify-content: flex-start !important;
    }
}
.candidate-list-shortlist{
    button{
        font-size: var(--font-size-12) !important;
        color:var(--gray9) !important;
        font-weight: var(--font-weight-400) !important;
        align-items: center !important;
        font-family: var(--font-family) !important;
        padding-right: 70px !important;
        // padding-left: 16px !important;
        &.primary-button{
            color: #FFFFFF !important;
        }
    }
    
}
.editPool-shortlist{
    .shortList{
        font-size: var(--font-size-12) !important;
        color:var(--gray9) !important;
        font-weight: var(--font-weight-400) !important;
        align-items: center !important;
        font-family: var(--font-family) !important;
        justify-content: flex-start !important;
        &.primary-button{
            color: #FFFFFF !important;
        }
    }
}
.candidate-details{
    color: var(--gray9) !important;
    opacity: 1 !important;
    font-size: var(--font-size-12) !important;
    font-weight: var(--font-weight-500) !important;
    padding: 4px 10px !important;
    font-family: var(--font-family) !important;
    border-bottom: none !important;
    margin-left: 15px;
}
.candidate-status-align{
    .matching-table{
        .MuiTableBody-root{
            tr{
                td{
                    padding: 20px 5px 15px 10px !important;
                }
            }
        }
    }
}
.suggested-slots{
    .MuiTableHead-root{
        background: #FFFFFF;
        tr{
            th{
                color: var(--gray7) !important;
                opacity: 1 !important;
                font-size: var(--font-size-14) !important;
                font-weight:var(--font-weight-500) !important;
                padding: 10px 0px !important;
                font-family: var(--font-family) !important;
                border-bottom: none !important;
            }
        }
    }
    .MuiTableBody-root{
        tr{
            td{
                color: var(--gray7) !important;
                font-weight: var(--font-weight-400) !important;
                font-size: var(--font-size-14) !important;
                border-bottom: none !important;
                padding: 15px 0px 0px !important;
                font-family: var(--font-family) !important;
            }
        }
    }
}
.top-align-table{
    .MuiTableHead-root tr th{
        vertical-align: top !important;
    }
}
.policy-table{
    .MuiPaper-root{
        box-shadow: none !important;
        border:1px solid $gray2 !important;
        border-radius: 0px !important;
    }
    .MuiTableHead-root{
        .MuiTableCell-root{
            border:1px solid $gray7 !important;
        }
    }
    .MuiTableBody-root{
        .MuiTableCell-root{
            vertical-align: top !important;
            border:1px solid $gray7 !important;
        }
    }
}