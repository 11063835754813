.editor-class{
  background-color: $bg-white;
  height: 150px !important;
  border: 1px solid var(--gray6) !important;
  border-radius: 6px;
  .DraftEditor-root{
    padding: 10px;
  }
}

.editor-class-offer{
  background-color: $bg-white;
  height: 280px !important;
  min-height: 300px !important;
  border: 1px solid var(--gray6) !important;
  border-radius: 6px;
  .DraftEditor-root{
    padding: 10px;
  }
}

.offer-editor-container {
  .editor-class-offer{
    background-color: $bg-white;
    height: 280px !important;
    min-height: 320px !important;
    border: 1px solid var(--gray6) !important;
    border-radius: 6px;
    .DraftEditor-root{
      padding: 10px;
    }
  }
}

.wrapper-class{
}
.toolbar-class{
border: 1px solid var(--gray6) !important;
border-radius: 6px !important;
}
.public-DraftStyleDefault-block{
  margin: 4px 10px;
}

.public-DraftStyleDefault-block {
   margin: 2px 0 !important;
}
.editor-class-offer {
  .public-DraftStyleDefault-block {
    margin: 0px 0px !important;
  }
}
.rdw-emoji-wrapper{
  display: none !important;
}
.rdw-embedded-wrapper{
  display: none !important;
}
.rdw-image-wrapper{
  display: none !important;
}
.rdw-remove-wrapper{
  display: none !important;
}
.rdw-block-dropdown{
  //border: 1px solid $gray6 !important;
}
.rdw-option-wrapper{
  //border: 1px solid $gray6 !important;
  &.rdw-option-disabled{
    display: none !important;
  }
}

.rdw-dropdown-carettoopen{
  right: 2% !important;
}
.openImage .rdw-image-wrapper{
  display: block !important;
}
.editor-align {
  .rdw-editor-toolbar {
    padding-right: 100px;
    position: relative;
  }
}

.editor-img {
  position: absolute;
  right: 250px;
  margin-top: 20px;
}