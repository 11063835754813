/* ChatWindow.css */
.chat-container {
    display: flex; /* Align chat windows horizontally */
    align-items: flex-end;
    position: fixed; /* Position at the bottom */
    bottom: 0; /* Align to the bottom */
    justify-content: flex-end;
    width: 100%; /* Full width */
    z-index: 99999;
    /* z-index: 1300; */
    pointer-events: none;
}

.chat-window {
    border: 1px solid #98A2B3;
    border-radius: 10px;
    width: 300px; /* Adjust width as needed */
    margin-right: 10px; /* Space between windows */
    transition: all 0.3s;
    background-color: white;
    pointer-events: all;
}

.chat-header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 50px;
}

.chat-title {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-body {
    padding: 10px;
}

.minimized {
    overflow: hidden;
    border: 1px solid #ccc;
}
.sent-sms .MuiFormControlLabel-label{
    margin-left: 0px !important;
}
.sent-sms {
    margin-right: 0px !important;
}
.chat-picker .EmojiPickerReact{
    margin-right: -10px !important;
}
.chat-placeholder .MuiInputBase-input{
    padding-left: 0px !important;
}
.new-chat-search{
    border: 1px solid #98A2B3;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-width: 0px;
}
.search-id-chat .search-id{
    border: 1px solid #98A2B3 !important;
    border-radius: 10px !important;
}
.search-id-new .search-id{
    border: 1px solid #98A2B3 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}