/*Font-size*/

.sub-text-font {
  font-size: var(--font-size-16) !important;
}

.sub-heading-font {
  font-size: var(--font-size-24) !important;
}

.small-text-font {
  font-size: var(--font-size-14) !important;
}

::placeholder {
  font-size:var(--font-size-14);
  font-family: var(--font-family) !important;
  color: var(--gray7);
  font-weight: normal !important;
  opacity: 0.6 !important;
}
.f-5 {
  font-size: 5px !important;
}
.f-12 {
  font-size: var(--font-size-12) !important;
}

.f-13 {
  font-size: $font-size-13 !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-14 {
  font-size: var(--font-size-14) !important;
}
.f-16 {
  font-size: var(--font-size-16) !important;
}
.f-18 {
  font-size: var(--font-size-18) !important;
}
.f-20 {
  font-size: var(--font-size-20) !important;
}
.f-21 {
  font-size: 21px !important;
}
.f-22 {
  font-size: 22px !important;
}
.f-24 {
  font-size: var(--font-size-24) !important;
}
.f-25 {
  font-size: 25px !important;
}
.f-32 {
  font-size: 32px !important;
}
.f-35 {
  font-size: 35px !important;
}
.f-36 {
  font-size: var(--font-size-36) !important;
}
.f-40 {
  font-size: 40px !important;
}
.f-42 {
  font-size: 42px !important;
}

.font-weight-500 {
  font-weight: var(--font-weight-500) !important;
}

.font-weight-600 {
  font-weight: var(--font-weight-600) !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-normal {
  font-weight: normal !important;
}
.align-item-center {
  align-items: center !important;
  justify-content: center !important;
  vertical-align: middle !important;
  display: flex !important;
}

.align-item-left {
  align-items: left;
  justify-content: left;
  vertical-align: middle;
  display: flex !important;
}

.align-item-right {
  align-items: right;
  justify-content: right;
  vertical-align: middle;
  display: flex !important;
}
.f-10 {
  font-size: var(--font-size-10) !important;
}
.f-11 {
  font-size: 11px !important;
}
.w-45 {
  width: 45px;
}
.w-83 {
  width: 83% !important;
}
.w-20 {
  width: 20px;
}
.h-20{
  height:20px;
}
.h-200 {
  height: 200px;
}
.w-16 {
  width: 16px;
}
.w-40 {
  width: 40% !important;
}
.f-19 {
  font-size: 19px !important;
}
.l-h-1 {
  line-height: 1 !important;
}
.l-h-20 {
  line-height: 20px !important;
}
.l-h-40 {
  line-height: 40px !important;
}
.font-weight-400 {
  font-weight: var(--font-weight-400) !important;
}

.text-lowercase {
  text-transform: lowercase;
}
.font-8 {
  font-size: 8px !important;
}
.border-radius-10 {
  border-radius: 10px !important;
}
.border-radius-100 {
  border-radius: 100px !important;
}
.f-30 {
  font-size: 30px !important;
}
.text-initial {
  text-transform: initial !important;
}
.break-space {
  white-space: break-spaces;
}
.no-wrap {
  white-space: nowrap;
}
.border-bottom {
  border-bottom: 1px solid #ebebeb;
}
.border-bottom:last-child {
  border-bottom: none;
}

.h1-text {
  font-size: var(--font-size-36) !important;
  font-weight: var(--font-weight-600) !important;
  color: var(--gray9);
  line-height: 1.2 !important;
}
.h2-text {
  font-size: var(--font-size-28) !important;
  font-weight: var(--font-weight-600) !important;
  color: var(--gray9);
}
.h3-text {
  font-size: var(--font-size-20) !important;
  font-weight: var(--font-weight-600) !important;
  color: var(--gray9);
}
.h4-text {
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-600) !important;
  color: var(--gray9);
}
.body-large-text {
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9);
}

.body-medium-text {
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-500) !important;
  color: var(--gray9);
}
.body-medium-normal {
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9);
}
.label-large-text {
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-500) !important;
  color: var(--gray9);
}
.label-medium-text {
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9);
}
.supporting-text-dark {
  font-size: var(--font-size-12) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9);
}
.supporting-text-light {
  font-size: var(--font-size-12) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray7);
}
.supporying-text-medium {
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray7);
}
.supporting-text-large {
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-500) !important;
  color: var(--gray7) !important;
}
.font-family{
  font-family:  var(--font-family) !important;
}
.min-w-100 {
  min-width: 100% !important;
}
.prof-summary-text{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: $gray8 !important;
}
