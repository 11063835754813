/*Checkbox-color*/
.radio-button-class {
  .MuiRadio-colorSecondary-15.MuiRadio-checked-12 {
    color: var(--primary-color);
  }
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiSvgIcon-root {
  font-size: var(--font-size-18) !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}
.form-control1 {
  &.input-group1 {
    background: $gray3 !important;
    // border: 1px solid $gray6  !important;
    // border-radius: 6px !important;
    // padding: 6px 12px;
    width: 100%;
    font-size: 14px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-family: $font-family !important;
    color: $gray9 !important;
    font-weight: 400px !important;

    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 0px !important;
        color: $gray9 !important;
        font-size: 14px !important;
        font-weight: 400px !important;
        background: $gray3 !important;
        //background-color: transparent !important;
      }
    }
    .MuiOutlinedInput-root {
      padding: 10px 14px !important;
    }
  }
}
.job-wizard {
  .MuiFormControlLabel-label {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: $gray10 !important;
    margin-left: 2px !important;
  }
  .MuiCheckbox-root {
    padding: 9px !important;
    color: #98a2b3 !important;
    &.Mui-checked{
      color: var(--primary-color) !important;
    }
  }
}

.form-control1 {
  &.input-group1 {
    background: $gray3 !important;
    // border: 1px solid $gray6  !important;
    // border-radius: 6px !important;
    // padding: 6px 12px;
    width: 100%;
    font-size: 14px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-family: $font-family !important;
    color: $gray9 !important;
    font-weight: 400px !important;

    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 0px !important;
        color: $gray9 !important;
        font-size: 14px !important;
        font-weight: 400px !important;
        background: $gray3 !important;
        //background-color: transparent !important;
      }
    }
    .MuiOutlinedInput-root {
      padding: 10px 14px !important;
    }
  }
}
.form-control2 {
  &.input-group2 {
    background: none !important;
    width: 100%;
    font-size: 14px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-family: $font-family !important;
    color: $gray9 !important;
    font-weight: 400px !important;

    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 0px !important;
        color: $gray9 !important;
        font-size: 14px !important;
        font-weight: 400px !important;
        background: none !important;
      }
    }
    .MuiOutlinedInput-root {
      padding: 10px 14px !important;
    }
  }
}
.job-wizard {
  .MuiFormControlLabel-label {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: $gray10 !important;
    margin-left: 2px !important;
  }
  .MuiCheckbox-root {
    padding: 9px !important;
    color: #98a2b3 !important;
    &.Mui-checked{
      color: var(--primary-color) !important;
    }
  }
}
.form-control {
  &.input-group {
    background: $bg-white  !important;
    border: 1px solid var(--gray6)  !important;
    border-radius: 6px !important;
    padding: 6px 12px !important;
    width: 100%;
    font-size: var(--font-size-14) !important;
    margin-top: 7px !important;
    font-family: var(--font-family)  !important;
    color: var(--gray9)  !important;
    font-weight: var(--font-weight-400) !important;

    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 6px 4px !important;
        color: var(--gray9)  !important;
        font-size: var(--font-size-14) !important;
        font-weight: var(--font-weight-400) !important;
        //background-color: transparent !important;
      }
    }
    &.modal-border1 {
      border: 1px solid var(--gray6) !important;
      border-radius: 8px !important;
      padding: 0px 0px !important;
    }
    &.modal-border {
      border: 0px solid var(--gray6) !important;
      max-height: 200px !important;
      max-width: 643px !important;
    }
    &.missingDetailsBrief {
      max-height: 200px !important;
      max-width: 626px !important;
    }
  }
}
.missingDetailsZipCodeAlign {
  .citySection {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
  .form-control.input-group {
    margin-top: 0px !important;
  }
}
.missingDetailsPaddingFix {
  .MuiInputBase-input {
    padding: 6px 0 6px !important;
  }
}
.disabled-text-area{
&.form-control {
  &.input-group {
    background: $bg-white  !important;
    border: 1px solid var(--gray6)  !important;
    border-radius: 6px !important;
    padding: 6px 12px;
    width: 100%;
    font-size: var(--font-size-14) !important;
    margin-top: 7px !important;
    font-family: var(--font-family)  !important;
    color: var(--gray11)  !important;
    font-weight: var(--font-weight-400) !important;

    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 6px 4px !important;
        color: var(--gray11)  !important;
        font-size: var(--font-size-14) !important;
        font-weight: var(--font-weight-400) !important;
        //background-color: transparent !important;
      }
    }
  }
}}
.form-control-background-gray {
  &.input-group{
    background: $gray3  !important;
    border: 1px solid var(--gray6)  !important;
    border-radius: 6px !important;
    padding: 6px 12px;
    width: 100%;
    font-size: var(--font-size-14) !important;
    margin-top: 7px !important;
    font-family: var(--font-family)  !important;
    color: var(--gray9)  !important;
    font-weight: var(--font-weight-400) !important;

    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 6px 4px !important;
        color: var(--gray9)  !important;
        font-size: var(--font-size-14) !important;
        font-weight: var(--font-weight-400) !important;
        //background-color: transparent !important;
      }
    }
    .MuiInputBase-input.Mui-disabled {
      -webkit-text-fill-color: var(--gray6) !important;
      opacity: 0.8;
      cursor: default !important;
    }
  }
}
.question{
  .form-control {
    &.input-group {
      background: $gray3 !important;
    }
  }
}
// input focus css
.form-control {
  &.input-group {
    &.Mui-focused {
      border: 1px solid var(--primary-color) !important;
    }
  }
}
.form-control {
  &.input-group {
    &.Mui-focused {
      &.is-invalid {
        border: 1px solid $danger-color !important;
      }
    }
  }
}

.MuiAutocomplete-root {
  &.MuiAutocomplete-hasPopupIcon {
    &.Mui-focused {
      .form-control {
        border: 1px solid var(--primary-color) !important;
      }
    }
  }
}
.MuiAutocomplete-root {
  &.MuiAutocomplete-hasPopupIcon {
    &.Mui-focused {
      .autocomplete-text-field {
        .MuiAutocomplete-inputFocused {
          border: 1px solid var(--primary-color) !important;
        }
      }
    }
  }
}

.form-control {
  &.input-group {
    .MuiSelect-select {
      padding: 5px 4px !important;
    }
    &.download-talent {
      .MuiSelect-select {
        display: flex !important;
        align-items: center !important;
      }
    }
  }
}

::placeholder {
  color: var(--gray7)  !important;
  font-weight: normal !important;
  opacity: 0.6 !important;
}
.placeholder{
  color: var(--gray7)  !important;
  font-weight: normal !important;
  opacity: 0.6 !important;
}

.header-g-placeholder {
  ::placeholder {
    font-style: normal !important;
    font-weight: var(--font-weight-400) !important;
    font-size: var(--font-size-14) !important;
  }
}

// input {
//   padding: 12px 8px !important;
// }

.input-otp {
  margin-top: 40px !important;

  input {
    width: 44px !important;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #98a2b3;
    padding: 8px 0px !important;
    margin-right: 32px;
    font-size: var(--font-size-24) !important;
    font-weight: var(--font-weight-500) !important;
    color: var(--gray9) ;

    &:focus-visible {
      outline: none !important;
    }
  }
}

.skill-checklist-autocomplete {
  .MuiAutocomplete-endAdornment {
    background: $bg-white;
    padding: 2px;
    right: 5px !important;
  }
}

.skill-checklist-autocomplete {
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    padding-right: 30px !important;
  }
}

.emailList-autocomplete {
  .MuiAutocomplete-endAdornment {
    padding: 2px;
    right: 5px !important;
  }
}

.emailList-autocomplete {
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    padding-right: 30px !important
  }
}

.job .inputGroup input {
  background: $bg-white;
  border-radius: 4px;
}

.job .css-1rv3ei8-MuiFormControl-root-MuiTextField-root {
  background: $bg-white;
  border-radius: 4px;
  margin-top: 7px;
}

.inputGroup input {
  background: $bg-input-gray;
  border-radius: 4px;
}

.css-1rv3ei8-MuiFormControl-root-MuiTextField-root {
  background: $bg-white;
  border-radius: 4px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

/*Custamization of scroll*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-container::-webkit-scrollbar {
  height: 8px; /* Adjust the height of the horizontal scrollbar */
}
.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.certificate-date {
  .MuiInputBase-root {
    input {
      padding-left: 5px !important;
    }
  }
}

.table-select-option {
  background-color: #eff0f6;

  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 0 10px;
      font-size: var(--font-size-12);
    }
  }
}

.select-input {
  background-color: #eff0f6;

  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 10px;
      font-size: var(--font-size-12);
    }
  }
}

.MuiInput-root {
  &.is-invalid {
    border: 1px solid $danger-color !important;
  }
}

.is-invalid-payRate {
  &.form-control.input-group {
    border: 1px solid $danger-color  !important;
  }
}

.MuiOutlinedInput-root {
  &.is-invalid {
    border: 1px solid $danger-color !important;
  }
}

.MuiTextField-root {
  &.is-invalid {
    border: 1px solid $danger-color !important;;
  }
}

.exp-skills.MuiTextField-root {
  &.is-invalid {
    border-radius: 6px !important;
  }
}

.cityName {
  .is-invalid {
    border: 1px solid #c92938 !important;
  }
}

/*.MuiTextField-root{
  &.skills-exp{
    width: 61% !important;
  }
}*/

.MuiInput-input {
  // font-size: 16px !important;
  // font-family: $font-family !important;
  // font-weight: 400 !important;
  // color: #1F1F1F !important;
  font-size: var(--font-size-14) !important;
  font-family: var(--font-family)  !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9)  !important;
  border-radius: 6px !important;
  background-color: rgba(239, 240, 246, 1);
}

.MuiOutlinedInput-input {
  &.MuiInputBase-input {
    // font-size: 16px !important;
    // font-family: $font-family !important;
    // font-weight: 400 !important;
    // color: #1F1F1F !important;
    font-size: var(--font-size-14) !important;
    font-family: var(--font-family)  !important;
    font-weight: var(--font-weight-400) !important;
    color: var(--gray9)  !important;
    border-radius: 6px !important;
    background-color: $bg-white !important;
    padding: 12px 8px !important;
    &.Mui-disabled {
      background-color: $gray3 !important;
    }
  }
}
.disable_class {
    .form-control {
    &.input-group {
      background-color: #eff0f6 !important;
    }
  }
  &.Mui-disabled {
    background-color: $gray3 !important;
  }
}
.disable_class {
  .form-control {
  &.input-group {
    background-color: #eff0f6 !important;
  }
}
}
.bionic-rate .MuiOutlinedInput-input {
  &.MuiInputBase-input {
    font-size: var(--font-size-10) !important;
  }
}

.autocomplete-text-field {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      padding: 12px 16px !important;
    }
  }
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #eff0f6 !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.inputGroup {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      color: var(--gray9)  !important;
      font-weight: normal !important;
    }
  }
}

.MuiPaper-root.MuiPopover-paper {
  background: #ffffff !important;
  // border: 1px solid $gray4  !important;
  box-sizing: border-box !important;
  box-shadow: 0px 12px 20px rgba(216, 216, 216, 0.5) !important;
  border-radius: 4px !important;
}

.password-icon {
  button {
    position: absolute !important;
    right: 10px !important;
    top: 5px !important;
  }
}

.budget {
  .input-group {
    padding: 6px !important;

    &.select-option-section {
      margin-top: 28px !important;
      border-radius: 4px !important;
      padding-right: 7px !important;
    }
  }

  .MuiOutlinedInput-root {
    .MuiSelect-select {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    .MuiSelect-icon {
      right: 0px !important;
    }
  }
}

.budgetRange {
  .input-group {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

.budget {
  .select-option-section {
    .MuiSvgIcon-root {
      right: 3px !important;
    }

    .MuiSelect-select {
      border-radius: 4px !important;
    }
  }
}

/*

.MuiFormControl-root{
  .MuiOutlinedInput-root{
      padding:0px !important;
  }
}*/

.MuiAutocomplete-root {
  .MuiOutlinedInput-root {
    padding: 0 !important;
  }
}

.inputGroup {
  .MuiFormControl-root {
    margin: 0;

    // .MuiOutlinedInput-root {
    //   background-color: $bg-white  !important;
    //   padding-right: 0px !important;
    //   border: 1px solid #D0D5DD !important;
    // }
  }
}

.sortByDropdown {
  .MuiOutlinedInput-root {
    svg {
      right: 0px !important;
      top: 14px !important;
    }
  }

  .MuiSelect-select {
    background-color: #f7f7fb;
    padding-left: 4px !important;
    padding-right: 18px !important;
  }
}

.signup-resolution {
  .form-control {
    &.input-group {
      margin-top: 4px !important;
    }
  }
}

.form-control {
  &.input-group {
    background-color: rgba(239, 240, 246, 1);
    width: 100%;
    border-radius: 4px;
    margin-top: 7px !important;
  }
}

.select-input-form {
  .MuiSelect-select {
    padding: 12px 8px;
  }
}

.text-transform-none {
  text-transform: none !important;
}

.public-DraftEditorPlaceholder-root {
  color: #121212 !important;
  opacity: 60%;
  font-size: var(--font-size-14);
  font-family: var(--font-family)  !important;
  font-weight: normal;
}

.message-text {
  .MuiTextField-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .MuiFormControl-root {
    background: transparent !important;
    width: 480px;

    .MuiOutlinedInput-root {
      input {
        background-color: transparent !important;
      }
    }
  }
}

.MuiRating-root {
  .MuiRating-icon {
    padding: 0 0px 0 0;
  }
}

.sort-dropdown {
  .MuiSelect-select {
    background-color: #ffffff !important;
  }
}

.review-candidate {
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      padding: 10px !important;

      .MuiOutlinedInput-input {
        padding: 0px !important;
        height: 100px !important;
      }
    }
  }
}

.matching-dropdown {
  .MuiOutlinedInput-root {
    svg {
      top: 8px !important;
    }
  }
}

.dropdownSort {
  .MuiInputBase-input {
    //background-color: transparent !important;
  }

  .MuiSelect-select {
    padding: 10px 5px !important;
    background: #f2f4f7 !important;
  }

  svg {
    // display: none !important;
  }
}

.category-align {
  svg {
    top: 18px !important;
    right: 0px !important;
  }

  .MuiSelect-nativeInput {
    text-overflow: ellipsis !important;
  }
}

.rating-select-align {
  svg {
    top: 11px !important;
    right: 5px !important;
  }

  .MuiSelect-nativeInput {
    text-overflow: ellipsis !important;
  }
}

.category-checklist {
  .Mui-disabled {
    font-weight: normal !important;
    -webkit-text-fill-color: #696871 !important;
  }
}
.checklist-disable{
  .Mui-disabled {
    font-weight: normal !important;
    -webkit-text-fill-color: #696871 !important;
    border-radius: 6px !important;
    .MuiAutocomplete-endAdornment{
      background-color: #F2F4F7 !important;
    }
  }
}
.border-none {
  border: none !important;
}

textarea {
  &:focus-visible {
    outline: none !important;
    border: none !important;
  }
}

.filter-check {
  .MuiCheckbox-root {
    padding-top: 5px !important;
    padding-left: 0px !important;
  }

  .MuiFormControlLabel-label {
    font-size: var(--font-size-16) !important;
  }
}

.reject-reason {
  .MuiOutlinedInput-root {
      padding-top: 0px !important;
      padding-left: 0px !important;
  }
}

.notes-radio {
  .Mui-checked {
    padding: 9px !important;
  }

  .MuiRadio-root {
    padding: 9px !important;
  }
}

.search-id {
  border: 1px solid var(--gray6)  !important;
  border-radius: 7px;

  .MuiInputBase-colorPrimary {
    padding-right: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    padding: 3px !important;
  }
}

.message-search-input {
  .MuiInputBase-root {
    padding-left: 0px !important;
    border-radius: 8px;
  }
}

.campaign-place {
  ::placeholder {
    font-weight: normal !important;
  }
}

.public-DraftStyleDefault-block {
  font-weight: normal !important;
}

.checkbox-alignment .MuiCheckbox-root {
  padding: 0px !important;
}

.checkbox-alignment .MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--primary-color) !important;
  padding: 0px !important;
}
.shift-filter {
  .shift-filter-shadow {
    box-shadow: none !important;
  }
}

.joblists-score-align {
  .MuiPaper-root.MuiPopover-paper {
    box-shadow: none !important;
  }
}

.textEllipse-input .MuiOutlinedInput-input {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: block;
  width: 200px !important;
  padding-right: 34px !important;
}
.textEllipse-talent-search .MuiOutlinedInput-input {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: block;
}

.bluk-upload-daterangepicker {
  #reportrange {
    border: 1px solid #696871 !important;
    border-radius: 20px !important;
    padding: 6px 10px 6px 13px !important;
    text-transform: none !important;
    margin: 10px 8px 10px 0px !important;
    font-size: var(--font-size-14);
    color: #1F1F1F !important;
  }

  .date-range-value {
    padding: 8px 0px 8px 8px !important;
    position: relative !important;
  }

  .calendarElement {
    position: absolute !important;
    left: 50%;
    // transform: translateX(-50%);
    // top: 30%;
    border: 1px solid #ccc;
    z-index: 999;
    width: 450px;
    border-radius: 4px !important;

    .rdrDateDisplayWrapper {
      border-radius: 4px !important;
    }
  }
}

.exclusion1 {
  .exclusion {
    margin-top: 0px !important;

    input {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
}

.email-label {
  .MuiInput-root {
    .MuiInput-input {
      padding-top: 15px !important;
      padding-bottom: 0px !important;
      padding-left: 0px !important;
    }
  }

  .MuiInputLabel-root {
    color: #696871 !important;
    font-size: var(--font-size-14) !important;
    font-weight: var(--font-weight-400) !important;
  }
}

.request-switch-case {
  .MuiFormControlLabel-root {
    margin-right: 0px !important;
  }

  .MuiSwitch-root {
    width: 45px;
    height: 20px;
    overflow: hidden;
    padding: 0px 0px;

    .Mui-checked {
      color: #ffffff;
      padding: 1px 0px 0px 8px !important;

      .MuiSwitch-thumb {
        margin: 1px 0px !important;
      }
    }
    .MuiSwitch-thumb {
      margin: -3px 0px;
    }

    .MuiSwitch-track {
      /*  background-color: #002962;
          opacity: 1;*/
      background-color: $gray7 !important;
      border-radius: 20px;
      position: absolute;
    }

    .MuiSwitch-thumb {
      width: 16px;
      height: 16px;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      // margin: 5px 0;
      color: #ffffff;
      // margin-left: -4px;
      // margin-top: -4px;
    }
  }

  .MuiSwitch-switchBase {
    // padding: 5px 0 0 4px !important;
    // padding-top: 2px;
    // padding-bottom: 2px;
    // padding-left: 2px;
    padding: 5px 5px 5px 2px !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primary-color) !important;
    opacity: 1;
  }

  .MuiFormControlLabel-label {
    color: var(--primary-color);
    font-size: var(--font-size-14);
  }
}

/*switch-case-toggle*/

.switch-case-section {

  .MuiFormControlLabel-root {

    margin-right: 0px !important;

  }



  .MuiSwitch-root {

    width: 40px;

    height: 37px;

    overflow: hidden;

    padding: 8px 0px;



    .Mui-checked {

      color: #FFFFFF;

      padding: 10px 0px 0px 2px !important;

    }



    .MuiSwitch-track {

      /*  background-color: #002962;

        opacity: 1;*/

      background-color: var(--gray7) !important;

      border-radius: 20px;

    }



    .MuiSwitch-thumb {

      width: 17px;

      height: 17px;

      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);

    }

  }



  .MuiSwitch-switchBase {

    padding: 10px 0 0 2px !important;

  }



  .MuiSwitch-switchBase:hover {

    background-color: transparent !important;

  }



  .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {

    background-color: var(--primary-color)  !important;

    opacity: 1;

  }



  .MuiFormControlLabel-label {

    color: var(--primary-color);

    font-size: var(--font-size-14);

  }

}



// Switch case toggle Scoring modal
.switch-case-section-scoringModal {
  .MuiFormControlLabel-root {
    margin-right: 0px !important;
  }

  .MuiSwitch-root {
    width: 40px;
    height: 37px;
    overflow: hidden;
    padding: 8px 0px;

    .Mui-checked {
      color: #FFFFFF;
      padding: 10px 0px 0px 2px !important;
    }

    .MuiSwitch-track {
      /*  background-color: #002962;
        opacity: 1;*/
      border-radius: 20px;
    }

    .MuiSwitch-thumb {
      width: 17px;
      height: 17px;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  }

  .MuiSwitch-switchBase {
    padding: 10px 0 0 2px !important;
  }

   .MuiSwitch-switchBase:hover {
    background-color: none !important;
  }

  .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: var(--primary-color)  !important;
    opacity: 1;
  }

  .MuiFormControlLabel-label {
    // color: #445bc7;
    color: var(--primary-color);
    font-size: var(--font-size-14);
  }
}

.select-all-pool {
  .MuiFormControlLabel-label {
    color: var(--gray7)  !important;
    font-size: var(--font-size-12) !important;
    font-weight: var(--font-weight-400) !important;
  }
}
.select-pool-request {
  .MuiFormControlLabel-label {
    color: var(--gray7)  !important;
    font-size: var(--font-size-14) !important;
    font-weight: var(--font-weight-400) !important;
  }
}

.matching-search {
  margin: 0px !important;
  background-color: #ffffff !important;

  #globalSearch {
    background-color: #ffffff !important;
  }
}

.action-filter {
  //border-right: 1px solid #002962 !important;
  padding: 12px 5px 12px 10px !important;
}

.header-search-bar {
  position: absolute;
  top: 46px;
  width: 57%;
}

.talent-pool-search {
  position: absolute;
  width: 35%;
  top: 590px;
  z-index: 999999;
}

.add-pool-dropdown {
  position: absolute;
  width: 85%;
  top: 253px;
  z-index: 999999;
}

.candidate-talent-pool-search {
  position: absolute;
  width: 15%;
  top: 168px;
  z-index: 999999;
}

.invite-talent-pool-search {
  position: absolute;
  width: 50%;
  top: 303px;
  z-index: 999999;
}

.candidate-search {
  .MuiFormControl-root {
    // border-radius: 8px !important;
    .MuiOutlinedInput-root {
      // background-color: #FFFFFF!important;
      // border: 1px solid #EAE9F2 !important;
      input {
        // background-color: #FFFFFF!important;
      }
    }
  }
}

.talentPool-list {
  .MuiMenu-list {
    height: 100px !important;
  }

  .MuiPaper-root {
    height: 100px !important;
  }
}

.request-auto-count {
  width: 40px !important;
  height: 40px;
  padding: 0px !important;
  .MuiInput-input {
    text-align: center !important;
  }
}

.shortlist-align {
  .MuiFormControlLabel-label {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
}

.CircularProgressbar .CircularProgressbar-text {
  dominant-baseline: middle !important;
  text-anchor: middle !important;
}

.candidate-lead-align {
  .MuiSelect-select {
    height: 0px !important;
    align-items: center !important;
    // color: var(--heading-text-color) !important;
    color: $gray9 !important;
  }
}

.lead-shortlist {
  .MuiButton-root {
    font-family: var(--font-family) !important;
  }
}

.access-input-align {
  .MuiFormControl-root {
    .MuiOutlinedInput-notchedOutline {
      position: absolute !important;
    }

    input {
      padding-right: 7px !important;
      padding-left: 12px !important;
    }
  }
}

.invite-edit-bar {
  .wrapper-class {
    .rdw-editor-toolbar {
      display: none !important;
    }
  }
}

.inputallowNumber {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

.MuiInputBase-colorPrimary {
  &.Mui-disabled {
    background-color: $gray3 !important;
  }
}

.checklist-category {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      &.Mui-disabled {
        background-color: $gray3 !important;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.new-search {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      padding: 0px !important;
    }
  }
}

.filter-payrate {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      background: rgba(234, 234, 234, 0.6) !important;
      border-radius: 5px !important;
      padding: 3px 0px 3px 4px !important;
      text-align: center !important;
      color: var(--gray9)  !important;
      font-size: var(--font-size-12) !important;
      font-weight: var(--font-weight-600) !important;
    }
  }
}

.radio-color {
  .MuiFormControlLabel-root {
    .MuiFormControlLabel-label {
      font-size: var(--font-size-14) !important;
      font-weight: var(--font-weight-400) !important;
      color: var(--gray10)  !important;
    }

    .Mui-checked {
      color: var(--primary-color) !important;
    }
  }
}

.job-share {
  .shareJob {
    padding: 0px !important;
    justify-content: end !important;

    svg {
      background: var(--secondary-color);
      border-radius: 8px;
      padding: 11px 10px 10px 10px;
      width: 40px;
      height: 40px;
    }
    &:hover {
      svg {
        background-color: var(--hover-button-color) !important;
      }
      svg > path {
        stroke: var(--hover-button-text-color) !important;
      }
    }
  }
}

.matching-view {
  .matching-list-view {
    .list-matching {
      // padding-bottom: 20px !important;
    }
  }
}

.relevance-align {
  font-size: var(--font-size-14) !important;

  &.Mui-selected {
    color: var(--primary-color) !important;
    background-color: var(--secondary-color) !important;
    font-weight: var(--font-weight-600) !important;
    font-size: var(--font-size-14) !important;
  }

  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 5px 20px 5px 5px !important;
      align-items: center;
      display: flex;
      // margin-top: 2px;
      color: var(--gray7)  !important;
      font-size: var(--font-size-16) !important;
      font-weight: normal !important;
    }

    .MuiSvgIcon-root {
      right: 0px !important;
    }
  }
}

.offerPayRateAlign {
  .payRatePaddingBottom {
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding-bottom: 7px !important;
      }
    }
  }
}

.job-menu {
  .jobdetail-menu {
    align-items: flex-start !important;
  }

  .detail-cta-icon {
    background: var(--secondary-color);
    border-radius: 8px;
    padding: 6px;
    width: 40px;
    height: 40px;
    &:hover {
      background-color: var(--hover-button-color) !important;
      .svg-icons > path {
        stroke: var(--hover-button-text-color) !important;
      }
    }
  }
}

.global-serach-table.MuiPaper-root {
  border: none !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}

.global-job {
  .jobdetail-menu {
    align-items: flex-start !important;
  }

  .detail-cta-icon {
    background: var(--secondary-color);
    border-radius: 4px;
    padding: 4px;
    width: 40px;
    height: 30px;
    &:hover {
      background-color: var(--hover-button-color) !important;
      .svg-icons > path {
        stroke: var(--hover-button-text-color) !important;
      }
    }
  }
}

.DraftEditor-editorContainer {
  font-size: var(--font-size-14) !important;
  font-family: var(--font-family)  !important;
  color: var(--gray9)  !important;
  font-weight: var(--font-weight-400) !important;
}

.textarea-input {
  .MuiInputBase-multiline {
    padding: 0 !important;
  }
}

input:focus-visible {
  outline: none !important;
}

.autocomplete-text-area {
  background: $bg-white  !important;
  border: 1px solid var(--gray6)  !important;
  border-radius: 6px !important;
  margin-top: 2px !important;
}

// autocomplet dropdown color
.autocomplete-menu {
  font-weight: lighter;
}
.body-container {
  max-width: 1400px !important;
  margin: 0 auto !important;
}

.endDateHighlight.input-group {
  border: 1px solid var(--primary-color) !important;
}
.chip-style {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  border-radius: 5px !important;
}
.choose-special {
  .MuiFormGroup-root {
    .MuiFormControlLabel-root {
      .MuiFormControlLabel-label {
        white-space: break-spaces;
      }
    }
  }
}
.switch-position {
  .MuiSwitch-switchBase {
    position: absolute !important;
    color: #ffffff !important;
    padding: 9px;
  }
}
.radio-check-position {
  .MuiFormControlLabel-root {
    .MuiRadio-root,
    .MuiCheckbox-root {
      padding: 9px !important;
    }
  }
}
.bionic-count {
  border: 1px solid #D0D5DD;
    border-radius: 2px;
    background: none !important;
    padding: 7px;
    color: #344054;
    font-weight: var(--font-weight-400) !important;
    font-size: var(--font-size-12) !important;
}
.bionic-rate {
  background: none !important;
  .MuiInputBase-input {
    color: #344054;
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-400);
    padding: 5px !important;
    text-align: center;
  }
  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: black !important;
  }
}
.budget{
  .form-control{
    margin-top: 5px !important;
  }
}
.pool-search{
  .MuiOutlinedInput-root{
    .MuiOutlinedInput-input{
      padding: 10px 8px !important;
    }
  }
}
jobWizard-radio {
  .MuiFormControlLabel-root {
    .MuiRadio-root {
      padding: 9px !important;
      color: #98a2b3 !important;

      &.Mui-checked {
        color: var(--primary-color) !important;
      }
    }
  }
}
.interview-date-card{
  border: 1px dotted $gray7 !important;
  border-radius: 6px;
  width:250px;
  .form-control.input-group{
    padding:3px !important;
    margin-top: 0px !important;
  }
  .date-picker-align{
    div{
      padding-top: 0px !important;
    }
  }
}
.deadline-min{
  .MuiOutlinedInput-root{
    padding-right: 0px !important;
  }
  .MuiOutlinedInput-notchedOutline{
    display: none !important;
  }
}
.interview-panel{
.panel-select{
  .MuiSelect-select{
    padding: 6px 4px !important;
    display: block !important;
  }
}
}
.search-panel{
  .MuiInputBase-root{
    padding-left: 0px !important;
  }
}
.panel-checkbox{
  .MuiCheckbox-root{
    padding: 2px 9px 9px 9px !important;
  }
  align-items: center !important;
}


.web-conference{
  .form-control.input-group{
    background-color: $gray3 !important;
    .MuiSelect-select{
      background-color: $gray3 !important;
    }
  }
}
.MuiPickersPopper-root {
  ::-webkit-scrollbar {
    width: 5px !important;
  }
  .MuiPaper-root {
    box-shadow: 0px 12px 20px rgba(216, 216, 216, 0.5);
    border-radius: 4px;
    .MuiMultiSectionDigitalClock-root {
      border-bottom: 1px solid $gray4 !important;
      .MuiList-root {
        width: 51px !important;
        .MuiMenuItem-root {
          font-size: 14px;
          padding: 5px 0px;
          margin: 0px;
          &:hover {
            background-color: var(--secondary-color);
          }
          &.Mui-selected {
            background-color: var(--primary-color);
          }
        }
      }
    }
    .MuiDialogActions-root{
      button{
        background-color: var(--primary-color);
        font-size: 14px;
        color: var(--gray1);
        font-weight: 600;
        padding: 8px 28px;
        border-radius: 6px;
        min-height: 10px;
        height: 30px;
        width: 30px;
      }
    }
  }
}

.interview-questions {
  .MuiOutlinedInput-root {
    display: flex;
    align-items: center;
    padding: 0px !important;
  }
}
.search-align{
  .new-search{
      .clear-icon-align{
        margin-right: 10px !important;
        right: 0px;
        position: absolute;
      }
  }
}
.form-control{
  &.reason-reject{
    height: 60px;
    min-height: 60px;
    max-height: 200px;
    .MuiOutlinedInput-root{
      padding: 0px !important;
      .MuiOutlinedInput-input{
        background: none !important;
        padding: 0px !important;
      }
    }
  }
}
.interview-note{
  .review-candidate {
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        padding: 0px !important;
      }
    }
  }
}
.add-message-input{
    .MuiOutlinedInput-root{
      padding: 0px !important;
    }
}
.salary-input{
  .form-control{
      // background-color: $bg-grey !important;
      padding-bottom: 2px;
  }
}
.exercise-input {
  .MuiFormControl-root {
    padding:10px 12px !important;
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 0px 4px !important;
        overflow: auto !important;
        max-height: 100px;
      }
    }
  }
}
.pay-rate-input{
  .MuiSelect-select{
    min-width: 40px !important;
  }
}
.payrate-closure{
  .usd-rate{
    .MuiSelect-select{
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    svg{
      right: 2px !important;
    }
  }
}
.offer-label{
  .MuiStepper-root{
    .MuiStepConnector-horizontal {
      max-width: 40px !important;
      min-width: 40px !important;
    }
    .MuiStepButton-root{
      .MuiSvgIcon-root{
        &.Mui-active{
          color: var(--primary-color) !important;
          .MuiStepIcon-text{
            fill: #FFFFFF!important;
          }
        }
        color: var(--secondary-color) !important;
        width: 40px !important;
        height: 28px !important;
        .MuiStepIcon-text{
          fill: #000 !important;
          font-size: 12px !important;
        }
      }
      .MuiStepLabel-root{
        .MuiStepLabel-iconContainer {
          padding-right: 2px !important;
         }
        .MuiStepLabel-label{
          color: #000;
          font-size: 14px;
          font-weight: 500;
          margin-right: 5px;
        }
      }
    }
    .MuiStepConnector-root{
      .MuiStepConnector-line{
        border-color: var(--secondary-color) !important;
        border-top-width:1.5px !important;
      }
      &.Mui-active{
        .MuiStepConnector-line{
          border-color: var(--primary-color) !important;
        }
      }
    }
  }
}
.expire-offer-onhold{
  .on-hold-offer{
    .bg-grey{
      background-color: #F2F4F7 !important;
      .Mui-disabled{
        color: var(--gray7)  !important;
        font-weight: 400 !important;
        opacity: 0.9 !important;
      }
    }
  }
}
.send-questions{
  .MuiInputBase-root{
    .MuiOutlinedInput-notchedOutline{
      padding: 0px !important;
    }
  }
}
.payrate-closure{
  .form-control {
    &.input-group {
      margin-top: 0px !important;
    }
  }
}
.tp-align{
  .question-align{
    // margin: 0px !important;
  }
  .tp-input{
    .MuiOutlinedInput-root{
        width: 100% !important;
    }
  }
  .MuiSelect-select{
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    white-space: nowrap;
  }
}
.closure-revamp{
  position: absolute;
  left: 24px;
  right: 24px;
  margin-top: 58px;
  .closure-status{
    color: $gray9;
    font-size: 12px;
    font-weight: 400;
    padding: 7px;
    text-align: center;
    border-radius: 8px;
  }
}
.share-job-align {
  .MuiFormControl-root {
    .MuiInputBase-root {
      .MuiInputBase-input {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        width: 190px;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding: 10px 0px !important;
      }
    }
  }
}
.closure-revamp{
  position: absolute;
  left: 24px;
  right: 24px;
  margin-top: 65px;
  .closure-status{
    color: $gray9;
    font-size: 12px;
    font-weight: 400;
    padding: 7px;
    text-align: center;
    border-radius: 8px;
  }
}
.share-copied{
  .MuiInputBase-root{
    .MuiOutlinedInput-input{
      padding-left: 0px !important;
    }
  }
}
.filter-date{
  .form-control.input-group{
    padding: 12px 16px !important;
  }
}
.clear-date{
  position: absolute;
  right: 25px;
  bottom: 15px;
}
.daterangepicker{
  position: absolute;
  top:113px !important;
  z-index: 9999 !important;
}
.daterangepicker::before{
  border-top: 7px solid #ccc !important;
  top: 279px !important;
  border-bottom: transparent !important;
}
.daterangepicker::after{
  border-bottom: transparent !important;
}
.daterangepicker .drp-calendar.left{
  padding-left: 0px !important;
  min-width: 240px !important;
}
.daterangepicker .drp-calendar.right{
  padding-right: 0px !important;
  min-width: 240px !important;
}
.daterangepicker td.start-date, .daterangepicker td.end-date{
  border-radius: 4px !important;
}
.daterangepicker td.in-range.active{
  background-color: var(--primary-color) !important;
}
.custom-no-options{
    background-color: #ffffff !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
    z-index: 999999999 !important;
    position: absolute !important;
    top: -10px !important;
    left: 0px !important;
    right: 0px !important;
    margin: 0px 20px !important;
    width: auto !important;
    border-radius: 4px;
  }
  .parent-container{
    div{
      width: -webkit-fill-available;
    }
    .form-control.input-group{
      .MuiInputBase-root{
        padding-right: 0px !important;
        .MuiInputAdornment-root{
          width: auto !important;
        }
      }
    }
}
.refer-upload-field{
  .form-control {
    &.input-group{
        padding-right: 0px !important;
    }
  }
  .MuiOutlinedInput-root {
    padding-right: 0px !important;
  }
}
.new-search {
  margin: 0px !important;
  .MuiOutlinedInput-root {
    .MuiInputBase-input{
      padding: 10px!important;
      background-color: #FFFFFF !important;
      border-radius: 6px !important;
    }
    .MuiOutlinedInput-notchedOutline {
      padding: 0px !important;
    }
    .Mui-disabled {
      background-color: #F2F4F7 !important;
    }
  }
}
.project-search{
  .new-search {
    .MuiOutlinedInput-root {
      .MuiInputBase-input{
        padding: 12px!important;
      }
    }
  }
}
.search-id {
  border: 1px solid var(--gray6)  !important;
  border-radius: 7px;
  .MuiInputBase-colorPrimary {
    padding-right: 0 !important;
  }
  .MuiOutlinedInput-notchedOutline {
    padding: 3px !important;
  }
}
.search-console {
  .MuiInputBase-colorPrimary {
    background-color: white;
  }
}
.candidate-list-align {
  .MuiSelect-select {
    display: flex !important;
  }
  .MuiSelect-icon {
    font-size: 23px !important;
  }
}
.companiesFilter {
  background: #eff0f6;
  border-radius: 7px;
  padding: 8px 10px;
  margin: 4px 4px 4px 0px;
  color: #1f1f1f;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  display: inline-block !important;
}
.refer-filter-reset{
  position: fixed;
    bottom: 0px;
    right: 40px;
    width: 56%;
    background-color: white;
    padding-bottom: 20px !important;
}
.cloak-align{
  .MuiInputBase-root{
    padding-right: 0px !important;
    .MuiInputAdornment-root{
    justify-content: end !important;
    }
  }
}
.default-base{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
#menu-base-theme-select{
  .MuiPaper-root.MuiPopover-paper{
    border: 1px solid $gray5 !important;
    .MuiMenuItem-root{
      margin-top: 4px !important;
    }
  }
}
.base-theme {
  .select-theme {
    .MuiSelect-select {
      padding: 5.6px 6px !important;

      .default-base {
        display: none;
      }
    }
  }
}
.schedule-time{
  .MuiInputBase-root{
    .MuiInputBase-input{
      min-width: 80% !important;
    }
  }
}
.user-type-align{
  .MuiInputBase-root {
    .MuiSelect-select{
      padding-right: 10px !important;
    }
  }
}
.refresh-job{
  right: 30px;
  position: fixed;
  top: 85px;
  width: 305px;
  z-index: 99999 !important;
  padding: 14px;
  background-color: #E3FCEF;
  color: #10171D;
  font-family: var(--font-family) !important;
  font-size:var(--font-size-14) !important;
  transition: opacity 0.3s ease-in-out;
  border-radius: 4px;
}
.refresh-job.hidden {
  opacity: 0;
}
.add-theme-img{
  svg:focus{
    outline: none !important;
  }
}
.options-align{
  .share-job{
    .MuiButton-root{
      min-width: 50px !important;
      margin-right: 2px;
    }
  }
}
.skills-weightAge-input {
  .form-control {
    &.input-group {
      margin-top: 10px !important;
      .MuiSelect-select{
        padding: 6px 4px !important;
      }
    }
  }
}
.refer-align-list{
  position: absolute;
  margin-top: 25px !important;
}
.refer-align-list-talentPool{
  position: absolute;
  margin-top: 5px !important;
}
.reference-input {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      background: $bg-white  !important;
      border: 1px solid var(--gray6)  !important;
      border-radius: 6px !important;
    }
}
}
.createProfile-code-referral {
  .MuiFormControl-root {
    padding: 0px !important;
}
}
.icon-positioning-onboard {
  .MuiFormControl-root{
    .MuiAutocomplete-endAdornment{
      margin-top: 3px !important;
      right: 3px !important;
    }
  }
}
.stop-criteria-img{
  padding: 11px;
  border: 2px solid #B42318;
  border-radius: 6.63px;
  background: #FEF3F2;
}
.choose-option-img{
  padding: 11px;
  border: 1.3px solid #B1B1B1;
  border-radius: 6.63px;
}
.exp-align-create{
  .form-control {
    &.input-group {
      margin-top: 8px !important;
      margin-bottom: 0px !important;
    }
  }
}
.location-switch {
  .MuiSwitch-root{
    margin-top: -6px !important;
  }
}
.MuiSelect-select{
  #select-none {
    display: none !important;
  }
}
#menu-answer-type-menu{
  .MuiPaper-root {
    width: 250px !important;
  }
}
.location-slider{
  .MuiSlider-valueLabel{
    background-color: transparent !important;
    top: 0px !important;
    .MuiSlider-valueLabelLabel{
      color: var(--primary-color) !important;
      font-weight: 600 !important;
      font-family: var(--font-family) !important;
    }
  }
}
.mile-km-align{
  &.mile-range{
    width: 48px !important;
    padding-left: 2px !important;
  }
}
.location-miles{
  .MuiInputBase-root{
    .Mui-disabled{
      -webkit-text-fill-color: var(--gray9)  !important;
    }
  }
}
.referree-name{
  .referree-input{
    &.form-control.input-group{
      .MuiInputBase-root{
        border-radius: 6px !important;
        .MuiInputBase-input{
          padding: 12px 16px !important;
        }
      }
    }
  }
}
.saved-input{
  .form-control.input-group{
    padding: 0 !important;
    .MuiInputBase-root{
      .MuiInputBase-input{
        padding: 11px 16px !important;
      }
    }
  }
}
.duration-select-input.form-control.input-group .MuiSelect-select{
  padding: 4.5px 4px !important;
}
.jobRef-disabled.form-control.input-group {
  background-color: #F2F4F7 !important;
}
.candidate-cta-align{
  .matching-menu .menus{
    padding:6px 8px !important
  }
}
.search-candidate{
  .MuiInputBase-root{
    .MuiInputBase-input{
      padding:10px 8px !important;
    }
  }
}

.pay-rate-input-profile{
  .MuiSelect-select{
    min-width: 30px !important;
    padding-right: 16px !important;
  }
}
.nationality-align {
  .MuiAutocomplete-root {
    padding: 6px 12px !important;
  }
}
.exp-skills {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      position: relative !important;
      margin: -8px !important;
    }
  }
}
.onboard-skills {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      padding: 10px 12px !important;
      background: $bg-white  !important;
      border: 1px solid var(--gray6)  !important;
      border-radius: 6px !important;
      width: 100%;
      font-size: var(--font-size-14) !important;
      // margin-top: 7px !important;
    }
  }
  .is-invalid-parsed-skill {
    .MuiOutlinedInput-input {
      &.MuiInputBase-input {
        border: 1px solid #B42318 !important;
      }
    }
  }
}
.date-of-birth {
  .form-control {
    &.input-group {
      margin-top: 5px !important;
    }
  }
}
.skill-add {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      margin-top: 5px !important;
    }
  }
}
.skill-width {
  .MuiInputBase-root {
    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        padding: 0px !important;
      }
      top: calc(50% - 9px) !important;
      svg {
        right: 0px !important;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      display: none !important;
    }
  }
}
.lastUsedDateField {
  background-color: $bg-white  !important;
  border: 1px solid var(--gray6)  !important;
  width: 100%;
  border-radius: 6px !important;
  font-size: var(--font-size-14) !important;
  margin-top: 5px !important;
  border: none;
  // height: 44px;
  padding: 10px 12px !important;
  background-image: none !important;

  .MuiInputBase-input {
    padding: 0px !important;
  }
}
.select-option-section {
  .MuiSelect-select {
    padding: 9px 8px !important;
  }
}

.select-option-section {
  .MuiSvgIcon-root {
    font-size: 13px !important;
  }
}
.select-option-section-reference {
  .MuiSelect-select {
    padding: 9px 8px !important;
  }
  .MuiSvgIcon-root {
    font-size: 14px !important;
  }
  .MuiIconButton-root {
    font-size: 14px !important;
    margin-top: 3px !important;
  }
}
// .select-option-section {
//   margin-top: 8px !important;
//   height: calc(1.5em + 0.75rem + 8px);
//   padding-bottom: 7px !important;
// }
.pay-rate-input-profile{
  .MuiSelect-select{
    min-width: 30px !important;
    padding-right: 16px !important;
  }
}
.MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.addree-profile{
  .address-input{
    .MuiInputBase-root{
      .MuiInputBase-input{
        padding: 4.3px !important;
      }
    }
  }
}
.work-location {
  .form-control.input-group {
    margin-top: 0px !important;
  }
}
.profile-essentials {
  overflow-y: auto;
  min-height: 100px;
  max-height:300px;
}
.MuiAutocomplete-popper {
  z-index: 99999 !important;
}
.question-list{
  height: 156px;
  overflow-y: auto;
}
.screening-label{
  .MuiInputBase-root{
    .MuiInputBase-input{
    padding-left: 0px !important;
    padding-bottom: 8px !important;
    &:hover{
      border-bottom: 1px solid $gray5;
      border-radius: 0px !important;
      padding-left: 0px !important;
      padding-bottom: 8px !important;
    }
    }
  }
}
.screening-label-active{
  .MuiInputBase-root{
    .MuiInputBase-input{
      border-bottom: 1px solid $gray7;
      border-radius: 0px !important;
      padding-left: 0px !important;
      padding-bottom: 8px !important;
    }
  }
}
.screening-label-error{
  .MuiInputBase-root{
    .MuiInputBase-input{
      border-bottom: 1px solid $danger-color;
      border-radius: 0px !important;
      padding-left: 0px !important;
      padding-bottom: 8px !important;
    }
  }
}
.screening-label-disabled{
  .MuiInputBase-root{
    .MuiInputBase-input{
      border-radius: 0px !important;
      padding-left: 0px !important;
      padding-bottom: 8px !important;
    }
  }
  .MuiInputBase-input.Mui-disabled {
    background-color: #FFFFFF !important;
  }
    
}
.screening-input{
  .MuiInputBase-root{
    min-width: 100%;
    display: flex !important;
    align-items: center !important;
  }
}
.screening-text-type {
  .MuiInputBase-root{
    min-width: 100% !important;
    padding: 2px 2px !important;
  }
  ::placeholder {
    color: var(--gray7)  !important;
    font-weight: normal !important;
    opacity: 0.6 !important;
  }
}
.screening-auto{
  .MuiAutocomplete-root{
    width: 100%;
  }
}
.screening-question-view{
  height: 420px;
  overflow-y: auto;
}
.screening-picker{
  .form-control-background-gray.input-group{
    background-color: #FFFFFF !important;
    .MuiFormControl-root{
      width: 90% !important;
      .MuiInputBase-input{
        opacity: 1 !important;
        -webkit-text-fill-color: var(--gray7)!important;
        font-family: $font-family !important;
      }
    }
  }
}
.screening-preview {
  overflow-y: auto;
  height: 450px;
}
.input-height {
  .MuiInputBase-root {
    height: 30px;
  }
}
.phone-input-padding {
  .form-control.input-group {
    padding: 7.5px 12px !important;
  }
}
.country-code-border-radius {
  .MuiInputBase-root.Mui-disabled {
    border-radius: 6px;
  }
}

.smart-filter-no-jobs {
  border: 2px dashed $gray6 !important;
  border-radius: 10px;
}
.options-align{
  .MuiPaper-root{
    .MuiMenuItem-root{
      display: flex !important;
      justify-content: flex-start !important;
      padding: 6px 16px !important;
    }
  }
}
.cross-profile{
  .profile{
    padding-left: 0px !important;
  }
}