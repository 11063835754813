.ccard-main {
  width: 40rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}

.ccard {
  width: 20rem;
  height: 20rem;
  background: rgb(109, 148, 255);
  border-radius: 5px;
  transition: box-shadow 0.5s;
  will-change: transform;
}

@media (min-width: 1200px) {
  .card-container {
    padding: 0 5em;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .responsive-img {
    width: 35% !important;
  }
  .responsive-header {
    margin-left: -7em !important;
    padding-right: 10px !important;
  }
  .responsive-header-text {
    font-size: var(--font-size-12) !important;
  }
}

.navbar-fixed {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  background: $bg-white;
  // padding: 8px 36px;
  border-bottom: 1px solid var(--gray3);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
}
.navbar-fixed-loggedout {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  background: $bg-white;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid var(--gray3);
  // box-shadow: 0px 2px 4px rgba(213, 213, 213, 0.75);
}

.background-section {
  background-image: url("/assets/images/profile-bg.png");
  width: 100%;
  background-size: cover;
  height: 150px;
  background-position: center;
  .bg-edit {
    justify-content: flex-end;
    display: flex;
    .edit-icon {
      border-radius: 50%;
      margin: 20px;
      padding: 8px;
      width: 35px;
      height: 35px;
      background-color: #ffffff;
      color: #696871;
    }
  }
}
// .profile-pic {
//   margin-left:  15px !important;
// }
.user-pic-block {
  .profile-pic {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .profile {
      padding: 3px;
      background-color: #fff;
      /*border: 1px solid #dee2e6;*/
      width: 100px;
      height: 100px;
      /*border-radius: 50%;*/
    }
    .profile-file-upload {
      position: absolute;
      display: none;
    }
  }
}

.user-pic-block {
  .profile-pic {
    &:hover {
      .profile-file-upload {
        display: block;
      }
    }
  }
}

.successIcon {
  border-radius: 50%;
  padding: 8px;
  width: 45px !important;
  height: 45px !important;
  color: #ffffff;
  background-color: #22a640;
}
.user-pic-block {
  .profile-pic {
    &:hover {
      .profile-file-upload {
        display: block;
        position: absolute;
      }
    }
  }
}
.user-pic-block {
  .profile-pic {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .profile {
      padding: 3px;
      background-color: #fff;
      /*border: 1px solid #dee2e6;*/
      width: 100px;
      height: 100px;
      /*border-radius: 50%;*/
    }
    .profile-file-upload {
      position: absolute;
      display: none;
    }
  }
}

.video-image {
  object-fit: cover;
}

.back-button {
  svg {
    font-size: 13px;
  }
}
.enabled-pool {
  background: #ffffff;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25);
  border-radius: 8px;
  padding: 15px;
  min-height: 130px;
}
.disabled-pool {
  background:  #F2F4F7;
  // box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12), 0px 10px 32px -4px rgba(90, 136, 193, 0.12);
  border: 1px solid #E4E7EC;
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25);
  border-radius: 8px;
  padding: 15px;
  min-height: 130px;
  color: #696871;
}
.updated-card{
  border-radius: 0px 0px 7px 7px;
  border-top: 1px solid #E4E7EC;
}
.talent-pool-card {
  border-radius: 50%;
  background: rgba(0, 37, 133, 0.2);
  display: inline-flex !important;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
}
.create-pool-details {
  background: #ffffff;
  border: 1px solid #eae9f2;
  box-shadow: 0px 6px 14px rgba(90, 136, 193, 0.12),
    0px 10px 32px rgba(90, 136, 193, 0.12);
  border-radius: 10px;
  // padding:30px;
}

.shadow-new {
  border: 1px solid #e4e7ec !important;
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25) !important;
}
.choose-video {
  background: #f9fafb;
  border: 1px dashed #98a2b3;
  border-radius: 8px;
}
.check-all {
  .MuiFormControlLabel-label {
    color: var(--gray7) !important;
    font-size: var(--font-size-12) !important;
    font-weight: var(--font-weight-400) !important;
  }
}
.candidate-pool-card {
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25);
  background: #f8f9fc;
  border-radius: 8px;
}
.candidate-pool-icon svg {
  // background: #6941C6;;
  padding: 7px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.add-to-pool {
  background: #f8f9fc;
  border-top: 1px solid #e4e7ec;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.lazy-loader2 {
  position: relative;
  background-color: #FFF;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  padding: 20px;
.shimmerBG {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}
@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}
.media {
  height: 165px !important;
}
.p-32 {
  padding: 32px;
}
.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.mx-32 {
  margin-left: 40px;
  margin-right: 40px;
}
.icon{
  width: 200px !important;
  height: 85px !important;
}
.title-line {
  height: 24px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 20px;
}
.title-line-small {
  height: 24px;
  width: 50%;
  margin-bottom: 12px;
  border-radius: 20px;
}
.content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}
.end {
    width: 40%;
  }
.mid {
    width: 20%;
}

}
.lazy-loader {
  position: relative;
  // -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  // box-shadow: 2px 4px 8px 5px rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  background-color: #FFF;
  border-radius: 3px;
  // height: 100vh;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  padding-bottom: 20px;
  padding-top: 30px;

.shimmerBG {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}
@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}
.media {
  height: 165px;
}
.p-32 {
  padding: 32px;
}
.mx-8{
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx-16{
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.px-8{
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.px-16{
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.title-line {
  height: 16px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 20px;
}
.content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
}
.end {
    width: 40%;
  }
.img {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
}
.cross-img {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
}
}
.time-line-border{
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  border-radius: 7px;
}
.tp-side-card{
  color:green !important;
  padding: 10px;
  border: 1px solid #E4E7EC;
  border-radius: 4px;
  height: 80px;
}
.tp-border{
  color: #E4E7EC !important;
  border: 1px solid #E4E7EC;
  border-width: 0.1px;
  width: 0.1px;
  margin-left: 20px;
  height: 50px;
}
.tp-card-img{
  svg{
    padding: 9px;
    border-radius: 50%;
  }
}
.interview-notes-box{
  padding: 16px;
  border-radius: 6px;
  background-color: var(--gray3);
}
.interview-notes-textarea{
  padding: 0px 6px !important;
}
.generate-ai{
  height: 230px;
  overflow-y: scroll;
   // overflow: auto
}
.cross-selling{
  height: 183px;
  overflow: auto
}
.week{
  .week-day{
    margin-right: 20px;
    padding: 10px;
    border-radius: 50%;
    width: 35px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 35px;
    font-size: 14px;
  }
}