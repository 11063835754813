@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF !important;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: var(--font-family) !important;
}

.report-style-class {
  height: 100vh;
}

iframe{
  border: none;
}

.MuiFormControlLabel-label{
  font-size:14px !important;
  color: #667085 !important;
  margin-left: 2px !important;
}

.css-eg0mwd-MuiSlider-thumb:hover,.css-eg0mwd-MuiSlider-thumb:focus,.css-eg0mwd-MuiSlider-thumb:active{
  box-shadow:none !important;
}

.explorationContainer{
  .exploreCanvas{
    .canvasFlexBox{
      .displayAreaContainer{
        .displayArea{
          height: 100% !important;
        }
      }
    }
  }
}    
.displayArea{
  .disableAnimations{
    .fitToPage{
      height: 100% !important;
    }
  }
}  
.displayArea{
  height: 100% !important;
}