.align-between {
  justify-content: space-between;
  display: flex;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
  display: -webkit-flex !important;
}

.d-block {
  display: block !important;
}
.d-inline-block{
  display: inline-block
}
.justify-end {
  justify-content: end !important;
  -webkit-justify-content: end;
}

.justify-around {
  justify-content: space-around !important;
}

.d-grid {
  display: grid !important;
}

.d-content {
  display: contents !important;
}

.d-inherit {
  display: inherit;
}

.d-none {
  display: none;
}

.error-msg {
  font-size: var(--font-size-12) !important;
  line-height: 15px;
  display: block;
  white-space: initial;
}

.copywrite {
  position: absolute;
  bottom: 10px;
  right: 210px;
}

.sub-info li {
  list-style: none;
  padding: 7px 0px;
}
.w-3cent{
  width: 3%;
}
.w-5cent{
  width: 5%;
}
.w-6cent{
  width: 6%;
}
.w-8cent{
  width: 8%;
}
.w-10cent{
  width: 10%;
}
.w-12cent{
  width: 12%;
}
.w-13cent{
  width: 13%;
}
.w-14cent{
  width: 14%;
}
.w-20cent{
  width: 20%;
}
.w-70cent{
  width: 80%;
}
.w-80cent{
  width: 80%;
}
.min-w-110{
  min-width: 110px !important;
}
.min-w-115{
  min-width: 115px !important;
}
.min-w-130{
  min-width: 130px !important;
}
.w-50cent{
  width: 50% !important;
}
.w-50{
  width: 50px !important;
}
.w-30{
  width: 30px !important;
}
.w-81{
  width: 81px;
}
.w-101{
  width: 101px;
}
.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90px !important;
}
.w-85cent{
  width: 85% !important;
}
.w-110{
  min-width: 100px;
  max-width: 110px;
  //width: 110px;
}
.w-115{
  width: 115px;
}
.w-120{
  width: 120px !important;
  height: auto !important;
}
.w-140{
  width: 140px !important;
}
.w-150 {
  width: 150px;
}
.w-155 {
  width: 155px;
}
.w-170 {
  width: 170px;
}
.w-165 {
  width: 165px;
}
.w-180 {
  width: 180px;
}

.w-200 {
  width: 200px;
}

.w-250 {
  width: 250px;
}
.w-290{
  width: 290px;
}
.w-300 {
  width: 300px;
}
.w-320 {
  width: 320px;
}
.w-400{
  width: 400px;
}
.w-450 {
  width: 450px;
}
.w-500 {
  width: 500px;
}
.w-35 {
  width: 35%;
}
.left-44{
  left: 44px !important;
}
.list-none {
  list-style: none;
}

.navBar ul {
  li {
    list-style: none;
    padding-right: 25px;
  }

  a {
    text-decoration: none;
    color: #000000;
  }
}

.footerNav ul {
  li {
    list-style: none;
    padding-top: 10px;
  }

  a {
    color: #262626;
    text-decoration: none;
    font-size: var(--font-size-14);
  }
}

.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left !important;
}

.align-right {
  text-align: right;
}

.textDecor {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.documents {
  a {
    text-decoration: underline !important;
  }
}

.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.h-auto{
  height: auto !important;
}
.h-24 {
  height: 24px !important;
}

.h-100 {
  height: 100vh;
}
.h-40 {
  height: 40%;
}
.cursor-normal {
  cursor: default !important;
}
.cursor-auto{
  cursor: auto !important;
}
.break-word {
  word-wrap: break-word !important;
}

.p-relative {
  position: relative;
}
.p-fixed {
  position: fixed;
}

.vertical-align-middle {
  vertical-align: middle !important;
}
.vertical-align-top {
  vertical-align: top !important;
}
.d-block {
  display: block;
}

.justify-left {
  justify-content: left;
}

.w-85 {
  width: 85px !important;
}

.justify-flex-end {
  justify-content: flex-end !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-dir {
  flex-direction: column;
}


.align-baseline {
  align-items: baseline !important;
}

.borderRadius-5 {
  border-radius: 5px;
}

.borderRadius-10 {
  border-radius: 10px;
}
.borderRadius-8 {
  border-radius: 8px;
}

.borderRadius-50 {
  border-radius: 50%;
}
.border-r-tb-0{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.border-l-tb-0{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.justify-between {
  justify-content: space-between !important;
  display: flex !important;
}

.break-all {
  word-break: break-all;
}

.d-inline {
  display: inline;
}

.word-space {
  word-spacing: -1px !important;
}

.enterprise-name-width {
  min-width: 86px !important;
  max-width: 150px;
}

.word-break {
  word-break: break-word !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.hov-underline {
  &:hover {
    text-decoration: underline !important;
  }
}

.vertical-baseline {
  vertical-align: baseline !important;
}

.vertical-bottom {
  vertical-align: bottom !important;
}

.height-100 {
  height: 100% !important;
}

.height-100v {
  height: 100vh !important;
}
.min-w-30{
  min-width: 30%;
}
.min-w-60{
  min-width: 60%;
}
.min-w-61{
  min-width: 61px;
}
.min-w-120{
  min-width: 120px !important;
}
.min-h-100 {
  min-height: 100% !important;
}

.min-w-14 {
  min-width: 14px !important;
}
.min-w-20{
  min-width: 20px;
}
.min-w-15{
  min-width: 15px;
}
.min-w-70 {
  min-width: 70px !important;
}
.min-w-100{
  min-width: 100px !important;
}
.min-w-100cent{
  min-width: 100% !important;
}
.max-w-290{
  max-width: 290px !important;
}
.max-w-500{
  max-width: 500px !important;
}
.min-w-140 {
  min-width: 140px !important;
}

.bulk-icon-align {
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.w-380px {
  width: 380px !important;
}

.d-inline-flex {
  display: inline-flex;
}

.w-max-content {
  width: max-content !important;
}
.min-w-max {
  min-width: max-content !important;
}
.formControlLabel {
  .MuiFormControlLabel-label {
    // margin-left: 10px !important;
    color: var(--gray10) !important;
    font-weight: var(--font-weight-400) !important;
  }
}

.formControlLabel {
  &.formControlLabel-checkbox {
    .MuiFormControlLabel-label {
      margin-left: 10px !important;
      color: var(--gray10) !important;
      font-weight: var(--font-weight-400) !important;
    }
  }
}
.border-radius-2 {
  border-radius: 2px;
}
.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-6 {
  border-radius: 6px;
}
.no-border {
  border-bottom: none !important;
}

.border-radius-8 {
  border-radius: 8px;
}
.font-family{
  font-family: var(--font-family) !important;
}
.top-0{
  top: 0 !important;
}
.w-130{
  width: 130px !important;
}
.w-135{
  width: 135px !important;
}
.object-fit{
  object-fit: cover !important;
}
.object-fit-contain{
  object-fit: contain !important;
}
.border-gray4{
  border: 1px solid #E4E7EC;
}
.border-error{
  border: 1px solid $danger-color !important;
}
.border-gray5{
  border: 1px solid $gray5;
}
.fit-content{
  width: fit-content !important;
}
.min-w-55{
  min-width: 55px !important;
}
.min-w-35{
  min-width: 35px !important;
}
.white-space{
  white-space: break-spaces !important;
}
.white-space-initial{
  white-space: initial !important;
}
.educationBullet .MuiSvgIcon-root {
  font-size: 7px !important;
  margin: 0px 2px 4px 0px;
  color: var(--gray7)
}

.educationScroll {
  max-height: 350px !important;
  overflow-y: auto;
}
.text-justify {
  text-align: justify;
}
.text-capitalize {
    text-transform: capitalize !important;
}
.w-135{
  width: 135px !important;
}
.min-w-content{
  min-width: fit-content !important;
}
.talent-pool-name{
  width: 120px;
}
.border-none{
  border: none !important;
}
.shadow-none{
  box-shadow: none !important;
}
.refer-candidate{
  padding: 10.5px 14px !important;
}
.secondary-bg-color-svg-icons {
  background-color: var(--secondary-color);
  &:hover{
    background-color: var(--hover-button-color) !important;
    color: var(--hover-button-text-color) !important;
    .svg-icons>path {
      stroke: var(--hover-button-text-color) !important;
    }
    .fill-svg-icons>path {
      fill: var(--hover-button-text-color) !important;
    }
  }
}
.job-log-shadow{
  .MuiPaper-root{
    box-shadow:0px 3px 8px rgba(149, 136, 136, 0.25) !important;
  }
}
.assess-update {
  .MuiPaper-elevation {
    min-width: 16ch !important;
    max-width: auto !important;
    left: 1109p !important;
  }
}
.text-decor-underline {
  text-decoration: underline;
}
.w-webkit{
  width: -webkit-fill-available !important;
}