.Toastify__toast-container{
  z-index: 999999 !important;
}

.Toastify__toast--warning {
  background: #FFE8BC !important;
}

.Toastify__toast--error {
  background: #DFBFC2 !important;
  color: #A70303 !important;
}

.toastBody {
  font-family: var(--font-family) !important;
  color: #10171D; /* #10171D */
  font-size:var(--font-size-14) !important;

}

.Toastify__toast-body{
  .Toastify__toast-icon {
    /*width: 0;*/
   /* background: url('/assets/images/clock.svg') center/contain no-repeat !important;*/
    /*svg{
      display: none;
    }*/
  }
}

.Toastify__toast--success{
  .Toastify__toast-icon {
    width: 20px;
    //height: 20px;
    height: var(--toast-image-height);
    background: url('/assets/images/toaster-icons/success-icon.svg') center/contain no-repeat !important;
    svg{
      display: none;
    }
  }
}

.Toastify__toast--warning{
  .Toastify__toast-icon {
    width: 20px;
    height: 20px;
    background: url('/assets/images/toaster-icons/warning-icon.svg') center/contain no-repeat !important;
    svg{
      display: none;
    }
  }
}

.Toastify{
  .Toastify__toast {
    font-family: var(--font-family) !important;
    &.Toastify__toast-theme--light{
      &.Toastify__toast--success{
        background-color: #E3FCEF;
        color: #1F1F1F;
        font-size: var(--font-size-14);
        /* z-index: 9999;*/
        /* opacity: 1 !important;
         z-index: 999999;*/
      }
    }
  }
}

.Toastify{
  .Toastify__toast {
    &.Toastify__toast-theme--light{
      &.Toastify__toast--warning{
        background-color: #FFEBE6;
        color: #1F1F1F !important;
        font-size: var(--font-size-14);
        /* opacity: 1 !important;
         z-index: 999999;*/
      }
    }
  }
}

.Toastify__close-button--light{
  display: none;
}

#alert-dialog-title{
  font-size: 20px !important;
  padding: 25px 20px 0 20px!important;
  color:  var(--gray9);
  font-weight: var(--font-weight-600);
  text-align: left;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#alert-dialog-description{
  font-size: 14px !important;
  padding: 15px 20px 0 20px ;
  color: var(--gray9);
}

.MuiPaper-root{
  .MuiDialogContent-root{
    padding: 20px 0;
  }
}


.MuiPaper-root {
  &.MuiPaper-elevation {
    &.MuiDialog-paper{
      min-width: 400px;
    }
  }
}


.confirm-link{
  &.dialog-primary{
    font-size: var(--font-size-16);
    padding:4px;
    color: var(--primary-color);
    text-transform: none;
    box-shadow: none;
    font-weight: var(--font-weight-600);
    border-radius: 8px;
    font-family: var(--font-family);
    height: 2em;
  }
  &.dialog-primary:hover{
    background: transparent !important;
  }
}

.confirm-link{
  &.dialog-secondary{
    color: var(--gray7);
    font-size: var(--font-size-16);
    padding:4px;
    text-transform: none;
    box-shadow: none;
    font-family: var(--font-family);
    height: 2em;
  }
  &.dialog-secondary:hover{
    background: transparent !important;
  }
}

.delete-link{
  &.dialog-primary{
    font-size: var(--font-size-16);
    padding:4px;
    text-transform: none;
    box-shadow: none;
    font-family: var(--font-family);
    height: 2em;
  }
  &.dialog-primary:hover{
    background: transparent !important;
  }
}



/*loader-css*/
.background-blur{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.loader{
  div{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    background-color: var(--loader-bg);
    /* z-index:999999;
     background-color: rgba(0, 0, 0, 0.1);
     opacity: 0.9;*/
    svg{
      width: 15% !important;
      height: 15% !important;
    }

  }
}

.Toastify__toast-container--top-right {
  top: 5em !important;
  right: 1em;
}
.MuiBadge-badge {
  background-color: #FFC6C6 !important;
  color: var(--gray9) !important;
  border-radius: 50% !important;
  min-width: 20px !important;
  min-height: 20px !important;
  padding: 2px 2px !important;
  font-size: var(--font-size-10) !important;
  cursor: pointer;
}

.notification-icon{
  .MuiSvgIcon-root{
    font-size: 25px !important;
  }
}

.ccard-main {
  width: 40rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}

.ccard {
  width: 20rem;
  height: 20rem;
  background: rgb(109, 148, 255);
  border-radius: 5px;
  transition: box-shadow 0.5s;
  will-change: transform;
}


@media (min-width: 1200px){
  .card-container{
    padding: 0 5em;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px){
  .responsive-img{
    width: 35% !important;
  }
  .responsive-header {
    margin-left: -7em !important;
    padding-right: 10px !important;
  }
  .responsive-header-text{
    font-size: var(--font-size-12) !important;
  }
}



.upload-resume{
  img{
    /*   color: #FF8080;
       background: rgba(255, 128, 128, 0.2);
       padding: 16px;
       border-radius: 50%;
       cursor: pointer;*/
  }
}

.profile-icon{
  img{
    /* height: 70px;
     width: 70px;*/
    color: #2E47CC;
    background: rgba(46, 71, 204, 0.2);
    padding: 16px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.upload-resume-section{
  svg{
    height: 18px;
    width: 18px;
    color:  var(--gray7);
    background: #FFFFFF;
    padding: 1px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 4px rgb(179 179 179 / 15%);
    margin-left: -14px;
    margin-bottom: 55px;
  }
}

.upload-resume-container{
  border: 1px dashed  var(--gray7);
  /*  width: 19em;*/
  padding: 30px 30px;
  height: 10em;
  margin: 25px;
  border-radius: 8px;
}

.upload-resume-preview{
  position: absolute;
  width: 90px;
  height: 90px;
  left: 590px;
  /* top: 250px;*/
  border: 1px solid #EAE9F2;
  box-sizing: border-box;
  border-radius: 50%;
  img{
    margin-top: 10px;
  }
  svg{
    position: absolute;
    left: 65.46%;
    right: 26.47%;
    top: -5.03%;
    bottom: 23.97%;
    background-color: #FFFFFF;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.back-button{
  svg{
    font-size: 13px;
  }
}

.tab-container{
  .MuiGrid-root{
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    display: grid;
  }
}

.create-skill-badge-block{
  .badge{
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: #262626;
    background-color: #F7F7FB;
    padding: .1rem .8rem;
    align-items: center;
    justify-content: center;
    margin: .1em .5em 0 0;
    line-height: 1.9;
    height: auto;
    display: inline-flex;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: .8rem;
    white-space: inherit;
    font-weight: normal;
  }
  .close{
    opacity: 0.6;
    font-size:  1.5em;
    color: #58595b;
    position: relative;
    z-index: 1;
    margin-top: 0;
    margin-left: 7px;
  }
}

.settings-layout{
  margin: 0 30px;
}

@media screen and (min-width: 300px) and (max-width: 767px){
  .settings-small-screen-resolution{

  }
  .settings-big-screen-resolution{
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1200px){
  .settings-big-screen-resolution{
  }
  .settings-small-screen-resolution{
    display: none;
  }
}
@media (min-width: 1200px){
  .settings-big-screen-resolution{
  }
  .settings-small-screen-resolution{
    display: none;
  }
}

/*css for publish screen*/
.main-publish-container{
 /* overflow-y: auto;
  height: 100vh;*/
}
.header-fixed{
  position: sticky;
  top: 0;
  z-index: 9;
}

.main-template-container{
  overflow-y: auto;
  overflow-x: hidden;
  height: 85vh;
}

/*.client-portal-fixed-bar{
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  overflow:hidden;
  width: 100%;
}*/



.open-button {
  bottom: 25px;
  left: 86em;
  img{
    right: 30px;
    width: 50px;
  }
}

.background-image-portal{
  padding-bottom: 81px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  // height: 480px;
  left: 0;
  top: 0;

  img{
    /* pointer-events: none;*/
    position: absolute;
    width: 100%;
    height: 100%;
    // object-fit: cover;
  }
}

.testimonial-right-container{
  img{
    width: 100%;
    height: 420px;
    object-fit: cover;
    padding: 10px;
  }
}

.testimonial-profile{
  img{
    width: 50%;
  }
}
.testimonial-img{
  height:100%;
  img{
    width: 75%;
    height:100%;
  }
}
.who-we-are-container{
  img{
    width: 100%;
    height:400px;
    object-fit:cover;
  }
}

.join-us-container{
  img{
    width: 100%;
    object-fit: cover;
    height: 420px;
  }
}


.meet-the-team{
  img{
    width: 100%;
    border-radius: 5%;
    height: 75%;
  }
}
.meet-the-team-section{
  img{
    border-radius: 50%;
    width: 100%;
  }
}
.life-at-company{
  img{
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}
.life-at-company3{
  img{
    width: 100%;
    border-radius: 5%;
    height: 96%;
  }
}
.ready-to-join-us{
  background-size: cover;
  height: 480px;
  img{
    width: 100%;
  }
}
.who-we-are-container{
  background-size: cover;
  height: 480px;
  img{
    width: 100%;
  }
}

.responsive-img{
  img{
    width: 100%;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    margin-top: 10px;
    height: 66px;
    // padding: 5px;
  }
}

.background-image-who-we-are{
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  img{
    // pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.who-we-are2-bg-img{
  // display: inline-block;
  // overflow: hidden;
  position: relative;
  width: 100%;
  height:480px !important;
  .who-we-are2{
    position: absolute;
    top:0px;
    bottom:0px;
    img{
      width:100%;
      height:100%;
    }
  }
}

.background-image-get-ready-to-join{
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  img{
    // pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.testimonial-profile-card{
  img{
    width: 30%;
  }
}

.header-img{
  img{
    width: 100%;
  }
}

.MuiAppBar-colorPrimary{
  background-color: #FFFFFF !important;
}



/* hover effect of client portal image
!* [1] The container *!
.img-hover-zoom {
  top: 0;
  left: 0;
  overflow: hidden; !* [1.2] Hide the overflowing of child elements *!
}

!* [2] Transition property for smooth transformation of images *!
.img-hover-zoom img {
  transform-origin: 30% 30%;
  transition: transform 1s, filter .5s ease-out;
}

!* [3] Finally, transforming the image when container gets hovered *!
.img-hover-zoom:hover img {
  transform: scale(1.15);
}*/



.navbar-header{
  background-color: white;
  color: black;
  height: 12vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  ul {
    list-style: none;
    display: flex;
    li {
      padding: 10px;
    }
  }
}

.nugget-section{
  background-color: $bg-info;
  padding: 12px 10px;
  .nugget-container{
    display: flow-root;
  }
}

.delete-sub-text {
  margin-top: -1em !important;
  font-size: var(--font-size-12) !important;
  margin-left: 25px !important;
  color:  var(--gray7);
}

.user-pic-block{
  .profile-pic{
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .profile{
      padding: 3px;
      background-color: #fff;
      /*border: 1px solid #dee2e6;*/
      width: 100px;
      height: 100px;
      /*border-radius: 50%;*/
    }
    .profile-file-upload{
      position: absolute;
      display: none;
    }
  }
}

.user-pic-block{
  .profile-pic{
    &:hover{
      .profile-file-upload{
        display: block;
        position: absolute;
      }
    }
  }
}

.container-scroll{
  // min-height: 100vh;
}

.pdf-icon-section{
  height: 80%;
  width: 15%;
  object-fit:cover;
}

.search-bar{
background-color: #eff0f6 !important;
}

.client-portal-container{
  height: 100vh;
}

.btn{
  &.primary-portal-button{
    border: 1px solid black;
    color: $white-color;
    background-color: black;
    font-size: var(--font-size-16);
    padding:5px 20px;
    text-transform: none;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);
    &:hover{
      background-color: var(--primary-color) !important;
      box-shadow: none;
      border:1px solid var(--primary-color) !important;
    }
  }
}

.search-section{
  .MuiFormControl-root{
    .MuiOutlinedInput-root{
        ::placeholder{
          color: #1f1f1f!important;
          font-weight:var(--font-weight-600);
        }
    }
  }
}

.radio-button-class{
  border: 1px solid #EAE9F2;
}

.team-image{
  img{
    width: 95%;
    border-radius: 5%;
    height: 96%;
  }
}

.header-image{
  img{
    width: 328px;
    height: 100%;
  }
}

.margin {
  p {
    margin: 0px;
  }
}

.editor-font-color:hover {
  cursor: pointer;
}

/* Custom CSS for react-tippy tooltips */
.MuiTooltip-popper {
  z-index: 99999 !important;
}
.MuiTooltip-tooltip{
  background-color: var(--gray10) !important;
  opacity: .92 !important;
  z-index: 99999 !important;
}
.MuiTooltip-arrow{
  color: var(--gray10) !important;
  opacity: .92 !important;
}

.tippy-popper{
  background-color: transparent !important;
  //border-radius: 4px;
}
#tippy-tooltip-1 .tippy-tooltip .tippy-tooltip--regular {
  font-size: var(--font-size-12) !important; 
  background-color: var(--gray10) !important;
  opacity: .92 !important;
}

.tippy-tooltip {
	position: relative;
	color: #fff;
	border-radius: 6px;
	padding: .4rem .6rem;
	text-align: center;
	will-change: transform;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $gray10 !important;
  opacity: .92 !important;
}
.tippy-tooltip [x-circle] {
	position: absolute;
	will-change: transform;
	background-color: $gray10 !important;
  opacity: .92 !important;
	border-radius: 50%;
	width: 130%;
	width: calc(110% + 2rem);
	left: 50%;
	top: 50%;
	z-index: -1;
	overflow: hidden;
	transition: all ease;
}
.tippy-tooltip[data-animatefill] {
	overflow: hidden;
	background-color: $gray10 !important;
  opacity: .92 !important;
}
.tippy-tooltip-content {
  color: #FFFFFF !important; 
  font-size: var(--font-size-12) !important;
}
.container .checkmark:after {
  background: var(--primary-color) !important;
}
.container input:checked ~ .checkmark {
  background-color: #ffffff;
  border: 1px solid var(--primary-color) !important;
}