.MuiBreadcrumbs-root{
  padding: 10px 0;
  ol{
    li{
      a{
        align-items: center;
        display: flex;
        text-decoration: none;
        color: var(--gray8);
        font-size: var(--font-size-16);
      }
    }
  }
}
.job-list-header{
  .MuiBreadcrumbs-root{
    padding: 10px 0;
    ol{
      li{
        a{
          align-items: center;
          display: flex;
          text-decoration: none;
          color: var(--gray1) !important;
          font-size: var(--font-size-12);
        }
      }
    }
  }
}
.jobWizard-Header{
  .MuiBreadcrumbs-root{
    padding: 10px 0;
    ol{
      li{
        a{
          align-items: center;
          display: flex;
          text-decoration: none;
          color: var(--gray9) !important;
          font-size: var(--font-size-12);
        }
      }
    }
  }
}
