.skills {
  background: $gray3;
  border-radius: 20px;
  padding: 4px 14px;
  margin: 4px 4px 4px 0px;
  color: $blue-color;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  display: inline-block !important;
  text-transform: lowercase;
}

.preferred-communication-label {
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: 500;
  .MuiFormControlLabel-label {
    font-weight: 500;
    color: var(--gray7) !important;
  }
  .Mui-checked {
    color: var(--primary-color) !important;
  }
  .Mui-checked + .MuiFormControlLabel-label {
    color: var(--primary-color) !important;
  }
}

.specialization-capitalize {
  text-transform: none;
}

.switch-cursor-default {
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--gray7) !important;
    opacity: 0.12;
  }
  .Mui-disabled {
    color: #f5f5f5 !important;
  }
}

.chat-textarea {
  .MuiInputBase-input {
    height: 30px !important;
  }
}

.message-list-unread-count {
  font-size: 10px !important;
  font-weight: 600 !important;
  padding: 5px;
  border-radius: 50px;
  color: white;
  background-color: var(--primary-color);
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.unread-message-primary-color {
  color: var(--primary-color) !important;
}

.messages-file-padding {
  .form-control.input-group {
    padding: 6px !important;
  }
}

.companiesFilter {
  background: #eff0f6;
  border-radius: 7px;
  padding: 8px 10px;
  margin: 4px 4px 4px 0px;
  color: #1f1f1f;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  display: inline-block !important;
}

.cityName {
  .location-search-input {
    font-weight: var(--font-weight-500) !important;
  }

  input {
    font-size: var(--font-size-14) !important;
    font-family: var(--font-family)  !important;
    font-weight: normal !important;
    color: #1f1f1f !important;
    background-color: #eff0f6;
    width: 100%;
    border-radius: 4px !important;
    margin-top: 9px !important;
    border: none;
    outline: none;
    padding: 12px 8px !important;
    // opacity: 0.9;
    // &::placeholder{
    // color: #bbbcc0;
    // color: #A9A9A9 !important;
    // opacity: 1.0;
    // }
  }

  .autocomplete-dropdown-container {
    // border-radius: 3px;
    box-shadow: 0 4px 4px rgb(234 233 242 / 40%);
    background: #fff;
    // padding: 0 20px;
    position: absolute;
    overflow: auto;
    /* max-height: 50%; */
    z-index: 100;
    /* bottom: 100px; */
    /*width: 315px;*/
    box-shadow: 0px 6px 14px -6px rgba(0, 0, 0, 0.12),
      0px 10px 32px -4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px !important;
  }
}

.MuiPickersBasePicker-container {
  .MuiPickersToolbar-toolbar {
    background-color: var(--primary-color) !important;
  }

  .MuiTypography-colorPrimary {
    color: var(--primary-color) !important;
  }
}

.MuiPickersModal-dialogRoot {
  .MuiButton-textPrimary {
    color: var(--primary-color) !important;
  }
}

.MuiPickersCalendar-week {
  .MuiPickersDay-daySelected {
    background-color: var(--primary-color) !important;
  }

  .MuiPickersDay-current {

    // color:var(--primary-color);
    &:focus {
      color: #fff !important;
    }
  }
}

.MuiInput-input.MuiInputBase-input.Mui-disabled {
  // background-color: #E7E7EA;
  // -webkit-text-fill-color: #696871;
  // border: 1px solid #ADADAD;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
.phone-input{
  .MuiInput-input{
    padding:6px 4px !important;
  }
}
// .select-candidate{
//   .select-option-section{
//     margin-top: 7px !important;
//   }
// }
.select-option-section {
  margin-top: 1px !important;
  //border-radius: 0 !important;
  &.select-candidate {
    margin-top: 7px !important;
  }
  .MuiSelect-select {
    // border: 1px solid #FFFFFF;
    padding: 0;
    // background: #FFFFFF;
    border-radius: 0 !important;
  }
}
.select-option-section-reference {
  margin-top: 8px !important;
  height: calc(1.5em + 0.75rem + 8px);
  padding-bottom: 7px !important;
}

.info {
  background: rgb(45, 169, 189, 0.2);
  border-radius: 10px;
  color: $info-color;
  font-size: var(--font-size-14);
  padding: 7px 20px;
}

.policy .info {
  background: $white-color;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  margin-top: 70px;
  padding: 20px;
  color: $black-color;
}

.tabActive {
  color: var(--primary-color) !important;
  // border-bottom: 2px solid var(--primary-color) !important;
  padding-bottom: 13px !important;
  text-decoration: underline !important;
}

.jobSearch {
  .inputGroup {
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        background-color: $bg-grey  !important;
      }
    }
  }
}

.matchingJob {
  .Mui-selected.position-issue {
    bottom: -3px !important;
  }

  .MuiBox-root {
    padding: 0px !important;
  }

  .MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        background-color: var(--primary-color) !important;
        height: 5px;
        border-radius: 3px;
      }
    }

    .MuiTabs-flexContainer {
      // justify-content: space-between !important;
      // border-bottom: 1px solid $gray4 !important;
      border-color: rgba(0, 41, 98, 0.2) !important;
      border-radius: 3px !important;
    }

    .MuiTab-root {
      text-transform: capitalize;
      color: var(--gray10);
      font-family: var(--font-family);
      padding-left: 0px !important;
      padding-right: 0px !important;
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-400);
      padding-bottom: 2px;
      margin-right: 30px;

      &.Mui-selected {
        color: var(--primary-color);
        font-size: var(--font-size-14) !important;
        font-weight: var(--font-weight-600) !important;
      }
    }
  }
}

// .job-type{
//   .MuiOutlinedInput-input{
//     &.MuiInputBase-input{
//       background-color: transparent !important;
//     }
//   }
// }
.search input {
  background: $bg-white  !important;
  border: 1px solid #d4d4d8;
  border-radius: 4px;
  padding: 8px 5px !important;
}

.searchKeyword {
  .MuiFormControl-root {
    background: $bg-white  !important;
    border: 1px solid #eae9f2;
    border-radius: 8px;
    margin-top: 10px !important;
  }

  input {
    background: $bg-white  !important;
    padding: 8px 5px !important;
  }
}

.jobsMatching {
  background: #ffffff;
  // border: 1px solid #EAE9F2;
  border-radius: 10px;
}

.campain-cardShadow {
  box-shadow: 0 7px 7px rgb(179 179 179 / 15%);
}

.acceptStatus,
.declineStatus,
.pendingStatus,
.expiredStatus,
.appliedStatus,
.interviewReferralStatus,
.priorityStatus,
.priorityStatusProfileCover,
.mspExclusive {
  border-radius: 20px;
  padding: 4px 15px;
}

.priorityStatusProfileCover {
  background: #eae6ff;
}

.priorityStatus {
  color: #3d3db9 !important;
  background: #e8e8ff !important;
  border-radius: 24px !important;
  font-size: 10px !important;
  font-weight: var(--font-weight-600) !important;
  padding: 4px 6px !important;
  bottom: -20px !important;
  left: 12px !important;
}
.mspExclusive {
  color: #150349 !important;
  background: #E5f0FC !important;
  border-radius: 24px !important;
  font-size: 10px !important;
  font-weight: var(--font-weight-600) !important;
  padding: 4px 6px !important;
  bottom: -20px !important;
left: 0 !important;
}
.global-search-list-exclusive {
  bottom: -20px !important;
  left: 16px !important;
}
.global-search-list-mspExclusive {
  bottom: -20px !important;
  left: 2px !important;
}
.hide_side_drawer {
  visibility: hidden;
}

.priority-image-status {
  color: #3d3db9 !important;
  background: #e8e8ff !important;
  font-size: 11px !important;
  font-weight: var(--font-weight-600) !important;
  padding: 4px 16px !important;
  border-radius: 20px;
  padding: 8px;
}
.priority-image-status-campaign {
  color: #3d3db9 !important;
  background: #e8e8ff !important;
  font-size: 11px !important;
  font-weight: var(--font-weight-600) !important;
  padding: 4px 12px !important;
  border-radius: 20px;
  padding: 8px;
}
.priority-image-status-mspExclusive {
  color: #150349 !important;
  background: #E5f0FC !important;
  font-size: 11px !important;
  font-weight: var(--font-weight-600) !important;
  padding: 4px 16px !important;
  border-radius: 20px;
  padding: 8px;
}
.priority-image-status-mspExclusive-campaign {
  color: #150349 !important;
  background: #E5f0FC !important;
  font-size: 11px !important;
  font-weight: var(--font-weight-600) !important;
  padding: 4px 10px !important;
  border-radius: 20px;
  padding: 8px;
}

.private-image-status {
  padding: 4px 16px;
  border-radius: 24px;
  background-color: #fff4e8;
  color: #b16007 !important;
  font-size: 11px;
  font-weight: var(--font-weight-600);
}
.private-image-status-campaign {
  padding: 4px 12px;
  border-radius: 24px;
  background-color: #fff4e8;
  color: #b16007 !important;
  font-size: 11px;
  font-weight: var(--font-weight-600);
}

.acceptStatus {
  background: #ecfdf3;
  color: #1a7fae;
}

.declineStatus {
  background: #fef3f2;
  color: #b42318;
}

.pendingStatus {
  background: #fffaeb;
  color: #b54708;
}

.appliedStatus {
  background: #EBF9FF;
  color: #1A7FAE !important;
}
.interviewReferralStatus {
  background: #E8E8FF;
  color: #3D3DB9 !important;
}
.expiredStatus {
  background-color: #f8f9fc;
  color: #363f72 !important;
}
.applied-status-color {
  color: #1A7FAE !important;
}

.availableStatus {
  background-color: rgba(236, 253, 243, 1);
  color: #027a48 !important;
  padding: 4px 15px;
  border-radius: 20px;
}
.onBoardedReferralStatus {
  background-color: #EBFFEB;
  color: #228B22 !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.offeredReferralStatus {
  background-color: #EBF9FF;
  color: #1A7FAe !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.offBoardedReferralStatus {
  background-color: #E4E7EC !important;
  color: #667085 !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}

.closedStatus {
  background-color: #f8f9fc;
  color: #363f72 !important;
  padding: 4px 15px;
  border-radius: 20px;
}

.expiryStatus {
  background-color: rgba(255, 250, 235, 1);
  color: #b54708 !important;
  padding: 4px 15px;
  border-radius: 20px;
}

.currentStage {
  background: #F9F5FF;
  color: #6941C6;
  border-radius: 20px;
  padding: 4px 15px;
}

.image-section {
  position: absolute;
  margin-top: -1.2em;
  padding-top: -4em;
  margin-left: 4px;
}

.profileImage {
  width: 140px;
  border-radius: 50%;
  height: 140px;
  // position: absolute;
  left: 130px;
}
.profileImage-crossSell {
  width: 140px;
  border-radius: 50%;
  height: 140px;
  // position: absolute;
  left: 130px;
}

.login-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  /* margin: 0 100px !important;*/
  background: #ffffff;
}

.logoImage-section {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 50px;
}

.verify {
  background: rgba(34, 166, 64, 0.2);
  border-radius: 10px;
  padding: 7px 20px;
}

.dashboard-list-tab {
  background: #ffffff;
  /* box-shadow: inset 0 1px 4px rgb(0 0 0 / 25%);*/
  border-radius: 3px;
  padding: 17px 25px;
  margin-top: 62px !important;
  position: relative;
  width: 100%;
  z-index: 1;
}

.registration {
  padding-right: 130px !important;
}

.remarkStatus {
  img {
    background: #EAF4FF;
    border-radius: 4px;
    padding: 8px;
  }
}

.round-image {
  border-radius: 50%;
}

.policyBullet {
  .MuiSvgIcon-root {
    color: rgba(31, 31, 31, 0.6) !important;
    font-size: 7px !important;
  }
}

.enterpriseSearch {
  .MuiFormControl-root {
    background: $bg-white;
    border-radius: 8px;
    width: 310px;
    margin-top: 7px !important;
    margin-bottom: 6px !important;
    // margin-top: 11px !important;
    // border: 1px solid $gray6;

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        border-radius: 8px;
      }
    }
  }
}

.searchFilter {
  background: #ffffff;
  border: 1px solid #eae9f2;
  border-radius: 10px;
}

.toolTip {
  .MuiButtonBase-root {
    padding: 0px 5px !important;
  }
}

.dropdown-arrow {
  padding: 0 !important;
  min-width: 0 !important;
}

.meet-team {}

.textEllipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  white-space: nowrap;
}

.WorkExperienceBullet {
  .MuiSvgIcon-root {
    font-size: 7px !important;
    margin: 0 5px;
  }
}

.profileImage {
  width: 111px;
  border-radius: 50%;
  height: 111px;
}

.profileImage-crossSell {
  width: 111px;
  border-radius: 50%;
  height: 111px;
}

.updateProfile {
  position: absolute;
  top: 73px;
  left: 5px;
  width: 90%;
  height: 37px;
}

.publicProfile {
  background: rgba(255, 255, 255, 0.4);
  // background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 12px 16px;
  position: absolute;
  top: 25px;
  right: 30px;
  color: #333333;
}

.profileBgImage {
  /*  background-image: url('/assets/images/profile-bg.png');*/
  width: 100%;
  background-size: cover;
  height: 100%;
  background-position: top;
  position: relative;

  /* margin-top: 74px;*/
  /*  .bg-image{
          object-fit: cover;
      }*/
  .bg-image {
    border-radius: 16px 16px 0px 0px;
  }
}

.matchingBreakdownTrailColor {
  stroke: rgba(34, 166, 64, 0.2) !important;
  // stroke: var(--primary-color) !important;
  // stroke-opacity: 0.2 !important;
}
.CircularProgressbar {
  .CircularProgressbar-trail {
    // stroke: #d6d6d6;
    stroke: rgba(34, 166, 64, 0.2) !important;
    // stroke: var(--primary-color) !important;
    // stroke-opacity: 0.2 !important;
  }
}

.editProfileBullet {
  .MuiSvgIcon-root {
    font-size: 7px !important;
    margin: 0 5px;
  }
}

.jobProfile {
  img {
    width: 50px;
    height: 50px;
  }
}

.matchingbgdefault {
  position: relative;
  height: 146px !important;
}

.matchingbg {
  position: relative;
  height: 137px !important;

  img {
    height: 137px !important;
  }
}

.skillsBroadcast {
  background: #eff0f6;
  border: 1px solid #696871;
  border-radius: 7px;
  color: #696871;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-12);
  padding: 7px 9px;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 4px 4px 4px 0px;
}

.addprofilepic {
  border-radius: 50%;
  cursor: pointer;
  /* margin: 20px;*/
  padding: 8px;
  width: 45px !important;
  height: 45px !important;
  background-color: #f1f1f1;
  color: #696871;
}

.enterprise-access-section {
  .background-img {

    /* background-image: url('/assets/images/profile-bg.png');*/
    /*margin-top: 4em !important;*/
    /*  .bg-image{
        object-fit: cover;
      }*/
    img {
      // width: 100%;
      // float: left;
      // background-size: cover;
      // height: 150px;
      // background-position: center;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 150px;
      width: 100%;
      float: left;
    }

    .editIcon {
      border-radius: 50%;
      cursor: pointer;
      margin: 20px;
      padding: 8px 10px 8px 10px;
      width: 35px !important;
      height: 35px !important;
      background-color: #fff;
      // object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
    }
  }

  .profile-section {
    padding: 10px 20px;
    height: 140px;

    .profile-main-section {
      position: absolute;
      top: 150px;

      .profile-pic-default {
        justify-content: flex-end;
        display: flex;

        img {
          width: 140px;
          height: 140px;
          /* border-radius: 50%;*/
        }
      }

      .user-pic-block {
        .profile-pic {
          img {
            width: 140px;
            height: 140px;
            /* border-radius: 50%;*/
            object-fit: cover;
          }
        }
      }

      .edit-camera-icon {
        position: absolute;
        top: -42px;
        left: 54px;
        color: #fff;
        cursor: pointer;
      }
    }

    .enterprise-section {
      margin-left: 145px;
    }
  }
}

.updateProfile {
  position: absolute;
  margin-left: 7px;
  margin-top: -48px;
  width: 90%;
  height: 45px;
}

.updateImage {
  position: absolute;
  top: -42px;
  left: 35px;
  color: #fff;
  cursor: pointer;
  width: 20px;
}

.textEllipse-table {
  // white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 200px;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .enterprise-access-section {
    .background-img {
      margin-top: 0 !important;
    }
  }

  .profile-section {
    .profile-main-section {
      top: 165px !important;
    }
  }
}
@media screen and (min-width: 425px) {
  .matching-tab-scroll{
    .MuiTabs-root{
      .MuiTabScrollButton-root{
        margin: 0px 10px !important;
      }
    }
  }
}
.logoImg-header {
  // height: 55px;
  height: 60px;
  padding: 0px !important;
}

.logoImg-navbar {
  width: 140px;
  height: 38px;
  object-fit: contain;
  padding: 0px !important;
}

.logoImg {
  /* width:70px;*/
  height: 60px;
}

.logoWord {
  height: 70px;
  width: 115px;
  margin-left: 5px;
  padding-bottom: 5px;
}

.text-capitalize {
  text-transform: capitalize !important;

  input {
    text-transform: capitalize !important;
  }
}

.select-date {
  .MuiInputBase-root {
    width: 100% !important;
  }
}
.dateScreeningQuesClearIcon {
  font-size: 20px !important;
  margin-right: 10px;
  margin-top: 4px;
  cursor: pointer;
}
.question-asses {
  .MuiFormControlLabel-root {
    .MuiCheckbox-root.Mui-checked {
      color: var(--primary-color) !important;
    }
  }
}
.availability-assess {
  margin-right: 101px !important;
}
.screeningQuesPadding {
  .MuiRadio-root {
    padding: 9px !important;
  }
  .MuiCheckbox-root {
    padding: 9px !important;
  }
}
.screeningQuesDisabled.form-control.input-group {
  background-color: #f9fafb !important;
}

.navdrop-down {
  .MuiPaper-root {
    left: 1080px !important;
  }
}

.MuiGrid-root {
  .MuiGrid-root {
    .MuiButton-root {
      &:hover {
        background-color: transparent;
      }

      svg {
        // margin-bottom:6px !important;
      }
    }
  }
}

.CircularProgressbar {
  .CircularProgressbar-text {
    font-weight: var(--font-weight-500);
  }
}

.bold-placeholder {
  .public-DraftEditorPlaceholder-inner {
    font-weight: bold;
  }
}

.analytics {
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;

  .MuiList-root {
    .MuiMenuItem-root {
      margin-right: 0px !important;
    }
  }
}

.assessment-questions {
  .MuiOutlinedInput-root {
    .MuiSelect-select {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 10px !important;
    }
  }
}

.screen-question {
  border: 1px solid var(--gray6);
  border-radius: 10px;
  padding: 10px;
  color: var(--gray9);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
}
.border-1px{
  border: 1px solid $gray6 !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
  background: $gray3;
}
.border-1px-radius-4px {
  border: 1px solid #98A2B3 !important;
  border-radius: 4px !important;
}
.upload-jd {
  padding: 10px;
  background: #fffaeb;
  border-radius: 6px;

  svg {
    background: var(--secondary-color);
    padding: 8px;
    width: 34px;
    border-radius: 50%;
  }
}

.assessment-details {

  // margin-left: 7px !important;
  .Mui-checked {
    color: var(--primary-color) !important;
  }
}

.assess-update {
  .MuiPaper-elevation {
    min-width: 16ch !important;
    max-width: auto !important;
    left: 1109p !important;
  }
}

.talent-pool-cta {
  .assess-update {
    .MuiPaper-elevation {
      min-width: auto !important;
    }
  }
}

.question-type {
  margin-top: 50px !important;
  justify-content: center;
  display: flex;
}

.notification-body {
  max-height: 125px;
  overflow-y: auto;
  cursor: context-menu !important;

  .MuiMenuItem-root {
    cursor: context-menu !important;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.MuiBox-root {
  .MuiSlider-sizeMedium {
    height: 2px !important;
    color: var(--primary-color) !important;

    .MuiSlider-thumbColorPrimary {
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.cityName {
  .location-search-input {
    font-weight: var(--font-weight-500) !important;
  }

  input {
    font-size: var(--font-size-14) !important;
    font-family: var(--font-family)  !important;
    font-weight: normal !important;
    color: #1f1f1f !important;
    background-color: #eff0f6;
    width: 100%;
    border-radius: 4px !important;
    margin-top: 0px !important;
    border: none;
    outline: none;
    padding: 14px 8px !important;
  }

  .autocomplete-dropdown-container {
    // border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(234, 233, 242, 0.4);
    background: #fff;
    // padding: 0 20px;
    position: absolute;
    overflow: auto;
    /* max-height: 50%; */
    z-index: 100;
    /* bottom: 100px; */
    /* width: 315px;*/
    box-shadow: 0px 6px 14px -6px rgba(0, 0, 0, 0.12),
      0px 10px 32px -4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px !important;
  }
}

.suggestion-item {
  padding: 7px 20px 7px 20px !important;
}

.suggestion-item--active {
  padding: 7px 20px 7px 20px !important;
}

.messaging {
  border-left: 1px solid #e4e7ec;
}

.message-pdf {
  background: #f7f7f7;
  border: 1px solid #e4e4e7;
  border-radius: 4px;
}
.message-pdfSend{
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  color: var(--gray1);
  a{
    color: var(--gray1);
  }
}
.message-pdfReply{
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  color: var(--primary-color);
  a{
    color: var(--primary-color);
  }
}

.file-close {
  border-radius: 50%;
  padding: 5px;
  width: 19px;
  height: 19px;
}

.job-count {
  border-radius: 50% !important;
  min-width: 17px !important;
  min-height: 17px !important;
  font-size: 9px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  color: white;
  font-weight: var(--font-weight-500) !important;
}

.notes-input {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  border-radius: 4px;
}

.Toastify__toast-container {
  .Toastify__toast {
    .Toastify__toast-body {
      align-items: flex-start;

      .Toastify__toast-icon {
        padding-top: 1px;
      }
    }
  }
}

.rating-progress {
  .MuiLinearProgress-determinate {
    height: 15px !important;
    border-radius: 0px !important;
    background: #eff0f6 !important;

    .MuiLinearProgress-bar {
      border-radius: 0px !important;
    }
  }
}

.matching-breakdown-progress {
  .MuiLinearProgress-determinate {
    height: 20px !important;
    border-radius: 25px !important;
    background: #8f9ddd !important;

    .MuiLinearProgress-bar {
      border-radius: 25px !important;
    }
  }
}

.assess-question {
  .MuiFormControlLabel-root {
    margin-bottom: 0;

    // align-items: flex-start !important;
    .MuiButtonBase-root {
      // padding-top: 2px !important;
    }

    .MuiCheckbox-root.Mui-checked {
      color: var(--primary-color) !important;
    }
  }
}

.delete-btn {
  margin-left: 10px;
  background: white;
  padding: 3px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.nav-bar-logo-section {

  /* background-image: url('/assets/images/profile-bg.png');*/
  /*margin-top: 4em !important;*/
  /*  .bg-image{
      object-fit: cover;
    }*/
  img {
    /* width: 100%;*/
    /* width: 315px;*/
    float: left;
    background-size: cover;
    /* height: 125px; */
    // height: 85px;
    background-position: center;
    box-shadow: 0px 4px 4px rgb(234 233 242 / 40%);
    border: 1px solid lightgray;
    /* margin-bottom: 15px;*/
    height: auto;
  }

  .editIcon {
    border-radius: 50%;
    cursor: pointer;
    margin: 0px -24px 20px -14px;
    /* margin: 20px;*/
    padding: 8px 10px 8px 10px;
    width: 35px !important;
    height: 35px !important;
    background-color: #fff;
    // object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
}

.talent-pool {
  background: #deebff;
  border: 1px solid #eae9f2;
  border-radius: 10px 10px 0px 0px;
}

.filter-jobs {
  background: #ffffff !important;
  // box-shadow: inset 0 1px 4px rgb(0 0 0 / 25%);
  // position:fixed !important;
  // width: 100%;
  z-index: 9;
  top: 82.5px;
  padding: 13px 19px !important;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%);
  // box-shadow: 0 4px 4px rgb(179 179 179 / 15%);
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  // right: 57px;
  // left: 229px;
  left: auto !important;
  overflow: visible !important;
  position: sticky !important;
}

.ReactCrop__image {
  min-width: 500px;
}

.ReactCrop__image {
  min-width: 230px;
  max-width: 230px;
  top: 0px !important;
  left: 0px !important;
  transform: rotate(0deg);
  transform-origin: 115px 115px;
}

/* new cropper plugin css for profile picture circular */
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #fff;
  outline: 1px dashed #fff !important;
  outline-color: #fff !important;
  border: 1px dashed #fff;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.cropper-point.point-se {
  top: calc(85% + 1px);
  right: 14%;
}

.cropper-point.point-sw {
  top: calc(85% + 1px);
  left: 14%;
}

.cropper-point.point-nw {
  top: calc(15% - 5px);
  left: 14%;
}

.cropper-point.point-ne {
  top: calc(15% - 5px);
  right: 14%;
}

.cropper-bg {
  background-image: none !important;
}

.cropper-modal {
  background-color: #ffffff !important;
  opacity: 0.5;
}

.cropper-line {
  border: 5px solid red;
  background-color: #696871 !important;
}

.cropper-modal {
  background-color: #000;
  opacity: 0 !important;
}

.cropper-point {
  background-color: #ffff !important;
  opacity: 0.9 !important;
}

#scrollableDiv {
  height: 910px !important;
}

.profile-video-thumbnail {
  object-fit: cover;
}

.filter-myjob {
  .MuiBox-root {
    .MuiFormControlLabel-root {
      .MuiTypography-root {
        color: var(--gray10)  !important;
        font-size: var(--font-size-14) !important;
        font-weight: var(--font-weight-400) !important;
      }
    }
  }
}

.checkbox-card {
  padding-top: 2px;
  padding-bottom: 2px;
}

.location-icon-checkbox {
  margin-left: -4px;
}

.play-icon {
  position: absolute;
  align-items: center;
  padding: 30px 30px;
  width: 78px;
}

.profile-image-overlay {
  width: 14px !important;
}

.profileImage-overlay {
  width: 22px !important;
  height: 26px !important;
  left: 199px;
  top: 285px;
  position: absolute;
  border-radius: unset;
}

.profileImage-overlay-crossSell {
  width: 22px !important;
  height: 26px !important;
  left: 199px;
  top: 285px;
  position: absolute;
  border-radius: unset;
}

.play-icon-profile {
  position: absolute;
  align-items: center;
  width: 15px;
  top: 15px;
  left: 20px;
}
.pool-play-icon{
  top: 16px !important;
  left: 35px !important;
}

.play-icon-panel-member {
  position: absolute;
  align-items: center;
  width: 15px !important;
  left: 74px;
}

.play-icon-global {
  position: absolute;
  align-items: center;
  width: 15px;
  top: 15px;
  left: 47px;
}

.play-icon-profile-detail {
  position: absolute;
  align-items: center;
  width: 15px !important;
  left: 42px;
}
.play-icon-profile-detail-icon {
  position: absolute;
  align-items: center;
  width: 15px !important;
  left: 35px;
}
.play-icon-interview-detail {
  position: absolute;
  align-items: center;
  width: 15px !important;
  left: 22px;
}

.rating-category {
  .job-category {
    .MuiInputBase-input {
      padding: 4px !important;
      color: var(--gray9)  !important;
    }
  }
}

.pagenation-design {
  .MuiTableCell-root {
    border-bottom: none !important;
    padding: 0px !important;

    .MuiToolbar-root {
      padding-left: 0px !important;

      .MuiTablePagination-actions {
        margin-left: 0px !important;
      }

      .MuiTablePagination-displayedRows {
        // position: absolute;
        // left: 38px;
      }

      .MuiButtonBase-root {
        color: #ffffff !important;
        // background-color: #445bc7 !important;
        background-color: var(--primary-color) !important;
        border-color: #ffffff;
        border-radius: 4px !important;
        padding: 1px;
        margin-right: 57px;
      }
    }
  }
}

.count-jobs {
  background: var(--secondary-color);
  border-radius: 8px;
  padding: 7px 8px 5px 8px;
  color: var(--primary-color);
}

.page-from {
  position: absolute;
  left: 33px;
  bottom: 8px;
  background: #ffffff;
  border: 1px solid rgba(52, 52, 52, 0.5);
  border-radius: 8px;
  padding: 7px 11px;
}

.totalPages {
  position: absolute;
  left: 110px;
  bottom: 15px;
  width: 100px !important;
}

.menu-list {
  position: fixed;
  // top: 82px;
  top: 56px;
  left: 0px;
  width: 55px;
  /* background: #FFFFFF !important;*/
  box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
  height: 100vh;
  // opacity:1;
  z-index: 99;
  background: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  // background:#7F94B0;
  // background: rgba(0, 41, 98, 1);
  ul,
  .sidebar-list {
    padding-left: 0px !important;
    // margin-top: 15px !important;
    padding-top: 0px !important;

    .active-list {
      /*  background: rgba(68, 90, 199, 0.1);*/
      // background-color: #FFFFFF;
      // a label{
      //   color:#445AC7;
      // }
      background: var(--secondary-color);
      border-left: 6px solid var(--primary-color);
      padding-left: 20px !important;
    }

    li {
      list-style: none;
      // margin-top: 10px;
      padding: 10px;
      padding-left: 25px;
      text-align: center !important;
    }

    a {
      text-decoration: none;
      font-size: var(--font-size-14);

      label {
        display: block !important;
        cursor: pointer;
        margin-top: 2px;
        font-size: var(--font-size-14) !important;
        font-weight: var(--font-weight-500) !important;
        letter-spacing: -0.75px !important;
        line-height: 130%;
      }
    }

    .collapse-label {
      left: 52px;

      .MuiAccordionSummary-content {
        margin: 0px !important;
      }

      svg {
        font-size: 25px !important;
        color: #696871;
        border-radius: 50%;
        border: 1px solid rgba(105, 104, 113, 0.4);
      }
    }
  }

  .menu-details {
    background: #ffffff !important;
    box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08) !important;
  }

  .collapse-navbar {
    background: transparent !important;
    color: #696871;
    top: 50px;
    right: auto !important;
    left: 0px !important;
    width: 40px;
    box-shadow: none !important;

    .MuiToolbar-root {
      padding-left: 0px !important;
    }
  }

  .MuiPaper-root {
    top: 54px !important;
    // width: 130px !important;
    width: 18% !important;
    border-radius: 0px !important;
    z-index: 999999 !important;
    background: #ffffff;
    border: 1px solid #e4e7ec !important;
    box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25) !important;
    // background: rgba(0, 41, 98, 1);
    // background:#7F94B0;
    // height: 527px;
    // overflow-y: scroll;
    height: -webkit-fill-available;
  }

  .menu-sideIcon {
    position: fixed;
    left: 43px;
    font-size: 23px !important;
    background: #ffffff !important;
    border: 1px solid #e4e7ec !important;
    box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25) !important;
  }
}

.hover-list {
  color: var(--gray9);
  &:hover {
    background: var(--secondary-color);
    color: var(--primary-color);
    .dashboard>path {
      fill: var(--primary-color) !important;
    }
    .contrasts>path {
      stroke: var(--primary-color);
    }
  }
}
.analytics-report {
  .MuiPaper-root {
    left: -20px !important;

    .MuiList-root {
      position: fixed;
      left: 165px;
      top: 55px;
      background: #ffffff;
      box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
      /* height: 100vh; */
      width: 280px !important;
      .analytic-options{
        height: 465px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
  }
}

.terms-content {
  ul {
    li {
      margin-top: 10px;
    }
  }
}

.analytic-settings-report {
  .MuiPaper-root {
    left: -20px !important;

    .MuiList-root {
      position: fixed;
      left: 56px;
      top: 55px;
      background: #ffffff;
      box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
      // padding: 25px !important;
      // height: 100vh;
      width: 280px !important;
      .analytic-options{
        height: 495px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
  }
}

.description-preview {
  p {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }
}

.MuiInputBase-colorPrimary:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  left: 0;
  bottom: 0;
  content: "";
  /* position: absolute; */
  right: 0;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
  border: none;
}

.launch-page-card {
  .MuiCardActionArea-root:hover {
    /* background-color: #FFFFFF !important;*/
    cursor: default;
  }

  span {
    background-color: inherit !important;
  }
}

.anchornew a {
  display: block;
  height: 60px;
  width: 182px;
  margin: 0 auto;
}

.anchorlogo a {
  display: block;
  // padding: 7px;
  // width: 133px;
  // margin: auto;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: 0px solid black !important;
}

.css-1k3dqhz-MuiButtonBase-root-MuiAccordionSummary-root:focus {
  outline: 2px solid black !important;
}

.MuiFormControlLabel-label:focus {
  border: 2px solid black !important;
}

.tableEllipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  width: 150px;
}

.position-issue {
  .MuiTouchRipple-root {
    position: initial !important;
  }

  .MuiOutlinedInput-notchedOutline {
    position: initial !important;
  }

  // .MuiBadge-anchorOriginTopRightRectangular {
  //   position: relative !important;
  //   right: 100px;
  // }
}

.Mui-selected.position-issue {
  border-bottom: solid 5px;
  position: relative;
  bottom: -5px;
  padding-bottom: 7px !important;
}

.matchingJob .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator {
  display: none;
}

p.label-mb {
  // margin-bottom: 10px !important;
}

.postion-issue-input {
  .MuiOutlinedInput-notchedOutline {
    position: relative !important;
    margin: -8px;
  }
}

.campaign-create {
  span {
    display: inline-flex !important;
  }
}

.addingEmail {
  top: 33px;
  right: 0px;
  position: absolute !important;
}

.score-modal {
  filter: opacity(0.8) drop-shadow(0 0 0 var(--primary-color));
}

.talent-pool-icon {
  filter: opacity(0.6) drop-shadow(0 0 0 #696871);
}

.talent-pool-active-icon {
  filter: opacity(0.6) drop-shadow(0 0 0 var(--primary-color));
}

.addbutton-size {
  // font-size: 10px !important;
  height: 25px !important;
  padding-top: 10px !important;
}

.adduserbox {
  // padding-top: 41px !important;
}

.public-profile-download {
  border-bottom: 1px solid lightgrey;
  padding: 5px;
  margin-bottom: 10px;
}

.adduserbox {
  // padding-top: 41px !important;
}

// .additional-details-form .css-yf8vq0-MuiSelect-nativeInput {
//   opacity: 1;
//     padding: 12px;
//     border:  1px solid $gray6 !important;
//     border-radius: 6px;
// }

.add-user-enterprise {
  margin-top: -5px !important;
}

.pagenation-joblist {
  .MuiPagination-root {
    ul {
      li {
        // margin-top: 8px !important;

        .MuiPaginationItem-previousNext {
          .MuiTouchRipple-root {
            background-color: transparent;
          }
        }

        .MuiButtonBase-root {
          border: 0px solid rgba(25, 118, 210, 0.5);
          color: var(--gray7);
        }
      }

      .MuiPaginationItem-root {
        border-radius: 8px;

        &.Mui-selected {
          background: var(--secondary-color) !important;
          color: var(--primary-color);
        }
      }

      .MuiPaginationItem-page {
        border: 0px solid rgba(25, 118, 210, 0.5) !important;
      }

      .MuiPaginationItem-icon {
        display: flex;
        align-items: center;
        margin: 0 !important;
      }
    }
  }
}

.campaign-pagination {
  .MuiPagination-root {
    ul {
      li {
        // margin-top: 8px !important;

        .MuiPaginationItem-previousNext {
          .MuiTouchRipple-root {
            background-color: var(--primary-color);
          }
        }

        .MuiButtonBase-root {
          border: 0px solid rgba(25, 118, 210, 0.5);
          color: var(--gray7);
        }
      }

      .MuiPaginationItem-root {
        border-radius: 8px;

        &.Mui-selected {
          background: var(--secondary-color) !important;
          color: var(--primary-color);
        }
      }

      .MuiPaginationItem-page {
        border: 0px solid rgba(25, 118, 210, 0.5) !important;
      }

      .MuiPaginationItem-icon {
        display: flex;
        align-items: center;
      }
    }
  }
}

.position-issue {
  .MuiTouchRipple-root {
    position: initial !important;
  }

  .MuiOutlinedInput-notchedOutline {
    position: initial !important;
  }

  .Clearicon-issue {
    position: relative;
    // right: 20px;
  }
  .Clearicon-issue-parsed-skills {
    position: relative;
    top: 2px;
    right: 20px;
  }
}

.overflow-issue {
  padding: 0 10px;
  max-width: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // word-wrap: normal;
}

.pointerEventsNone {
  pointer-events: none;
}

.upload-job-success {
  background: rgba(34, 166, 64, 0.2);
  border-radius: 7px;
  font-size: var(--font-size-14);
  padding: 4px;
  margin-left: 5px;
}

.upload-job-failed {
  background: rgba(201, 41, 56, 0.2);
  border-radius: 7px;
  font-size: var(--font-size-14);
  padding: 4px;
  margin-left: 5px;
}

.sidebar-list {
  ul {
    margin-top: 0px !important;
    margin-bottom: 7px !important;
  }
}

.sample-card {
  border: 1px solid #e4e4e7 !important;
  border-radius: 6px;
  padding: 15px;
  height: 115px;
}

.privacy-mobile {
  position: fixed;
  bottom: 0px;
  width: 100%;
  // margin-bottom: 5px !important;
  background: #ffffff !important;
  left: 0px !important;
}

.rdw-image-modal-upload-option-label {
  word-break: break-all;
}

.campain .MuiOutlinedInput-input.MuiInputBase-input {
  // color: #A9A9A9 !important;
}

.campain .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus,
.campainPool .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: none !important;
}

.sampleImage {
  width: 45px !important;
  height: 45px !important;
  object-fit: cover;
  border-radius: 50%;
}

.shortList {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  /*optional*/
  text-transform: capitalize !important;
  /*input has OS specific font-family*/
  color: #069;
  cursor: pointer;
  justify-content: flex-start !important;
}

.filter-scroll {
  max-height: 240px !important;
  overflow-y: auto !important;
}

.Bulk-upload-align {
  .MuiFormControlLabel-label {
    margin-top: 3px !important;
  }
}

.locationAutocomplete .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input {
  margin-top: 0px !important;
}

.locationAutocomplete .MuiFormControl-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  display: none !important;
}
.locationAutocomplete-displayClearIcon .MuiFormControl-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  display: block !important;
}

.campaign-body {
  height: 300px !important;
}

.button-navigation-color {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.button-navigation .MuiBottomNavigationAction-root .MuiBottomNavigationAction-label {
  font-size: var(--font-size-16) !important;
}

path:hover {
  // fill-opacity: .7;
}

/* Style for Custom Tooltip */
div.tooltip {
  position: absolute;
  text-align: center;
  width: 60px;
  height: 26px;
  padding: 2px;
  font-size: var(--font-size-12);
  font-family: var(--font-family);
  background: #4b4b4bde;
  border: 0px;
  border-radius: 6px;
  pointer-events: none;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
}
.MuiTooltip-popper{
  .MuiTooltip-tooltip {
    font-size: var(--font-size-12);
  }
}

.MuiBottomNavigationAction-label {
  font-family: var(--font-family)  !important;
}

.no-data {
  margin-top: 91px;
  margin-bottom: 91px;
  text-align: center;
}

.no-data1 {
  margin-top: 123px;
  margin-bottom: 123px;
  text-align: center;
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background-color: var(--primary-color) !important;
}

.resume-upload {
  background: $gray3;
  // border: 1px solid #eae9f2;
  border-radius: 4px;
  padding: 5px 10px !important;
}
.resume-upload-pool{
  background: #F9FAFB;
  // border: 1px solid #eae9f2;
  border-radius: 4px;
  padding: 5px 10px !important;
}

.timeline-resume {
  border: 1px solid rgba(128, 129, 145, 0.4);
  border-radius: 8px;
  padding: 5px 10px !important;
}

.share_job_image {
  position: relative;
  top: -2px;
}

.inputavailable {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.Scoring-model {
  /* display: flex; */
  // align-items: center;
  justify-content: space-around;
  // vertical-align: middle;
  display: flex;
}

.arrow-buttons {
  background-color: #eff0f6;
  height: 50 !important;
}

.matching-weightage-conatiner {
  display: flex;
  justify-content: space-between;
}

.rating-progress {
  .MuiLinearProgress-determinate {
    height: 15px !important;
    border-radius: 8px !important;
    background: #eff0f6 !important;

    .MuiLinearProgress-bar {
      border-radius: 5px !important;
    }
  }
}

.analytic-res-align {
  color: var(--gray8) !important;
  &:hover {
    background: var(--secondary-color) !important;
    color: var(--primary-color) !important;
  }
}

.percent::after {
  content: "%";
  display: inline-block;
  margin-top: 5px;
  padding-left: 2px;
  font-size: var(--font-size-12);
  color: var(--gray7);
  font-weight: var(--font-weight-400);
  // padding-top: 2px;
}

.years::after {
  content: "year(s)";
  padding-left: 2px;
  margin-top: 5px;
  display: inline-block;
  font-size: var(--font-size-12);
  color: var(--gray7);
  font-weight: var(--font-weight-400);
}

.candidate-list-align {
  .MuiSelect-select {
    display: flex !important;
  }

  .MuiSelect-icon {
    font-size: 23px !important;
    // top: calc(50% - 0.6em);
    // margin-top: 1px !important;
  }
}

.menu-list {
  .scrollbar-auto {
    scrollbar-width: auto;
    background-color: lightgreen;
    height: 150px;
    width: 200px;
    overflow-y: scroll;
  }
}

.rating-progress {
  .MuiLinearProgress-determinate {
    height: 18px !important;
    border-radius: 4px !important;
    background: #eff0f6 !important;

    .MuiLinearProgress-bar {
      border-radius: 25px !important;
    }
  }
}

.radar-height {
  height: 380px !important;
  // width: 400px !important;
}

.no-campaign {
  background: transparent !important;
  box-shadow: none !important;
}

.calender-align {
  position: absolute;
  top: 113px;
  left: 647px;
}

.grid1-border-header {
  border-right: 1px solid #d9dce2;
  padding-top: 2px;
}

.top-section-border {
  border-top: 1px solid var(--gray4);
  border-right: 1px solid var(--gray4);
  border-left: 1px solid var(--gray4);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.tabs-section-border {
  border-right: 1px solid var(--gray4);
  border-left: 1px solid var(--gray4);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.matching-score-align {
  background: #ecfdf3;
  // background-color: var(--secondary-color);
  border-radius: 24px;
  height: 30px !important;
}

.check-details {
  .MuiCheckbox-root {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
  }
}

.open-status {
  background: #ecfdf3;
  border-radius: 24px;
  padding: 2px 8px !important;
  color: $success-color;
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-600);
}

.closed-status {
  background: #f2f4f7;
  border-radius: 24px;
  padding: 2px 8px !important;
  color: #363f72;
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-600);
}

.drop-status {
  background: #fef3f2;
  border-radius: 24px;
  padding: 2px 8px !important;
  color: #b42318;
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-600);
}

.t-47 {
  top: 47px !important;
}
.t-75{
  top: 75px !important;
}
.t-95{
  top: 95px !important;
}
.t-90{
  top: 90px !important;
}
.t-105{
  top: 105px !important;
}
.t-104 {
  top: 104px !important;
}
.t-140 {
  top: 140px !important;
}
.t-165{
  top: 165px !important;
}
.t-180{
  top: 180px !important;
}
.t-220{
  top: 220px !important;
}
.job-detail-drawer .MuiDrawer-paper {
  width: 80% !important;
}

.jobDetail-drawer .MuiDrawer-paper {
  width: 60% !important;
}

.time-line-position {
  margin-left: -20px;
}

.notification-default-icon {
  // background-color: var(--primary-color);
  // border-radius: 50%;
  // width: 35px;
  // height: 35px;
  // padding: 5px;
}

.notification-body-width {
  .MuiPopover-paper {
    top: 16px !important;
  }
}
.all-notifications{
  &.notification-hover{
    // z-index: 1300 !important;
    z-index: 99999 !important;
    top: 55px !important;
  }
  .MuiPopover-paper{
    top: 0px !important;
    overflow-y: hidden;
    .MuiMenu-list{
      padding-bottom: 0px !important;
    }
  }
}

.notification-body {
  max-height: 73vh;
  overflow-y: hidden;
  overflow-x: hidden;
  cursor: context-menu !important;

  .MuiMenuItem-root {
    cursor: context-menu !important;
  }
  .notification-content {
    max-height: 58vh;
    overflow-y: auto;
  }
}

.active-message {
  background-color: var(--secondary-color);
}

.message-text {
  background-color: var(--primary-color);
  color: var(--gray1);
  border-radius: 6px 0px 6px 6px;
  line-height: normal;
}

.message-text-right {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 0px 6px 6px 6px;
  line-height: normal;
}

.inviteViaEmail {
  position: absolute !important;
  bottom: 82px !important;
  right: 0px !important;
  width: 100% !important;
}

.switch-overflow {
  overflow-y: auto;
  height: 380px !important;
}

.tabulator,
.tabulator-edit-select-list {
  font-family: var(--font-family);
  font-size: var(--font-size-14) !important;
}

.tabulator-header-filter input {
  padding: 4px !important;
}

.tabulator-col-title {
  color: #344054;
}

.tabulator .tabulator-header .tabulator-col {
  background-color: #f2f4f7 !important;
}

.tabulator {
  ::-webkit-scrollbar {
    height: 8px;
  }
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid var(--secondary-color) !important;
}

.themecolor>path {
  stroke: #1973b9;
}

.msp-drawer {
  .MuiMenu-list {
    padding-bottom: 0 !important;
  }

  .MuiPopover-paper {
    top: 55px !important;
    left: 20px !important;
    min-width: 220px !important;
  }
}
.svg-secondary-color-stroke {
  background: var(--secondary-color);
  &:hover {
    background: var(--hover-button-color)  !important;
    .svg-icons > g > path {
      fill: var(--hover-button-text-color)  !important;
    }
    .svg-icons > path {
      stroke: var(--hover-button-text-color)  !important;
    }
  }
}
.msp-svg>path{
  fill:#150349;
}
.svg-secondary-color-stroke g>path {
  fill: var(--primary-color) !important;
}
.svg-icon-circle>circle{
  fill: var(--secondary-color) !important;
}
.svg-icons-gray1>path {
  stroke: $gray1 !important;
}
.svg-icons-gray6>path {
  fill: var(--gray6) !important;
}
.svg-icons-gray7>path {
  stroke: $gray7 !important;
}
.svg-icons-gray9>path {
  stroke: $gray9 !important;
}
.fill-svg-icons-gray1>path {
  fill: $gray1;
}
.svg-icons>path {
  stroke: var(--primary-color) !important;
}

.rect-svg-icons>rect {
  fill: var(--primary-color);
}
.disabled-fill-svg-icons > path {
  fill: gray;
}
.disabled-send-button {
  pointer-events: none;
}

.rect-svg-icons-sec>rect {
  fill: var(--secondary-color);
}

.fill-svg-icons>path {
  fill: var(--primary-color);
}
.disabled-fill-svg-icons > path {
  fill: gray;
}
.disabled-send-button {
  pointer-events: none;
}

.circle-svg-icons>circle,
.circle-svg-icons>line {
  stroke: var(--primary-color);
}

.svg-primary-color {
  background: var(--primary-color);
  &:hover {
    background: var(--hover-button-color)  !important;
    .fill-svg-icons > path {
      fill: var(--hover-button-text-color)  !important;
    }
  }
}

.svg-secondary-color {
  background: var(--secondary-color);
  &:hover {
    background: var(--hover-button-color)  !important;
    .fill-svg-icons > path {
      fill: var(--hover-button-text-color)  !important;
    }
  }
}

// .icon{
//   color: var(--primary-color);
//   stroke: currentColor;
// }
.Sapphire-Breeze {
  padding: 10px !important;
  background-color: #1a7fae !important;
  width: 10px !important;
  margin-left: 15px !important;
}

.Aqua-Mist {
  padding: 10px !important;
  background-color: #008081 !important;
  width: 10px !important;
  margin-left: 15px !important;
}

.Lavender-Bliss {
  padding: 10px !important;
  background-color: #6941c6 !important;
  width: 10px !important;
  margin-left: 15px !important;
}

.Magenta-Nightfall {
  padding: 10px !important;
  background-color: #af26b1 !important;
  width: 10px !important;
  margin-left: 15px !important;
}

.Sunset-Blaze {
  padding: 10px !important;
  background-color: #b15826 !important;
  width: 10px !important;
  margin-left: 15px !important;
}

.Forest-Fern {
  padding: 10px !important;
  background-color: #150349 !important;
  width: 10px !important;
  margin-left: 15px !important;
}

.primary-color-picker,
.secondary-color-picker,
.hover-button-color-picker,
.hover-button-text-color-picker,
.heading-text-color-picker,
.main-text-color-picker,
.list-hover-color-picker {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  height: 44px;
  width: 50px;
  z-index: 10;
  border-radius: 6px;
}

.primary-color-picker {
  background: var(--primary-color);
}

.secondary-color-picker {
  background: var(--secondary-color);
}

.hover-button-color-picker {
  background: var(--hover-button-color);
}

.hover-button-text-color-picker {
  background: var(--hover-button-text-color);
}

.heading-text-color-picker {
  background: var(--heading-text-color);
}

.main-text-color-picker {
  background: var(--main-text-color);
}

.list-hover-color-picker {
  background: var(--list-hover-color);
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.primary-color-popover {
  position: relative;
  z-index: 99;
  top: -115px;
}

.secondary-color-popover {
  position: relative;
  z-index: 99;
  top: -115px;
}
.hover-button-color-popover {
  position: relative;
  z-index: 99;
  top: -115px;
}
.hover-button-text-color-popover {
  position: relative;
  z-index: 99;
  top: -115px;
}
.heading-text-color-popover {
  position: relative;
  z-index: 99;
  top: -115px;
}
.main-text-color-popover {
  position: relative;
  z-index: 99;
  top: -115px;
}
.list-hover-color-popover {
  position: relative;
  z-index: 99;
  top: -210px;
}
.editor-font-color {
  //width: 195px !important;
  position: absolute;
  top: 0;
  right: -125px;
}

.MuiPickerDTTabs-tabs {
  background-color: var(--primary-color) !important;
}

.MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: var(--primary-color) !important;
}

.MuiMenu-list .Mui-selected {
  background-color: var(--secondary-color) !important;
}
.MuiAutocomplete-popper .Mui-focused {
  background-color: var(--secondary-color) !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 10px 2px 10px 2px !important;
}

.tabulator-row .tabulator-cell {
  padding: 10px 2px 10px 2px !important;
}

.feedback-width {
  width: 600px !important;
}

.menu-options {
  button {
    padding: 0 !important;
  }
}

.profile-list-btn {
  &.MuiButtonBase-root {
    &:hover {
      background-color: var(--secondary-color) !important;
      box-shadow: none;
      color: var(--primary-color) !important;

      .svg-icons1>path {
        stroke: var(--primary-color);
      }
    }
  }
}

.svg-icons1:hover>path {
  stroke: var(--primary-color);
}

.settings-sidebar {
  li {
    list-style: none;

    div {
      padding: 14px;
      display: flex;

      &.mui-selected {
        background-color: var(--secondary-color);

        p {
          color: var(--primary-color);
        }
      }
    }
  }
}

.settings-sidebar-fix {
  position: fixed;
  top: 5em;
  width: 30%;
  z-index: 2;
}

.settings-body {
  padding-top: 50px;
  width: 60%;
  position: absolute;
  right: 0;
  //padding-right: 8em;
}

.edu-icon {
  img {
    background: #EEF5FF;
    padding: 10px;
    width: 42px;
    border-radius: 50%;
  }
}

// pagination hover css
.css-188v5gy-MuiPaginationItem-icon {
  margin: 0 !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
  // padding: 0 14px;
}

.editIcon-userDetails {
  border-radius: 15%;
  cursor: pointer;
  // margin: 20px;
  padding: 4px 4px 4px 4px;
  width: 25px !important;
  height: 25px !important;
  background-color: var(--secondary-color);
  // object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.daterangepicker .ranges li.active {
  background-color: var(--primary-color) !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--primary-color) !important;
}

.daterangepicker td.in-range {
  background-color: var(--secondary-color) !important;
}

.daterangepicker td.off.in-range {
  background-color: #fff !important;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover,
.tabulator-edit-select-list .tabulator-edit-select-list-item.active {
  background: var(--primary-color) !important;
}

.outline-none {
  outline: none !important;
}

.assign-msp {
  .MuiSelect-select {
    display: flex !important;
    align-items: center !important;
  }
}
.rehire-talent{
  .rehire-pool{
    .rehire-pool-align{
      font-weight: var(--font-weight-600) !important;
    }
  }
}
.payrate-suggesting-box {
  border: 1px solid #E4E7EC;
  border-radius: 5px !important;
}
.payrate-primary {
  color: var(--primary-color) !important;
  font-size: var(--font-size-14) !important;
}
.btn-accessibility {
  position: relative;
  right: 0;
  z-index: 10;
  .crossIcon-bionic {
    padding: 6px !important;
    width: 40px;
    height: 40px;
    background-color: var(--gray11);
    border-radius: 50%;
    cursor: pointer;
  }
}
.btn-accessibility:hover {
  // content: url("/assets/images/accessiblity-onhover.svg");
  position: fixed;
  top: 50%;
  right: 0px;
  z-index: 2;
  // .icon-display {
  //   display: none;
  // }
  // .icon-display1{
  //   display: block;
  //   position: fixed;
  //   top: 51.1%;
  //   right: 8px;
  //   z-index: 2;
  // }
}
.accessibility-new {
  position: fixed;
  top: 50%;
  right: 19px;
  width: 45px;
  z-index: 2;
  // z-index: 999999999;
  //z-index: 999999999999 !important;
}
.icon-display {
  display: block;
}
.icon-display1 {
  display: none;
}
.bionic-popover{
//   background: #FFFFFF;
// box-shadow: 0px 3px 8px rgba(149, 136, 136, 0.25);
// border-radius: 4px;
padding: 5px 15px 5px 15px;
}
.access-popover{
  z-index: 9999999999 !important;
  .MuiPaper-root {
    &.MuiPopover-paper {
      background: #FFFFFF !important;
          box-shadow: 0px 3px 8px rgba(149, 136, 136, 0.25) !important;
          border-radius: 4px !important;
          width: 300px !important;
    }
  }
}
.access-popover-neuro{
  z-index: 9999999999 !important;
  .MuiPaper-root {
    &.MuiPopover-paper {
      background: #FFFFFF !important;
          box-shadow: 0px 3px 8px rgba(149, 136, 136, 0.25) !important;
          border-radius: 4px !important;
          width: 315px !important;
    }
  }
}
.privacy-neuro {
  position: fixed;
  bottom: 10px;
  width: 100%;
  // margin-bottom: 5px !important;
  //background: #ffffff !important;
  left: 0px !important;
  font-size: var(--font-size-14) !important;
}
.share-job-align{
  .share-job{
    display: flex;
  }
}
.neuro-diversity-background{
  background-image: url('/assets/images/Neurodiversity-background.svg');
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  // background-image: url('/assets/images/Neurodiversity-background.png');
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // top: 0;
  // bottom: 0;
  // position: absolute;
  // width: 100%;
  }
  // .login-bg-neuro {
  //   position: relative;
  //     height: 100%;
  //     width: 100%;
  // }
   .neuro-section{
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 99;
    height: 100vh;
      max-width: 100wh !important;
      align-items: center;
      vertical-align: middle;
      display: flex;
   }
  .neuro-logo-image{
    position: absolute;
    top: 0;
    left: 30px;
  }

.MuiBox-root {
  .resumePrefillProgress {
    &.MuiLinearProgress-determinate {
      background-color: var(--gray4) !important;
      border-radius: 10px;
    }
  }
}
.footerIcon {
  padding: 6px;
  // color: $white-color;
  // background: $grey-color;
  // border-radius: 50%;
  // width: 28px !important;
  // height: 28px !important;
  cursor: pointer;
}
.create-job-menu {
  .MuiPaper-root {
    ul {
      li {
        display: block !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
      }
    }
  }
}
.jobWizard-arrowdown {
  position: fixed;
    top: 79%;
    right: 30px;
    width: 45px;
    z-index: 2;
}
.job-wizard-payrate{
  background: var(--secondary-color)  !important;
  border-radius: 20px !important;
}
.job-wizard-payrate-select {
  background: var(--primary-color)  !important;
  border-radius: 20px !important;
}
.highlight-list-container {
  border: 1px solid var(--primary-color);
}
.notification-hover1 {
  z-index: 0 !important;
}
.notification-hover {
  z-index: 5 !important;
  .MuiPopover-paper .notifymenu-item:hover {
    background-color: #f7f7fb !important;
}
}
.unread-dot{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  background-color: var(--primary-color) !important;
}
.budgetalign{
  .budget .form-control{
    margin-top: 3px !important;
  }
}
.job-log-shadow{
  .MuiPaper-root{
    box-shadow:0px 3px 8px rgba(149, 136, 136, 0.25) !important;
  }
}
.jobWizardSearchBox {
  //position: fixed;
  bottom: 0px !important;
  margin-bottom: 27px !important;
  padding:20px;
  width: -webkit-fill-available !important;
}
.jobWizardSearchBar {
  //width: -webkit-fill-available !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  right: 0;
}
.img-loader {
    position: relative;
    border: none !important;
    background-color: #FFF;
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
    padding: 14px 14px 6px 20px !important;
    padding-top: 8px !important;
}
.interviewer{
  color: var(--primary-color);
  background-color: var(--secondary-color);
  padding: 6px 8px;
  border-radius: 6px;
  margin-right: 10px;
  margin-top: 5px;
  display: inline-block;
}
.notesScrollOnHover{
  max-height: 300px;
  overflow-y: hidden;
}
.notesScrollOnHover:hover{
  overflow-y: auto;
}
.scoreCardScrollOnHover{
  max-height: 400px;
  overflow-y: hidden;
}
.scoreCardScrollOnHover:hover{
  overflow-y: auto;
}
.deselect-scorecard{
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 6px;
  cursor: pointer;
}
.select-scorecard{
  color: white;
  background-color: var(--primary-color);
  border-radius: 6px;
  cursor: pointer;
}
.interview-link:hover{
  text-decoration: underline !important;
}
#panel1a-review {
  .Mui-expanded, .MuiAccordionSummary-content {
    margin: 0px !important;
  }
}
.scorecardlist {
  min-height: 54px !important;
}
.offer-download {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  border-radius: 5px;
}
.offer-disable {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  border-radius: 5px;
  pointer-events: none;
  color: var(--gray7) !important;
  background-color: var(--gray4) !important;
  font-weight: var(--font-weight-600) !important;
  border-color: var(--gray7) !important;
}
.btn-position {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
.outlook-icon{
  border-radius: 50%;
  padding: 3px;
  box-shadow: 0px 6px 14px rgba(90, 136, 193, 0.12),
    0px 10px 32px rgba(90, 136, 193, 0.12);
}
.tabs-in-notes-active {
  padding: 10px 14px 10px 14px;
  border-radius: 40px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  font-size: 12px;
  margin-right: 5px !important;
}
.tabs-in-notes-inactive {
  padding: 10px 14px 10px 14px;
  border-radius: 40px;
  background-color: var(--secondary-color);
  color: #000;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  margin-right: 5px !important;
}
.tabs-in-notes-disable {
  padding: 10px 14px 10px 14px;
  border-radius: 40px;
  color: var(--gray7) !important;
  background-color: var(--gray4) !important;
  pointer-events: none;
  cursor: default;
  font-size: 12px;
  margin-right: 5px !important;
}
.stepper_time_line {
  min-height: 100px !important;
  max-height: 240px !important;
  overflow: auto;
}
#panel1a-header-details{
    display: flex !important;
}
.selectBox{
  display: flex !important;
  text-decoration: none !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.fade-out-content {
  color: white;
  animation: fadeOutBackground 4s 1s forwards;
}

.fade-out {
  opacity: 0;
}
@keyframes fadeOutBackground {
  from {
      background-color: #E5f0FC;
  }
  to {
      background-color: transparent;
  }
}
.share-button {
  .amplify-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.share-button {
  .MuiPaper-root{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
  }
}
.responsive-share {
 width: 225px;
}
.responsive-qr {
  width: 130px;
  height: 125px;
}
.approve-title {
  border-bottom: 5px solid var(--primary-color);
  border-radius: 1.8rem !important;
  width: 10.4rem;
}
.all-notifications{
  .MuiPopover-paper{
    .MuiMenu-list{
      padding-bottom: 0px !important;
    }
  }
}
#refer-candidate > path {
  stroke-width: 0.85;
}
.job-summary-align {
  .MuiStepper-horizontal {
    .MuiStep-horizontal {
      padding-left: 8px !important;
    }
  }
}
.licence-number {
  .form-control:focus {
    box-shadow: none !important;
  }
  ::placeholder {
    color: var(--gray7) !important;
    font-weight: normal !important;
    opacity: 0.6 !important;
  }
}
.phone-number-textfield {
.form-control {
  margin-top: 1px !important;
}
}
.CircularProgressbar {
  padding: 0.5px;
}
.select-enterprise {
  .MuiOutlinedInput-root {
    .MuiSelect-select {
      padding-right: 10px !important;
    }
  }
}
.quill-editor-container {
  height: 400px;
}

.ql-editor {
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
  height: 400px;
  background-color: white; /* Set desired background color */
  color: #333;
}
.border-red-upload-resume {
  border: 1px dashed $danger-color !important;
}
.checkboxDocument {
  .MuiCheckbox-root {
    color: #98a2b3 !important;
    &.Mui-checked {
      color: var(--primary-color) !important;
      padding: "0px";
      &.hover {
          background: "none";
          padding: "0px";
      }
  }
  }
  }
  
  .profile-radius {
    background: $bg-white;
    border: 1px solid var(--gray4);
    border-radius: 0px 0px 8px 8px;
  }
  .profileCoverBullet.MuiSvgIcon-root {
    font-size: 5px !important;
  }
  .aiButton {
    padding: 6px 12px !important;
  }
  .work-history {
    img {
      background: #f0eeff;
      padding: 8px;
      width: 34px;
      border-radius: 50%;
    }
  }
  .portfolio-link {
    li .link-icon {
      background: #ffeeef;
      padding: 6px;
      width: 30px;
      border-radius: 50%;
    }
  }
  .edu-documents {
    li {
      list-style: none !important;
      margin-top: 20px !important;
  
      a {
        border: 1px solid rgba(128, 129, 145, 0.4);
        border-radius: 8px;
        text-decoration: none !important;
        padding: 8px;
        color: var(--gray11);
        font-size: var(--font-size-14) !important;
      }
    }
  }
  .candidateProfileDocument {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      width: 400px !important;
    }
  }
  .file-extension-image-new {
    transform: scale(0.75);
    object-fit: cover;
  }
  .select-option-reference {
    margin-top: 8px !important;
    height: calc(1.5em + 0.75rem + 8px);
   padding-bottom: 7px !important;
  }
  .reference-icon {
    img {
      background: #fdeeff;
      padding: 8px;
      width: 34px;
      border-radius: 50%;
    }
  }

  .uploaded-video {
    svg {
      background: #f0eeff;
      padding: 8px;
      width: 30px;
      border-radius: 4px;
      height: 30px;
    }
  }
  .save-add-candidate {
    position: fixed;
    bottom: 60px;
    right: 23px;
    z-index: 1000; /* Make sure it's above other elements */
}
.back-job>g>path{
  fill: $gray7 !important;
  }