.m-0 {
    margin: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}
.mb-2 {
    margin-bottom: 2px !important; 
}

.rr-15 {
    margin-right: 15px !important;
}
.mb-8 {
    margin-bottom: 8px !important;
}
.mt-1 {
    margin-top: 1px !important;
}
.mb-1 {
    margin-bottom: 1px !important;
}

.mt-0 {
    margin-top: 0 !important;
}
.mt-12 {
    margin-top: 12px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-6 {
    margin-top: 6px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-9 {
    margin-top: 9px !important;
}

.mt-3 {
    margin-top: 3px !important;
}
.mt-4 {
    margin-top: 4px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-13 {
    margin-top: 13px !important;
}

.mt-15 {
    margin-top: 15px !important;
}
.mt-17 {
    margin-top: 17px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-21 {
    margin-top: 21px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-28 {
    margin-top: 28px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-34 {
    margin-top: 34px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-36 {
    margin-top: 36px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-100 {
    margin-top: 100px !important;
}
.mt-110 {
    margin-top: 110px !important;
}
.mt-148{
    margin-top: 148px !important;
}
.mt-166{
    margin-top: 166px !important;
}
.m-r-10 {
    margin-right: 10px !important;
}

.m-auto {
    margin: 0 auto !important;
}

.mx-5 {
    margin: 0 5px;
}

.mx-6 {
    margin: 5px 6px;
}

.mx-20 {
    margin: 0 20px;
}

.p-absolute {
    position: absolute;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-t-b-5 {
    margin: 5px 0;
}

.m-t-b-10 {
    margin: 10px 0 !important;
}

.m-l-r-10 {
    margin: 0 10px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-30 {
    margin: 30px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-6 {
    margin-right: 6px;
}
.mr-8 {
    margin-right: 8px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.MuiFormHelperText-root {
    margin-top: 10px !important;
}

.MuiTextField-root {
    .MuiOutlinedInput-root {
        svg {
            // top:8px;
        }
    }
}

.mr-30 {
    margin-right: 30px !important;
}

.mt-14 {
    margin-top: 14px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-16 {
    margin-top: 16px !important;
}
.ml-2{
    margin-left: 2px !important;
}
.ml-3{
    margin-left: 5px;
}
.ml-5 {
    margin-left: 5px !important;
}

.ml-3 {
    margin-left: 3px !important;
}
.ml-9 {
    margin-left: 9px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-11{
    margin-left: -11px !important;
}
.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}


.ml-30 {
    margin-left: 30px !important;
}

.mt-18 {
    margin-top: 18px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-32 {
    margin-top: 32px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.m-r-30 {
    margin-right: 30px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-r-45 {
    margin-right: 45px !important;
}

.m-r-50 {
    margin-right: 50px !important;
}

.mb-3 {
    margin-bottom: 3px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}
.mb-7 {
    margin-bottom: 7px !important;
}
.mb-12 {
    margin-bottom: 12px !important;
}
.mb-13 {
    margin-bottom: 13px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.mr-0 {
    margin-right: 0px !important
}

.mt-2 {
    margin-top: 2px !important;
}

.mt-1 {
    margin-top: 1px !important;
}

.parent-container {
    margin-top: -8px;
  }
.ml--5 {
    margin-left: -5px;
}
.ml--15 {
    margin-left: -15px !important;

}
.mb-10 {
    margin-bottom: 10px !important;
}
.mr-19{
    margin-right: 19px !important;
}
.mr-20 {
    margin-right: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.m-t-m-10 {
    margin-top: -10px;
}
.ml-m-15{
    margin-left: -15px;
}
.m-t-m-5 {
    margin-top: -5px;
}

.l-h-1 {
    line-height: 1;
}

.m-r-6 {
    margin-right: 6px;
}

.mt-2 {
    margin-top: 2px !important;
}

.m-r-17 {
    margin-right: 17px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.mr-25 {
    margin-right: 25px !important
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.m-t-1 {
    margin-top: 1px !important;
}
.m-t-5 {
    margin-top: 5px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-35 {
    margin-left: 35px !important;
}

.mt-7 {
    margin-top: 7px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.my-15 {
    margin: 15px 0 !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}
.m-40 {
    margin: 40px !important;
}
.mb-50 {
    margin-bottom: 50px !important;
}

.mt-1 {
    margin-top: 1px !important;
}
.mt-m-37 {
    margin-top: -37px !important;
}
.mlr-20{
    margin-left: 20px;
    margin-right: 20px;
}
.mr-9{
    margin-right: 9px;
}
.ml-22{
    margin-left: 22px;
}
.mr-22{
    margin-right: 22px;
}
.mr-34{
    margin-right: 34px;
}
.mr-70{
    margin-right: 70px !important;
}
.mr-77 {
    margin-right: 77px;
}
.mt-m-15{
    margin-top: -15px !important;
}
.mr-40{
    margin-right: 40px !important;
}
.m-l-30{
    margin: 0 30px;
}
.mx-125 {
    margin-left: 125px;
    margin-right: 125px;
}