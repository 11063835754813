.footer-section {
  background-color: #222222;

  .footer-sub-section {
    ul {
      list-style: none;

      p {
        color: #FFFFFF;
        padding-bottom: 1em;
      }
    }
  }
}


.hire-top-section {
  background-image: linear-gradient(180deg, var(--primary-color) 0%, var(--primary-color) 100%) !important;
  box-shadow: 0px 45px 135px -45px var(--primary-color);
  border-radius: 10px;
  padding: 20px;

  .hire-top-inner {
    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }

  }

  .contactUs-button {
    border: 1px solid #FFFFFF;
    color: var(--gray9);
    background-color: #FFFFFF;
    font-size: var(--font-size-16);
    padding: 10px 30px;
    text-transform: none;
    box-shadow: none;
    font-family: var(--font-family);
  }
}

.social-media-icons {
  list-style: none;
  display: contents;

  .linkedIn-icon {
    a {
      background-color: #007bb6 !important;
      color: #FFFFFF;
      padding: 15px;
      margin: 10px;
      border-radius: 40px;
      width: auto;
      height: auto;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      display: flex;
    }
  }

  .facebook-icon {
    a {
      background-color: #3b5998 !important;
      color: #FFFFFF;
      padding: 15px;
      margin: 10px;
      border-radius: 40px;
      width: auto;
      height: auto;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      display: flex;
    }
  }

  .twitter-icon {
    a {
      background-color: #00aced !important;
      color: #FFFFFF;
      padding: 15px;
      margin: 10px;
      border-radius: 40px;
      width: auto;
      height: auto;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      display: flex;
    }
  }
}

.design-section {
  .inner-design-section {
    padding: 2.5em;
  }
}

.demand-hiring {
  .demand-hiring-inner-section {
    background-image: linear-gradient(43deg, #1973b9 33%, #09b9fa 43%);
    background-color: #E8F5FE;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);
    padding: 2em;
  }

  .get-it-now-button {
    border: 1px solid #474747;
    color: #FFFFFF;
    background-color: #474747;
    font-size: var(--font-size-16);
    padding: 10px 30px;
    text-transform: none;
    box-shadow: none;
    font-family: var(--font-family);
  }
}


.simple-background-section {

  /*  !*background: url('/assets/images/launch-page-images/background-curve-image.png') center/contain no-repeat !important;*!
  background: url('/assets/images/launch-page-images/background-curve-image.png') no-repeat !important;
  background-size: 100% 100px;
  top: 0;
  height: 100px;
  z-index: 1;*/
  .simple-section {
    background-color: #28283f !important;

    .inner-simple-section {
      padding: 2em 10em;
    }

    .card-section {
      padding: 2em;
    }
  }
}


.watch-video-btn {
  border: 1px solid var(--primary-color);
  color: #FFFFFF;
  background-color: var(--primary-color);
  font-size: var(--font-size-16);
  padding: 10px 30px;
  text-transform: none;
  box-shadow: none;
  font-family: var(--font-family);
}

.inner-image-section {
  padding: 2.5em;
}

.inner-image-section-left {
  padding-left: 0px;
  width: 96.6%;
  transform: scaleX(1.38) scaleY(1.38) translateX(-109px) translateY(32px) rotateX(395deg) rotateY(331deg) rotateZ(350deg);
  text-align: right;
  margin-right: 0;

  .image-shadow {
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
    display: inline-block;
    position: relative;
    max-width: 100%;
  }

  .box-shadow-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }
}


.sub-navbar {
  background: #FFFFFF !important;
  box-shadow: inset 0 1px 4px rgb(0 0 0 / 25%);
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 67px;
  padding: 10px 40px 5px 40px !important;
  // box-shadow: 0 4px 4px rgb(179 179 179 / 15%);
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.demo-button {
  border: 1px solid $info-color;
  color: #FFFFFF;
  background-color: $info-color;
  font-size: var(--font-size-16);
  padding: 10px 30px;
  text-transform: none;
  box-shadow: none;
  font-family: var(--font-family);
}

.launch-page-heading-color {
  color: #474747 !important;
}

.landing-sub-text-color {
  color: #545454;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
}

.caursoul-text {
  position: absolute;
  background-color: #FFFFFF;
  width: 80%;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.caursoul-button1 {
  position: absolute;
  bottom: 220px;
  left: 127px;
}

.caursoul-button2 {
  position: absolute;
  bottom: 200px;
  left: 127px;
}

.react-multi-carousel-dot button {
  width: 10px !important;
  height: 10px !important;
}

.react-multi-carousel-dot-list {
  padding: 30px 0 !important;
}

.react-multi-carousel-dot button {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  opacity: .8 !important;
}

.react-multi-carousel-dot button {
  /*  border-color: #d78879 !important;*/
}

.react-multi-carousel-dot--active button {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.react-multiple-carousel__arrow::before {
  font-size: var(--font-size-20);
  color: var(--primary-color) !important;
  display: block;
  text-align: center;
  z-index: 2;
  position: relative;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
}

.react-multiple-carousel__arrow:hover {
  background: transparent !important;
}


/* [1] The container */
.section-hover-zoom {
  top: 0;
  left: 0;
  overflow: hidden;
  /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.section-hover-zoom {
  transform-origin: 30% 30%;
  transition: transform 1s, filter .5s ease-out;
}

/* [3] Finally, transforming the image when container gets hovered */
.section-hover-zoom:hover {
  transform: scale(1.15);
}

.save-hire-section {
  /* background-image: linear-gradient(180deg,#ffffff 0%,#edf8ff 100%)!important;*/
}




.background-image {
  background: url('/assets/images/launch-page-images/background-curve-image.png') center/contain no-repeat !important;
}

.simple-card-section {
  background-color: #28283f !important;
  box-shadow: none !important;
}

.bg {
  background-color: rgb(28 165 255 / 0.1);
  background-size: cover;
  -webkit-background-size: cover;
  width: 100%;
  /* height: 1000px;*/
  overflow: hidden;
  position: relative;

  /*below show how it works*/
  &:after {
    content: "";
    width: 200%;
    height: 0;
    padding-top: 200%;
    border-radius: 100%;
    background: #FFFFFF;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.green-light-background {
  background-color: var(--secondary-color);
  //background-color: #dae5f2;
  border-radius: 30px;
}

.title-border {
  border-bottom: 5px solid var(--primary-color);
  border-radius: 1.8rem !important;
  width: 4.3rem;
}

// .card-image {
//   border: 1px solid $gray4;
//   width: 70px;
//   padding: 12px;
//   border-radius: 12px;
//   align-items: center;
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   margin: 0 auto;
//   background: linear-gradient(to top, blue 0%, blue 100%);
//   transition: background-position 0.3s ease;

//   // &:hover {
//   //   // background-color: #F4F9F4;
//   //   // cursor: pointer;
//   //   // background-color: rgba(34, 139, 34, 0.07);
//   //   // transition: background-color 0.3s ease;
//   //   background-position: 0% -100%;
//   // }
// }
// .card-image:hover{
//   background-position: 0% -100%;
// }

.card-image {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--gray4);
  width: 70px;
  padding: 12px;
  border-radius: 12px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1.8rem;
}

.card-image::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #F4F9F4;
  transition: top 0.3s ease;
}

.card-image:hover::before {
  top: 0;
}

.card-image {
  .MuiCardMedia-img {
    z-index: 99;
  }
}

.features-card {
  text-align: center;
  padding: 4.3rem 0.875rem;
  border: 1px solid transparent;
  box-shadow: 0px 0.25rem 1.5rem 0px rgba(0, 0, 0, 0.05) !important;
  margin: 1.25rem 0.9rem;
  border-radius: 0.375rem !important;
  transition: 0.3s ease !important;
  cursor: default;
}

// .features-card:hover {
//   box-shadow: none;
//   border-color: rgba(34, 139, 34, 0.1);
//   transform: translateY(-2px);
//   cursor: pointer;
// }
.direct-source{
  margin-top: 48em !important;
}

.launch-page-subtext {
  font-size: 15px !important;
  // color: #676767 !important;
  color:var(--gray9) !important;
  font-weight: 400 !important;
}

.launch-page-title {
  font-size: var(--font-size-34) !important;
  color: #222222 !important;
  font-weight: var(--font-weight-600) !important;
}

.launch-page-title1 {
  font-size: var(--font-size-24) !important;
  color: #222222 !important;
  font-weight: var(--font-weight-600) !important;
}

.launch-page-title2 {
  font-size: 47px !important;
  color: #222222 !important;
  font-weight: var(--font-weight-600) !important;
}

.responsive-bottom-section {
  &.discover-section {
    padding-bottom: 20em !important;
  }
}
