.mile-km-align{
    background: rgba(234, 234, 234, 0.6) !important;
    border-radius: 5px !important;
    padding: 3px 0px 3px 3px !important;
    text-align: center !important;
    color: var(--gray9)  !important;
    font-size: var(--font-size-12) !important;
    font-weight: var(--font-weight-600) !important;
    width: 36px;
    height: 22px;
    align-items: center;
    display: flex;
    text-align: center !important;
    justify-content: center;
}
.panel-profile{
    .play-icon-profile-detail{
        left: 74px !important;
    }
}
.loader-login {
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid var(--primary-color);
  width: 20px;
  height: 20px;
  margin-left: 10px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.dashboard-card{
  background-color: $bg-white !important;
  border-radius: 10px;
  padding: 5px 15px 15px 15px;
  border: 1px solid var(--primary-color);
  height: 100px;
  position: relative;
  &:hover{
    background: linear-gradient(0deg, var(--primary-color) 40%, var(--secondary-color) 220%) !important;
    color: #FFFFFF !important;
    .svg-icons>path{
      stroke: #FFFFFF !important;
    }
    .hov-color{
      color: #FFFFFF !important;
    }
  }
}
.new-dashboard-card {
  background-color: $bg-white !important;
  border-radius: 10px;
  padding: 5px 15px 15px 15px;
  border: 1px solid var(--primary-color);
  height: 100px;
  position: relative;
}
.dashboard-label{
  height: 76px;
  align-items: center;
  display: flex;
  padding-bottom: 5px;
}
.dashboard-loader{
  border-radius: 10px;
  position: relative;
  padding: 15px !important;
  height: 95px;
  .dashboardLoader{
    margin-top: 0px !important;
    height: 22px !important;
  }
}
.bottom-0{
  bottom: 0px !important;
}
.right-0{
  right: 0px;
}
.mr-15{
  margin-right: 15px !important;
}
.new-tag-chip{
  background-color: #FFA500;
  border-radius: 20px;
  padding: 3px 10px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  color: var(--gray9);
}
.matching-closure{
  .closure-status{
    padding-left: 210px !important;
  }
}
.offer-letter-div {
  .MuiCheckbox-root {
    padding: 9px 9px 9px 0px !important;
  }
}
.matching-menu{
  .jobdetail-menu{
    button{
      display: flex;
      justify-content: flex-end;
      min-width: 40px;
    }
  }
}

// locationFilterCSS
.location-card{
  background-color: $bg-white !important;
  border-radius: 8px;
  padding: 10px 10px 5px;
  border: 1px solid var(--primary-color);
  width: 180px;
}
.location-card-active{
   
      background: linear-gradient(0deg, var(--primary-color) -79%, var(--secondary-color) 220%) !important;;
      color: #FFFFFF !important;
      .hov-color{
        color: #FFFFFF !important;
      }
}
    

.tooltipLocation{
  background-color: var(--gray9) !important;
  position: relative !important;
  width: max-content;
  top: 10px;
  left: 0px;
  padding: 7px 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 6px !important;
  color: #fff;
}
.tooltipLocation .MuiTooltip-arrow {
  color: var(--gray9) !important;
  left: -30px !important;
  position: absolute;
}
.tooltipLocation .MuiTooltip-arrow:before {
  background-color: var(--gray9) !important;
  top: -7px;
  left: 50%;
  transform: translateX(0%) rotate(45deg);
}
.tooltipLocation{
  &.other-location .MuiTooltip-arrow{
    left: 8px !important;
  }
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media screen and (min-width: 200px) and (max-width: 600px) {
  .dashboard-card{
    height: 130px !important;
  }
  .dashboardLoader{
      width: 75% !important;
  }
  .dashboard-label{
    height: 90px !important;
  }
  .matching-closure{
    .closure-status{
      padding-left: 7px !important;
    }
  }
}
@media screen and (min-width: 200px) and (max-width: 320px){
  .dashboard-card{
    height: 140px !important;
  }
}
.upload-resume-section {
  svg {
    height: 18px;
    width: 18px;
    color: #696871;
    background: #FFFFFF;
    padding: 1px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 4px rgb(179 179 179 / 15%);
    margin-left: -14px;
    margin-bottom: 55px;
  }
}
.upload-resume {
  border: 1px dashed #696871;
  width: 17em;
  padding: 30px;
  height: 10em;
  margin: 25px;
  border-radius: 8px;
}
.svg-icons-disable > path {
  fill: #00000061;
}
.profileName {
  margin-left: 8em !important;
}
.flex-row-reverse{
  flex-direction: row-reverse;
}
.work-location {
  .campain {
    .MuiOutlinedInput-input {
      color: #667085 !important;
      font-weight: normal !important;
      opacity: 0.6;
    }
  }
}
.screening-radio{
  .MuiFormControlLabel-root{
    display: flex !important;
    align-items: flex-start;
  .MuiFormControlLabel-label{
    word-break: break-word;
    margin-top: 6px;
  }
  }
}
.checkbox-view{
  .MuiCheckbox-root{
    padding: 9px !important;
    color: #98A2B3 !important;
  }
}
.radio-view{
  .MuiRadio-root{
    color: #667085 !important;
    padding: 9px !important;
  }
}
#menu-screening-question{
  .MuiPaper-root.MuiPopover-paper{
    width: 45%;
    .preview-dropdown {
      display: block !important;
      white-space: break-spaces !important;
      word-break: break-word !important;
      padding: 6px 16px !important;
    }
  }
}
.screening-answer{
  .form-control.input-group{
    padding: 0px !important;
    .MuiInputBase-root{
      border-radius: 6px !important;
      .MuiInputBase-input{
        padding: 12px 16px !important;
      }
    }
  }
}
.screening-date-preview {
  .form-control.input-group {
    padding: 0px !important;
    .MuiInput-root{
      .MuiInputBase-input{
        padding: 12px 16px !important;
        border-radius: 6px 0px 0px 6px !important;
        &.Mui-disabled{
          background-color: #F2F4F7 !important;
          -webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
        }
      }
    }
    .date-icon{
      background-color: #F2F4F7 !important;
      padding-right: 10px;
      border-radius: 0px 6px 6px 0px !important;
    }
    .date-icon-cross-sell{
      padding-right: 10px;
      border-radius: 0px 6px 6px 0px !important;
    }
  }
}
.cross-radio{
  .MuiRadio-root{
    padding: 2px 13px 0px 10px !important;
  }
}
.smart-filter-badge {
  display: flex;
    width: 25px;
    height: 25px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 50%;
    padding: 2px 2px;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: -12px;
    right: -8px;
    justify-content: center;
    align-items: center;
}
  .svg-fill-primary-filter:hover>path {
    fill: var(--hover-button-text-color)  !important;
  }
  .svg-fill-primary-filter>path {
    fill: var(--primary-color)  !important;
  }
  .smart-filter-non-active>path {
    fill: var(--gray7)  !important;
  }
  .smart-filter-active>path {
    fill: var(--primary-color) !important;
    stroke: none !important;
  }
  /* Style for the Popover with the tip */
  .popover-with-tip .MuiPaper-root {
    position: relative;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  
  .triangle-pointer {
    position: absolute;
    top: -8px; /* Position the triangle above the popover */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white; /* Same color as the Popover background */
    z-index: 1;
  }
  
  .popover-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .popover-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .primary-button {
    background-color: #007bff; /* Adjust color as needed */
    color: white;
    font-weight: bold;
  }

.smart-filter-container {
    position: absolute;
    transform: translateX(-50%);
    max-width: 360px;
    width: 360px;
    z-index: 999;
    margin-top: 24px !important;
}
.smart-filter::after {
  content: '';
  position: absolute;
  top: -14px;
  left: 78%;
  width: 30px;
  height: 30px;
  background-color: white;
  z-index: 0;
  clip-path: polygon(0% 0%, 100% 0%, 6% 95%, -900% 0);
  transform: rotate(45deg);
  border: 1px solid $gray5;
  border-radius: 5px;
}
  